import React from 'react';
import {Button} from '@mui/material';
import {Link as RouterLink, useRouteError} from 'react-router-dom';

function NotFound() {
    const error = useRouteError();

    return (
        <div>
            <div id="error-page">
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error?.statusText || error?.message || 404}</i>
                </p>
                <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to="/home"
                >
                    Back to Home
                </Button>
            </div>
        </div>
    );
}

export default NotFound;
