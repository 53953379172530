import React, { useRef } from "react";
import { ImageListItem } from "@mui/material";
import { Box } from "@mui/material";

export function ImageItem(props) {
    const { image, keyMap, idx, propertyMap, openImageModal,
        renderLabelingModule, selectedJobFile, renderToolbar } = props;
    const imgRef = useRef(null);
    return (
        <Box sx={{ marginBottom: "10px" }}>
            {selectedJobFile
                ? renderToolbar(idx, image)
                : null}
            <Box sx={{ border: "1px solid" }}>
                <ImageListItem>
                    {renderLabelingModule
                        ? renderLabelingModule({ imgRef, index: idx, pageId: image[keyMap] })
                        : null}
                    <img
                        ref={r => imgRef.current = r}
                        loading="lazy"
                        src={image[propertyMap]}
                        alt={image[keyMap]}
                        onClick={() => openImageModal(image)}
                    />
                </ImageListItem>
            </Box>
        </Box>
    )
}