import {createSlice} from '@reduxjs/toolkit';
import {fetchGeneralOptions} from '../Thunks/RootThunk';
import _ from 'lodash';

const rootSlice = createSlice({
    name: 'root',
    initialState: {
        companies: [],
        documentPackageTypes: [],

        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGeneralOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchGeneralOptions.fulfilled, (state, action) => {
            state.companies = _.get(action.payload, 'companies') || [];
            state.documentPackageTypes = _.get(action.payload, 'documentPackageTypes') || [];
            state.loading = false;
        })
        builder.addCase(fetchGeneralOptions.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
    },
});

export default rootSlice;
