export function drawPoint(ctx, point, color = '#45D38C', pointPixels = 16) {
    ctx.fillStyle = color;
    ctx.fillRect(point.x - pointPixels / 2, point.y  - pointPixels / 2, pointPixels, pointPixels);
}
export function drawArea(ctx, points = [], lineColor = 'red', pointColor = '#45D38C', pointPixels = 16) {
    if (!points.length) return;

    for (let index = 0; index < points.length; index++) {
        const point = points[index];
        drawPoint(ctx, point, pointColor, pointPixels);

        if (index < 1) continue;
        drawLine(ctx, points[index - 1], points[index], lineColor);

        if (index !== 3) continue;
        drawLine(ctx, points[index], points[0]);
    }
}
export function fillSelectedArea(ctx, points, color){
    ctx.moveTo(points[0].x, points[0].y);
    ctx.fillStyle = color;
    ctx.beginPath();
    points.forEach(point => {
        ctx.lineTo(point.x, point.y);
    });
    ctx.closePath();
    ctx.fill();
}

export function clearSelectedArea(ctx, points, pointPixels = 16) {
    points.forEach(point => {
        ctx.clearRect(point.x - pointPixels / 2 - 1, point.y - pointPixels / 2 - 1, pointPixels + 1, pointPixels + 1);
    });
    console.log(points);
    if(points.length < 2) return;

    const clearRect = points.reduce((acc, p) => {
        const { topLeftPoint, bottomRightPoint } = acc;
        topLeftPoint.x = Math.min(topLeftPoint.x, p.x - 1);
        topLeftPoint.y = Math.min(topLeftPoint.y, p.y - 1);
        bottomRightPoint.x =  Math.max(bottomRightPoint.x, p.x + 1);
        bottomRightPoint.y = Math.max(bottomRightPoint.y, p.y + 1);
        return acc;
    }, {
        topLeftPoint: { x: points[0].x, y: points[0].y },
        bottomRightPoint: { x: 0, y: 0 }
    });
    console.log(clearRect, points);
    const { topLeftPoint, bottomRightPoint } = clearRect;
    const clearRectParams = [
        topLeftPoint.x,
        topLeftPoint.y,
        bottomRightPoint.x - topLeftPoint.x,
        bottomRightPoint.y - topLeftPoint.y];
    ctx.clearRect(...clearRectParams);
}
export function drawText(ctx, point, text, font = '14px Georgia', color = '#fc03a1') {
    ctx.font = font;
    ctx.fillStyle = color;
    ctx.fillText(text, point.x + 5, point.y + 20);
    ctx.globalCompositeOperation = "destination-over";
}
export function drawLine(ctx, startPoint, destinationPoint, color = 'red') {
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.moveTo(startPoint.x, startPoint.y);
    ctx.lineTo(destinationPoint.x, destinationPoint.y);
    ctx.stroke();
}