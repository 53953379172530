import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const jobsSlice = createSlice({
    name: 'jobs',
    initialState: {
        jobs: [],
        selectedJob: {
            Id: null,
            Files: []
        },
        selectedJobFile: {},
        loading: false,
        error: null,
        documents: [],
    },
    reducers: {
        setJobs(state, action) {
            return {
                ...state,
                jobs: action.payload
            }
        },

        setJob(state, action) {

            const normalizePayload = Object.keys(action.payload).reduce((acc, key) => {
                const upperCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
                acc[upperCaseKey] = action.payload[key];
                return acc;
              }, {});

            return {
                ...state,
                jobs: state.jobs.map((job) => 
                    job.Id === normalizePayload.Id
                    ? normalizePayload
                    : job
                )
            }
        },

        setSelectedJob(state, action) {
            return {
                ...state,
                selectedJob: action.payload
            }
        },

        setSelectedJobFile(state, action) {
            return {
                ...state,
                selectedJobFile: action.payload
            }
        },

        setIdentifiedDocuments(state, action) {
            return {
                ...state,
                identifiedDocuments: action.payload
            }
        },
        setRandomFiles(state, action) {
            return {
                ...state,
                randomFiles: action.payload
            }
        },
        setJobExcludedDocs(state, { payload }) {
            return {
                ...state,
                jobExcludedDocs: payload,
            }
        },
        setDocuments(state, { payload }) {
            return {
                ...state,
                documents: payload,
            }
        }
    },
    extraReducers: (builder) => {
        // builder.addCase(fetchGeneralOptions.pending, (state) => {
        //     state.loading = true;
        // })
        // builder.addCase(fetchGeneralOptions.fulfilled, (state, action) => {
        //     state.companies = _.get(action.payload, 'companies') || [];
        //     state.documentPackageTypes = _.get(action.payload, 'documentPackageTypes') || [];
        //     state.loading = false;
        // })
        // builder.addCase(fetchGeneralOptions.rejected, (state, action) => {
        //     state.error = action.payload;
        //     state.loading = false;
        // })
    },
});
export const {
    setJobs,
    setJob,
    setSelectedJob,
    setSelectedJobFile,
    setIdentifiedDocuments,
    setRandomFiles,
    setSelectedJobFilePages,
    setResultValidation,
    setJobExcludedDocs,
    setDocuments,
} = jobsSlice.actions;
export default jobsSlice;
