import _ from 'lodash';
import { documentSlice_KEYS } from 'Shared/Infrastructure/Store/ConfigureStore';
const localState = (state) => _.get(state, 'documents');

export const getCurrentSample = (state) => _.get(localState(state), 'currentSample')|| {};
export const getDocumentTypes = (state) => _.get(localState(state), 'documentTypes') || [];
export const getFiles = (state) => _.get(localState(state), 'files') || [];
export const getFilteredCompanies = (state) => _.get(localState(state), 'filteredCompanies') || [];
export const getDocuments = (state) => _.get(localState(state), 'filteredDocuments') || [];
export const getSelectedFile = (state) => _.get(localState(state), 'selectedFile') || {};
export const getSelectedFileFilter = (state) => _.get(localState(state), 'fileFilter') || {};
export const getSelectedDocumentType = (state) => _.get(localState(state), 'selectedDocumentType') || {};
export const getSelectedFileDocumentType = (state) => _.get(localState(state), 'selectedFileDocumentType') || {};
export const getSelectedDocumentFilter = (state) => _.get(localState(state), 'documentFilter') || {};
export const getSelectedDocument = (state) => _.get(localState(state), 'selectedDocument') || {};
export const getSelectedPages = (state) => _.get(localState(state), documentSlice_KEYS.SELECTED_PAGES) || [];
export const getDataSamples = (state) => _.get(localState(state), 'dataSamples.DataSamples') || [];
export const getSharedIdentifyPages = (state) => _.get(localState(state), 'sharedIdentifyPages') || [];
export const getSharedCurrentPages = (state) => _.get(localState(state), 'sharedCurrentPages') || [];
export const getDataSamplesCount = (state) => _.get(localState(state), 'dataSamplesCount') || [];
export const getDataSamplesCountByFilter = (state) =>_.get(localState(state), 'dataSamples.DataSamplesCount') || 0;
export const getSamplesPagination = (state) => _.get(localState(state), 'samplesPagination') || {};
export const getSelectedDataSamples = (state) => _.get(localState(state), 'selectedDataSamples') || [];
export const getSelectedDataSet = (state) => _.get(localState(state), 'selectedDataSet') || {};
export const getDataSets = (state) => _.get(localState(state), 'dataSets') || [];
export const getDataSamplesStatistics = (state) => _.get(localState(state), 'dataSamplesStatistics');
export const getClassifiers = (state) => _.get(localState(state), 'classifiers') || [];
export const getRecognizers = (state) => _.get(localState(state), 'recognizers') || [];
export const getEntityList = (state) => _.get(localState(state), 'entityList') || [];
export const getIsFilePages = (state) => _.get(localState(state), 'isFilePages');
export const getAiProcessingResult = (state) => _.get(localState(state), 'aiProcessingResult');
export const getShowingAiProcessingResultDialog = (state) => _.get(localState(state), 'showAiProcessingResultDialog') || false;
export const getEntityTypes = (state) => Object.keys(_.get(localState(state), 'entityTypes')).length === 0 ? [] : _.get(localState(state), 'entityTypes');
export const getSelectedEntityType = (state) => _.get(localState(state), 'selectedEntityType') || {};
export const getDocSampleFilter = (state) => _.get(localState(state), 'sampleFilter') || {};
export const getLabels = (state) => Object.keys(_.get(localState(state), 'labels')).length === 0 ? [] : _.get(localState(state), 'labels');
export const getSelectedLabel = (state) => _.get(localState(state), 'selectedLabel') || {};
export const getError = (state) => _.get(localState(state), 'error');

