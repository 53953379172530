import React, {useEffect, useState} from 'react';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Drawer as MuiDrawer,
    CssBaseline, Divider,
    IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    styled,
    Toolbar,
    Typography,
    Collapse,
    useTheme,
    Link
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArticleIcon from '@mui/icons-material/Article';
import FitbitTwoToneIcon from '@mui/icons-material/FitbitTwoTone';
import DatasetIcon from '@mui/icons-material/Dataset';
import HomeIcon from '@mui/icons-material/Home';
import {Link as RouterLink, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Thunks from '../Api/thunk';
import * as RootThunks from '../../../Shared/Infrastructure/Thunks/RootThunk';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import DensitySmallTwoToneIcon from '@mui/icons-material/DensitySmallTwoTone';
import DeblurTwoToneIcon from '@mui/icons-material/DeblurTwoTone';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import _ from "lodash";
import * as Selectors from '../Api/selectors';
import * as Actions from "../../Documents/Api/actions";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LabelIcon from '@mui/icons-material/Label';

const drawerWidth = 240;
const docSelectionSteps = {
    filesSelection: {path: '/files', name: 'File Selection'},
    documentSelection: {path: '/documents', name: 'Document Selection'},
    samples: {path: '/documents/samples', name: 'Data Samples', group: '/documents/preview/' },
    sets: {path: '/documents/sets', name: 'Data Sets'},
    classifiers: {path: '/documents/classifiers', name: 'Classifiers'},
    recognize: {name: 'Recognize', childs: {
        entityTypes: {path: '/documents/recognizers/entity-types', name: 'Entity Types'},
        labels: {path: '/documents/recognizers/labels', name: 'Labels'},
        recognizers: {path: '/documents/recognizers', name: 'Recognizers'},
    }},
    jobs: {name: 'Job Runner', childs: {
        runner: {path: '/jobs/runner', name: 'Job Runner / Configurator'},
        //validation: {path: '/jobs/validation', name: 'Job Runner Validation'},
    }},
    analytics: {path: '/jobs/analytics', name: 'Job Runner Analytics'},
};

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function DocumentsMenu({fetchDocumentTypes, fetchGeneralOptions, documentTypes}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [collapse, setCollapse] = useState({});
    const [selectedStep, setSelectedStep] = useState('filesSelection');
    const [selectedStepName, setSelectedStepName] = useState('Files Selection');

    const { connectToDocumentHub } = Actions.useDocumentHub();

    useEffect(() => {
        connectToDocumentHub();
    }, [])

    useEffect(() => {
        fetchDocumentTypes();
        fetchGeneralOptions();
    }, [fetchDocumentTypes, fetchGeneralOptions]);

    useEffect(() => {
        for (const key of Object.keys(docSelectionSteps)) {
            const step = docSelectionSteps[key];
            if (!_.isNil(step.childs)) {
                const childKey = Object.keys(step.childs).find(childKey =>
                    step.childs[childKey].path === location.pathname);
                if (!_.isNil(childKey)) {
                    setSelectedStep(childKey);
                    setSelectedStepName(step.childs[childKey].name);
                    return;
                }
            }
            if (step.path === location.pathname || location.pathname.includes(key) || location.pathname.includes(step.group)) {
                setSelectedStep(key);
                setSelectedStepName(step.name);
                return;
            }
        }
    }, [location]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleListButtonClick = (path) => {
        navigate(path);
    };

    const setIconForSelectedStep = (key) => {
        switch (key) {
            case 'filesSelection':
                return (<InsertDriveFileIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'documentSelection':
                return (<ArticleIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'samples':
                return (<ArchiveIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'sets':
                return (<DatasetIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'classifiers':
                return (<DeblurTwoToneIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'recognize':
                return (<AppRegistrationTwoToneIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'entityTypes':
                return (<DensitySmallTwoToneIcon sx={{ color: selectedStep === key ? 'primary.contrastText' : null }} />);
            case 'labels':
                return (<LabelIcon sx={{ color: selectedStep === key ? 'primary.contrastText' : null }} />);
            case 'recognizers':
                return (<FitbitTwoToneIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'jobs':
                return (<ContentPasteSearchIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'runner':
                    return (<EngineeringIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            case 'analytics':
                    return (<ShowChartIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
            default:
                return (<AdsClickIcon sx={{color: selectedStep === key ? 'primary.contrastText' : null}}/>);
        }
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 1,
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {selectedStepName}
                    </Typography>
                    <Box sx={{ display: 'flex', position: 'absolute', left: '50%', transform: 'translateX(-50%)', alignItems: 'center'}}>
                     <SmartToyIcon sx={{marginRight: '1rem'}}/>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            QR AI
                        </Typography>
                    </Box>
                    <Box sx={{marginLeft: 'auto'}}>
                        <Button
                            component={Link}
                            href='https://docs.google.com/document/d/1VAR9MyKAuJCaYuRUVNFq_2Efi3L16-VbmKJYjFE2H2k/edit'
                            color="inherit"
                            sx={{color: 'primary.contrastText'}}
                            target="_blank"
                            variant='customSecondaryRoundedText'
                            startIcon={<MenuBookIcon/>}
                        >
                            Guide
                        </Button>
                        <Button
                            variant='customSecondaryRoundedText'
                            sx={{color: 'primary.contrastText', ml: '0.5rem'}}
                            component={RouterLink}
                            to="/home"
                            startIcon={<HomeIcon/>}
                        >
                            Home
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <Typography variant="h6" sx={{marginRight: 'auto'}}>Tabs:</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {Object.keys(docSelectionSteps).map((key) => (
                        <ListItem key={key} disablePadding sx={{
                            display: 'block',
                            backgroundColor: selectedStep === key ? "primary.main" : null
                        }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                onClick={() => {
                                    docSelectionSteps[key].childs 
                                    ? setCollapse(collapse[key] ? {
                                        ...collapse,
                                        [key]: !collapse[key]
                                    } 
                                    : {
                                        ...collapse,
                                        [key]: true
                                    })
                                    : handleListButtonClick(docSelectionSteps[key].path)
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    {setIconForSelectedStep(key)}
                                </ListItemIcon>
                                <ListItemText primary={docSelectionSteps[key].name} sx={{
                                    opacity: open ? 1 : 0,
                                    color: selectedStep === key ? "white" : "black"
                                }}/>
                                {docSelectionSteps[key].childs  
                                    ? collapse[key] ? <ExpandLess /> : <ExpandMore />
                                    : null}
                            </ListItemButton>
                            {docSelectionSteps[key].childs && 
                                <Collapse in={collapse[key]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {Object.keys(docSelectionSteps[key].childs).map((childKey) => (
                                            (
                                                <ListItem key={childKey} disablePadding sx={{
                                                    display: 'block',
                                                    backgroundColor: selectedStep === childKey ? "primary.main" : null
                                                }}>
                                                    <ListItemButton
                                                        sx={{ pl: 4 }}
                                                        onClick={() => handleListButtonClick(docSelectionSteps[key].childs[childKey].path)}
                                                    >
                                                        <ListItemIcon>
                                                            {setIconForSelectedStep(childKey)}
                                                        </ListItemIcon>
                                                        <ListItemText primary={docSelectionSteps[key].childs[childKey].name} sx={{
                                                            opacity: open ? 1 : 0,
                                                            color: selectedStep === childKey ? "white" : "black"
                                                        }}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        ))}
                                    </List>
                                </Collapse>
                            }
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader/>
                {!_.isEmpty(documentTypes)
                    ? <Outlet/>
                    : null}
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    documentTypes: Selectors.getDocumentTypes(state),
})

const mapDispatchToProps = {
    fetchDocumentTypes: Thunks.fetchDocumentTypes,
    fetchGeneralOptions: RootThunks.fetchGeneralOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsMenu);
