import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Thunks from '../../Api/thunk';
import { connect } from 'react-redux';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    IconButton,
    TextField,
    Typography,
    Tabs,
    Tab
} from '@mui/material';
import _ from 'lodash';
import * as JobThunks from './JobsThunk';
import * as Selectors from '../../Api/selectors';
import { FILE_FILTER_DEFAULT_STATE, DOCUMENT_FILTER_DEFAULT_STATE } from '../../Api/slice';
import { debounceFunction } from '../DataSamples/DocumentTypeSamples';
import DocumentImageList from '../../../../Widgets/DocumentSelection/Ui/ImageList';
import * as JobSlice from './JobsSlice';
import * as JobSelectors from './selectors';
import ClearIcon from '@mui/icons-material/Clear';
import * as Colors from './../../../../Shared/Styles/Colors';

const DEFAULT_RANDOM_FILES_COUNT = 10;
const DEFAULT_DOCUMENTS_COUNT = 5;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function aplyTabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function JobRunner({
    fetchClassifiers,
    classifiers,
    identifiedDocuments,
    randomFiles,
    fetchDocumentPreview,
    selectedFile,
    selectedDocument,
    jobExcludedDocs,

    fetchFiles,
    fetchDocuments,
    setShowCreate,
    createJob,
    setJobExcludedDocs,
    documentTypes,
    setDocuments,
}) {
    const [config, setConfig] = useState({
        Classifier: null,
        Description: '',
        MaxPagesPerFile: 10,
        RandomFiles: DEFAULT_RANDOM_FILES_COUNT,
        IdentifiedDocumentsPerDocType: DEFAULT_DOCUMENTS_COUNT,
    });

    const [tabValue, setTabValue] = useState(0);
    const [selectedRowIdx, setSelectedRowIdx] = useState(null);

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [selectedDocId, setSelectedDocId] = useState(null);
    const selectedFileOrDoc = [selectedFile, selectedDocument].find(x => x.DocumentFK == selectedDocId)

    const sortedDocuments = _.orderBy(identifiedDocuments, ['DocumentTypeFk', 'Created'], ['asc', 'desc'])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const loadFiles = (newValue) => {
        const filter = {
            ...FILE_FILTER_DEFAULT_STATE,
            ItemsPerPage: newValue || config.RandomFiles,
            ExcludedDocuments: jobExcludedDocs,
            MaxPagesPerFile: config.MaxPagesPerFile,
        }
        setLoadingFiles(true);
        fetchFiles(filter).then(() => {
            setLoadingFiles(false);
        });
    };
    const loadDocuments = (newValue) => {
        const classifierId = _.get(config, 'Classifier.Id');
        if (!classifierId) {
            return;
        }
        const filter = {
            ...DOCUMENT_FILTER_DEFAULT_STATE,
            ItemsPerPage: newValue || config.IdentifiedDocumentsPerDocType,
            ExcludedDocuments: jobExcludedDocs,
            MaxPagesPerFile: config.MaxPagesPerFile,
        }
        setLoadingDocuments(true);
        fetchDocuments({ classifierId, filter }).then(() => {
            setLoadingDocuments(false);
        });
    };
    useEffect(() => {
        fetchClassifiers();
        return () => {
            setDocuments([])
        }
    }, []);

    useEffect(() => {
        const classifierId = _.get(config, 'Classifier.Id');
        if (!classifierId) {
            return
        }
        loadDocuments();
        loadFiles();
    }, [config.Classifier])

    const changeConfig = (propName, value) => {
        setConfig({ ...config, [propName]: value });
    }

    const handleNumberInput = (propName, value) => {
        const val = value.match(/(\d+)/);
        if (val) {
            const integerValue = +val[0]
            changeConfig(propName, integerValue)
            if (propName === 'RandomFiles') {
                debounceFunction(() => loadFiles(integerValue));
            } else if (propName === 'IdentifiedDocumentsPerDocType') {
                debounceFunction(() => loadDocuments(integerValue));
            }
        }
    }

    const createJobHandler = () => {
        const { Classifier, Description } = config;
        const Documents = identifiedDocuments.map(doc => ({
            DocumentId: doc.Id,
            PagesCount: doc.PagesCount,
            Filename: null,
        }));
        const Files = randomFiles.map(file => ({
            DocumentId: file.Id,
            PagesCount: file.PagesCount,
            Filename: file.FileName,
        }))
        createJob({
            ProcessorFk: Classifier.Id,
            Description: Description,
            Documents,
            Files,
        });
        setShowCreate(false);
    }
    const excludeDocument = (documentId) => {
        const jobExcludedDocsCopy = _.cloneDeep(jobExcludedDocs);
        if (selectedDocId == documentId) {
            setSelectedDocId(null);
        }
        if (jobExcludedDocsCopy.includes(documentId)) {
            return;
        }
        jobExcludedDocsCopy.push(documentId);
        setJobExcludedDocs(jobExcludedDocsCopy);
    }
    const renderDocumentsList = (documents, isFile) => documents.map((doc, idx) => {
        const { FileName, Id, DocumentTypeFk } = doc;
        const name = DocumentTypeFk
            ? _.get(documentTypes.find(x => x.Id === DocumentTypeFk), 'Name')
            : FileName;
        const rowColor = selectedRowIdx === idx
            ? Colors.PRIMARY_LIGHT_COLOR
            : idx % 2 === 1 ? Colors.GREY_HIGHLIGHT_COLOR : null;

        return <Box
            key={`${doc}-${idx}`}
            onClick={() => {
                setSelectedRowIdx(idx);
                setSelectedDocId(Id);
                fetchDocumentPreview({ documentFK: Id, isFile });
            }}
            sx={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', background: rowColor }}
        >
            <Typography>{name}</Typography>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation();
                    excludeDocument(Id)
                }}
                aria-label="delete"
                size="small"
            >
                <ClearIcon color="error" />
            </IconButton>
        </Box>
    })

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Button sx={{ width: '200px' }} variant='customRounded' onClick={() => setShowCreate(false)} >Cancel</Button>
            <Box sx={{ display: 'inline-flex', gap: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <Box sx={{ width: 400, display: 'flex', flexDirection: 'column', gap: '15px', background: 'none' }}>
                        <Autocomplete
                            key="document-types-autocomplete"
                            size="small"
                            sx={{ width: '100%', marginRight: '1rem' }}
                            options={classifiers}
                            value={config.Classifier}
                            getOptionLabel={(option) => option.Name}
                            getOptionKey={(option) => option.ProcessorId}
                            renderInput={(params) => (
                                <TextField {...params} key={params.id} label="Classifier" variant="outlined" />
                            )}
                            onChange={(event, classifier) => changeConfig('Classifier', classifier)}
                        />
                        <TextField
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Description"
                            value={_.get(config, 'Description', '')}
                            onChange={(event) => changeConfig('Description', event.target.value)}
                        />
                        <TextField
                            disabled={_.isNil(config.Classifier)}
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Max pages per file"
                            value={_.get(config, 'MaxPagesPerFile', 10)}
                            onChange={(event) => handleNumberInput('MaxPagesPerFile', event.target.value)}
                            type='number'
                        />
                        <TextField
                            disabled={_.isNil(config.Classifier)}
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Random files"
                            value={_.get(config, 'RandomFiles', 10)}
                            onChange={(event) => handleNumberInput('RandomFiles', event.target.value)}
                            type='number'
                        />
                        <TextField
                            disabled={_.isNil(config.Classifier)}
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Identified documents per Doc. Type"
                            value={_.get(config, 'IdentifiedDocumentsPerDocType', 10)}
                            onChange={(event) => handleNumberInput('IdentifiedDocumentsPerDocType', event.target.value)}
                            type='number'
                        />
                        <Button
                            disabled={_.isNil(config.Classifier)}
                            variant='contained'
                            onClick={createJobHandler}
                            size='small'
                            sx={{ width: 140 }}
                        >
                            Create Job
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="jobs tabs">
                            <Tab label="Identified" {...aplyTabProps(0)} />
                            <Tab label="Random" {...aplyTabProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        {loadingDocuments
                            ? <CircularProgress />
                            : <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                {renderDocumentsList(sortedDocuments, false)}
                            </Box>}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        {loadingFiles
                            ? <CircularProgress />
                            : <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                {renderDocumentsList(randomFiles, true)}
                            </Box>}
                    </CustomTabPanel>
                </Box>

                <Box sx={{ width: '60%' }}>
                    {selectedFileOrDoc
                        ? <DocumentImageList
                            hideControlButtons={true}
                            images={selectedFileOrDoc.ScannedPages}
                            sharedCurrentPages={[]}
                            sharedIdentifyPages={[]}
                            propertyMap={'CdnUrl'}
                            handleImageSelection={_.noop}
                            isImageSelected={false}
                            keyMap={'ScannedPageId'}
                            pageNumberMap={'PageNumber'}
                            // includeIdentifiedPage={includeIdentifiedPage}
                            // identifyDocumentPage={identifyDocumentPage}
                            documentFK={selectedFileOrDoc?.DocumentFK}
                        />
                        : null}
                </Box>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const classifiers = Selectors.getClassifiers(state);
    const allIdentifiedDocuments = JobSelectors.getDocuments(state);
    const allRandomFiles = Selectors.getFiles(state);
    const selectedFile = Selectors.getSelectedFile(state);
    const selectedDocument = Selectors.getSelectedDocument(state);
    const jobExcludedDocs = JobSelectors.getJobExcludedDocs(state);
    const identifiedDocuments = allIdentifiedDocuments.filter(doc => !jobExcludedDocs.includes(doc.Id));
    const randomFiles = allRandomFiles.filter(file => !jobExcludedDocs.includes(file.Id));
    const documentTypes = Selectors.getDocumentTypes(state);
    return {
        classifiers,
        identifiedDocuments,
        randomFiles,
        selectedFile,
        selectedDocument,
        jobExcludedDocs,
        documentTypes,
    }
}

const mapDispatchToProps = {
    fetchClassifiers: Thunks.fetchClassifiers,
    fetchDocumentPreview: Thunks.fetchDocumentPreview,
    fetchFiles: Thunks.fetchFiles,
    fetchDocuments: JobThunks.fetchDocuments,
    createJob: JobThunks.createJob,
    setJobExcludedDocs: JobSlice.setJobExcludedDocs,
    setDocuments: JobSlice.setDocuments,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobRunner);
