import React from 'react';
import {connect} from 'react-redux';
import * as Thunks from '../../../Api/thunk';
import * as Selectors from '../../../Api/selectors';
import AiProcessorsScreen from '../Shared/AiProcessorsScreen';
import DeblurTwoToneIcon from '@mui/icons-material/DeblurTwoTone';

function Classifiers({
                        fetchClassifiers,
                        classifiers,
                        classifyDocument,
                        updateClassifier,
                        removeClassifier,
                        resumeClassifierTraining
                    }) {

    return (
        <AiProcessorsScreen
            fetchAiProcessors={fetchClassifiers}
            aiProcessors={classifiers}
            processDocument={classifyDocument}
            aiProcessingLabel={'Classify Document'}
            updateProcessor={updateClassifier}
            removeProcessor={removeClassifier}
            aiProcessingIcon={<DeblurTwoToneIcon/>}
            resumeTraining={resumeClassifierTraining}
        />
    );
}

const mapStateToProps = (state) => {
    const classifiers = Selectors.getClassifiers(state);

    return {
        classifiers
    }
}

const mapDispatchToProps = {
    fetchClassifiers: Thunks.fetchClassifiers,
    classifyDocument: Thunks.classifyDocument,
    updateClassifier: Thunks.updateClassifier,
    removeClassifier: Thunks.removeClassifier,
    resumeClassifierTraining: Thunks.resumeClassifierTraining
}

export default connect(mapStateToProps, mapDispatchToProps)(Classifiers);
