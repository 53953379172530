import { React, useEffect, useState, useRef, useCallback } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination, IconButton, Tooltip, Box, Popper, TextField, ClickAwayListener, InputAdornment
} from '@mui/material';
import _ from 'lodash';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import * as Helpers from '../Helpers';

const debounceFunction = _.debounce((func) => {
    func();
}, 500);


function TableComponent({
                            columns,
                            rows,
                            handleRowItemClick,
                            handleSelectedRow,
                            enableTablePagination,
                            saveTablePagination,
                            tablePaginationProps,
                            tableContainerSx,
                            selectedSampleId
                        }) {

    const [validatedRows, setValidatedRows] = useState(_.isNil(rows) ? [] : rows);
    const [rowsPerPage, setRowsPerPage] = useState(tablePaginationProps?.RowsPerPage ?? 10);
    const [page, setPage] = useState(tablePaginationProps?.Page ?? 0);
    const [anchor, setAnchor] = useState(null);
    const [popperProps, setPopperProps] = useState(
        columns.reduce((acc, column) => {
            if (column.filterable) {
                acc[column.value] = {
                    label: column.name,
                    columnFilter: column.filter,
                    currentValue: '',
                }
            }

            return acc;
        }, {})
    );
    const [popperKey, setPopperKey] = useState(null);
    const filterFieldRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        if (selectedSampleId && tableRef.current) {
            const selectedRow = tableRef.current.querySelector(`[data-row-id="${selectedSampleId}"]`);
            if (selectedRow) {
                selectedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [selectedSampleId]);
    
    const filterRows = useCallback((popperProps) => {
        const conditions = Object.keys(popperProps).map(key => {
            return {
                filter: !_.isEmpty(popperProps[key].currentValue) ? popperProps[key].columnFilter : (x, value) => true,
                currentValue: popperProps[key].currentValue
            }
        });
        setValidatedRows(rows.filter((value) => {
            return conditions.reduce((prev, current) => {
                return prev && current.filter(value, current.currentValue)
            }, true)
        }));
    }, [rows]);

    useEffect(() => {
        setValidatedRows(_.isNil(rows) ? [] : rows);
        if (enableTablePagination)
            filterRows(popperProps);
    }, [rows, enableTablePagination, filterRows, popperProps]);

    const paginatedRows = () => {
        const result = rowsPerPage > 0 ? validatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : validatedRows;
        return result
    }

    const tableScrollToTop = () => {
        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);

        if (_.isFunction(saveTablePagination))
            saveTablePagination({Page: newPage});

        tableScrollToTop();
    };

    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setRowsPerPage(value);

        if (_.isFunction(saveTablePagination))
            saveTablePagination({RowsPerPage: value, Page: 0});

        setPage(0);
    };

    const handleFilterClick = (event, key) => {
        setAnchor(anchor ? null : event.currentTarget);
        setPopperKey(key);
    }

    const handleFilterClose = (event) => {
        if (filterFieldRef.current && filterFieldRef.current.contains(event.target))
            return;

        setAnchor(null);
    }

    const open = Boolean(anchor);
    const id = open ? 'base-popper' : undefined;

    const handleFilterChange = (event) => {
        const newPopperProp = {
            ...popperProps,
            [popperKey]: {
                ...popperProps[popperKey],
                currentValue: event.target.value
            }
        }
        setPopperProps(newPopperProp);
        debounceFunction(() => {
            filterRows(newPopperProp);
        });
    }

    const handleFilterClearText = () => {
        const newPopperProp = {
            ...popperProps,
            [popperKey]: {
                ...popperProps[popperKey],
                currentValue: ''
            }
        };
        setPopperProps(newPopperProp);
        debounceFunction(() => {
            filterRows(newPopperProp);
        });
    }

    return (
        <Paper sx={{width: '100%'}}>
            <TableContainer sx={tableContainerSx}>
                <Table stickyHeader sx={{minWidth: 500}} ref={tableRef}>
                    <TableHead sx={{backgroundColor: 'primary.main', color: 'primary.contrastText'}}>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (<TableCell key={column.name}
                                                   sx={{
                                                       backgroundColor: 'primary.main',
                                                       color: 'primary.contrastText',
                                                       width: column.columnWidth ?? null,
                                                       maxWidth: column.columnMaxWidth ?? null,
                                                   }}
                                                   align={_.isNil(column.align) ? 'left' : column.align}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        {column.name}
                                        {column.filterable ?
                                            <Box>
                                                <Box>
                                                    <Tooltip title="Filter">
                                                        <IconButton
                                                            sx={{color: 'primary.contrastText'}}
                                                            size='small'
                                                            onClick={(event) => handleFilterClick(event, column.value)}
                                                        >
                                                            {popperProps[column.value]?.currentValue ?
                                                                <FilterListOutlinedIcon fontSize='small'/> :
                                                                <FilterListOffIcon fontSize='small'/>}
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Popper id={id + index} open={open} anchorEl={anchor}>
                                                    <ClickAwayListener onClickAway={handleFilterClose}>
                                                        <Paper sx={{marginTop: '1rem'}} ref={filterFieldRef}>
                                                            <TextField
                                                                label={popperProps[popperKey]?.label}
                                                                variant="filled"
                                                                value={popperProps[popperKey]?.currentValue}
                                                                onChange={handleFilterChange}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position='end'>
                                                                            <IconButton
                                                                                onClick={handleFilterClearText}
                                                                                edge='end'
                                                                                size='small'
                                                                                disabled={!popperProps[popperKey]?.currentValue}
                                                                                sx={{color: 'primary.main'}}
                                                                            >
                                                                                <CloseOutlinedIcon fontSize='small'/>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </Paper>
                                                    </ClickAwayListener>
                                                </Popper>
                                            </Box> : null}
                                    </Box>
                                </TableCell>)
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(enableTablePagination ? paginatedRows() : validatedRows).map((row, rowIdx) => {
                            return (
                                <TableRow
                                    key={`${Helpers.generateGuid()}-${rowIdx}`}
                                    data-row-id={row.Id}
                                    onClick={() => handleRowItemClick(row)}
                                    hover
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    selected={handleSelectedRow(row)}
                                >
                                    {columns.map((column, colIdx) => {
                                        return (<TableCell title={column.isTitleShown && row[column.value]} key={`${Helpers.generateGuid()}-${rowIdx}-${colIdx}`}
                                        sx={{
                                            width: column.columnWidth ?? null,
                                            maxWidth: column.columnMaxWidth ?? null,
                                            overflowWrap: 'break-word',
                                        }}
                                        align={column.align}>{row[column.value]}</TableCell>)
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {enableTablePagination && (
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 50, {value: validatedRows.length, label: 'All'}]}
                    component="div"
                    count={validatedRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Paper>
    );
}

export default TableComponent;
