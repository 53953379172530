import { FetchAjaxProxy } from './FetchAjaxProxy';
import { ProxyFactory } from './FetchAjaxProxyFactory';

class SharedExceptionHandlingProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateSharedProxy('ExceptionHandling');
        this.saveException = (exceptionData) => this.proxy.sendPost(exceptionData, `SaveException`);
        this.getException = (exceptionId) => this.proxy.sendGet(`GetException/${exceptionId}`);
        this.getLength = () => this.proxy.sendGet('GetLength');
        this.getPartOfLog = (partModel) => this.proxy.sendPost(partModel, 'GetPartialData');
        this.getFullLog = () => this.proxy.sendGet('GetFullData');
        this.getSourceStackTrace = (loanId) => this.proxy.sendGet(`GetSourceStackTrace/${loanId}`);
    }
}

class UserProfileProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateUserProxy('Profile');
        this.getUser = (userId) => this.proxy.sendGet(userId);
        this.verifyUserPhone = (userId, phone) => this.proxy.sendPost(phone, `${userId}/Verify`);
        this.updateUserPhone = (userId, model) => this.proxy.sendPost(model, `${userId}/UpdatePhone`);
        this.updateUserProfile = (userId, patch) => this.proxy.sendPatch(patch, `${userId}/Update`);
        this.getUserSubscriptions = (userId) => this.proxy.sendGet(`UserSubscription/${userId}`);
        this.getRightsForUser = (userId) => this.proxy.sendGet(`GetRightsForUser/${userId}`);
        this.updateUserSubscription = (userId, subscription) => this.proxy.sendPost(subscription, `UpdateUserSubscription/${userId}`);
        this.applyUserEventSubscriptionUpdate = (userId, update) => this.proxy.sendPost(update, `UserEventSubscription/${userId}/Update`);
        this.changePassword = (userId, model) => this.proxy.sendPost(model, `${userId}/ChangePassword`);
        this.finishUploading = (fileIdentifier, fileMetadata, userId, imageType) => this.proxy.sendPost(fileMetadata, `${userId}/FinishUploading/${fileIdentifier}/${imageType}`);
        this.getUserPermissions = (userId) => this.proxy.sendGet(`${userId}/Permissions`);
        this.getStateSettings = (userId) => this.proxy.sendGet(`${userId}/StateSettings`);
        this.patchUserStateSetting = (userId, stateId, data) => this.proxy.sendPost(data, `${userId}/StateSetting/${stateId}`);
        //Lo State License
        this.verifyLoStateLicense = (loUserId, stateFk, productId, requirementType, loanId = null) =>
            this.proxy.sendGet(`${loUserId}/LoStateLicense/${stateFk}/${productId}/Verify/${requirementType}${loanId ? '/' + loanId : ''}`);
        this.notifyStateLicenceAdmin = (loUserId, stateFk, lenderCompanyFk) => this.proxy.sendPost(null, `${loUserId}/LoStateLicense/${stateFk}/${lenderCompanyFk}/Notify`);
        this.getLoStateLicense = (loUserId, stateFk, lenderCompanyFk) => this.proxy.sendGet(`${loUserId}/LoStateLicense/${stateFk}/${lenderCompanyFk}`);
        this.createLoStateLicense = (model, loUserId, stateFk, lenderCompanyFk) => this.proxy.sendPost(model, `${loUserId}/LoStateLicense/${stateFk}/${lenderCompanyFk}`);
        this.updateLoStateLicense = (patch, loUserId, stateFk, lenderCompanyFk) => this.proxy.sendPatch(patch, `${loUserId}/LoStateLicense/${stateFk}/${lenderCompanyFk}`);
    }
}

class CompanyProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAdminProxy('Company');
        this.changeReportSettings = (patch, companyId) => this.proxy.sendPatch(patch, `ReportSettings/${companyId}`);
    }
}

class AIAppInfoProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('Info');
        this.getAppInfo = () => {
            const requestPromise = this.proxy.sendGet();
            requestPromise.then((appInfo) => {
                FetchAjaxProxy.SHARED_APIS.BASE_API_ORIGIN = appInfo.SharedApiOrigin;
                if (!FetchAjaxProxy.SHARED_APIS.BASE_API_ORIGIN) return;
                [companyProxy,
                    userProfileProxy,
                    fileUploaderProxy,
                    sharedExceptionHandlingProxy,
                    systemImageProxy
                ].forEach((apiProxy) => {
                    apiProxy.proxy.setBaseApiUrl(FetchAjaxProxy.SHARED_APIS.BASE_API_ORIGIN);
                });
            });
            return requestPromise;
        };
    }
}

class DocumentTypeProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('DocumentType');

        this.getDocumentTypes = () => this.proxy.sendGet();
    }
}

class DocumentProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('Document');

        this.getDocuments = (filter) => this.proxy.sendPost(filter);
        this.getFilteredCompanies = () => this.proxy.sendGet(`FilteredCompanies`);
        this.getDocumentPreview = (documentFK) => this.proxy.sendGet(`Preview/${documentFK}`);
    }
}

class FileProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('UnidentifiedFile');

        this.getFiles = (filter) => this.proxy.sendPost(filter);
    }   
}

class DataSampleProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('DataSample');

        this.getDataSamples = () => this.proxy.sendGet();
        this.getDataSamplesView = (filter) => this.proxy.sendPost(filter, 'view');
        this.getDataSamplesWithFilter = (filter) => this.proxy.sendPost(filter, 'samples');
        this.getDataSampleStatistics = () => this.proxy.sendGet('creation-statistics');
        this.saveDataSample = (request) => this.proxy.sendPost(request);
        this.updateDataSample = (sampleId, patchArray) => this.proxy.sendPatch(patchArray, `${sampleId}`);
        this.deleteDataSample = (sampleId) => this.proxy.sendDelete(`${sampleId}`);

        this.selectAllDataSamples = (docTypeFk) => this.proxy.sendPost(docTypeFk, 'select-all');
        this.getDataSamplePreview = (sampleId) => this.proxy.sendGet(`preview/${sampleId}`);
    }
}
class IdentificationProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('Identification');
        this.identifyDocumentPage = (request) => this.proxy.sendPost(request, 'page-identification');
        this.identifyDocument = (request) => this.proxy.sendPost(request, 'doc-identification');
    }
}

class ClassifierProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('Classifier');

        this.getClassifiers = () => this.proxy.sendGet();
        this.submitTrainClassifier = (dataSetId) => this.proxy.sendGet(`${dataSetId}`);
        this.classifyDocument = (data) => this.proxy.sendFilePostRequest(data, 'TestClassifier');
        this.updateClassifier = (patchArray, processorId) => this.proxy.sendPatch(patchArray, processorId);
        this.deleteClassifier = (processorId) => this.proxy.sendDelete(processorId);
        this.resumeTraining = (processorId) => this.proxy.sendGet(`resume-training/${processorId}`);
    }
}
class RecognizerProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('Recognizer');

        this.getRecognizers = () => this.proxy.sendGet();
        this.submitTrainRecognizer = (dataSetId) => this.proxy.sendGet(`${dataSetId}`);
        this.recognizeDocumentEntities = (data) => this.proxy.sendFilePostRequest(data, 'TestRecognizer');
        this.updateRecognizer = (patchArray, processorId) => this.proxy.sendPatch(patchArray, `${processorId}`);
        this.getEntityTypes = () => this.proxy.sendGet('EntityTypes');
        this.addEntityType = (entityType) => this.proxy.sendPost(entityType, 'EntityTypes');
        this.deleteEntityType = (entityTypesListId) => this.proxy.sendDelete(`EntityTypes/${entityTypesListId}`);
        this.getLabels = () => this.proxy.sendGet('Labels');
        this.addLabel = (label) => this.proxy.sendPost(label, 'Labels');
        this.deleteLabel = (labelId) => this.proxy.sendDelete(`Labels/${labelId}`);
        this.updateLabel = (labelId, patchArray) => this.proxy.sendPatch(patchArray, `Labels/${labelId}`);
    }
}

class DataSetProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('DataSet');

        this.getDataSets = () => this.proxy.sendGet();
        this.saveDataSet = (dataSet) => this.proxy.sendPost(dataSet);
        this.resumeCreating = (dataSetId) => this.proxy.sendGet(`resume-creating/${dataSetId}`);
        this.deleteDataSet = (id) => this.proxy.sendDelete(id);
    }
}

class AiGeneralInfoProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('General');

        this.getGeneralInfo = () => this.proxy.sendGet();
    }
}

class FileUploaderProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateSharedProxy('uploader');
        this.setMetadata = (fileIdentifier, fileMetadata) =>
            this.proxy.sendPost(fileMetadata, `setFileMetadata/${fileIdentifier}`);
        this.setChunk = (fileIdentifier, fileChunk, isS3Image = false) =>
            this.proxy.sendPost(fileChunk, `setFileChunk/${fileIdentifier}/${isS3Image}`);
        this.setBinaryChunk = (binaryContent, fileIdentifier, partNumber) =>
            this.proxy.sendFilePostRequest(binaryContent, `setBinaryFileChunk/${fileIdentifier}/${partNumber}`, true);
    }
}

class SystemImageProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateUserProxy('SystemImage');
        this.getUserImages = (userId) => this.proxy.sendGet(`${userId}/images`);
        this.applyOperations = (request, imageId) => this.proxy.sendPost(request, `image/${imageId}/ApplyOperations`);
        this.resetOperations = (request, imageId) => this.proxy.sendPost(request, `image/${imageId}/Reset`);
    }
}

class JobProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('Job');
        this.getJobs = () => this.proxy.sendGet('');
        this.stopJob = (jobId) => this.proxy.sendGet(`stop/${jobId}`);
        this.cancelJob = (jobId) => this.proxy.sendGet(`cancel/${jobId}`);
        this.getJobFiles = (id) => this.proxy.sendGet(`${id}/files`);
        this.getFilePreview = (aiFileId) => this.proxy.sendGet(`file-preview/${aiFileId}`);
        this.updateJobFilePage = (pageId, patchArray) => this.proxy.sendPatch(patchArray, `filePage-validation/${pageId}`);
        this.getDocuments = (classifierId, filter) => this.proxy.sendPost(filter, `${classifierId}/documents`);
        this.createJob = (job) => this.proxy.sendPost(job, '');
    }
}

class AiClassifierProxy {
    constructor() {
        this.proxy = ProxyFactory.CreateAIProxy('AiClassifierQueue');
        this.startJob = (jobId) => this.proxy.sendGet(`Add/${jobId}`);
        this.resetJob = (jobId) => this.proxy.sendGet(`Reset/${jobId}`);
    }
}

const aiAppInfoProxy = new AIAppInfoProxy();
const documentTypeProxy = new DocumentTypeProxy();
const documentProxy = new DocumentProxy();
const dataSampleProxy = new DataSampleProxy();
const dataSetProxy = new DataSetProxy();
const aiGeneralInfoProxy = new AiGeneralInfoProxy();
const loanProxy = {};
const fileUploaderProxy = new FileUploaderProxy();
const sharedExceptionHandlingProxy = new SharedExceptionHandlingProxy();
const userProfileProxy = new UserProfileProxy();
const companyProxy = new CompanyProxy();
const systemImageProxy = new SystemImageProxy();
const classifierProxy = new ClassifierProxy();
const recognizerProxy = new RecognizerProxy();
const fileProxy = new FileProxy();
const identificationProxy = new IdentificationProxy();
const jobProxy = new JobProxy();
const aiClassifierProxy = new AiClassifierProxy();
export {
    jobProxy,
    aiClassifierProxy,
    recognizerProxy,
    aiAppInfoProxy,
    fileProxy,
    documentTypeProxy,
    documentProxy,
    dataSampleProxy,
    dataSetProxy,
    aiGeneralInfoProxy,
    companyProxy,
    classifierProxy,
    loanProxy,
    fileUploaderProxy,
    sharedExceptionHandlingProxy,
    userProfileProxy,
    systemImageProxy,
    identificationProxy
};
