import React, {useEffect, useState, } from 'react';
import {connect} from 'react-redux';
import * as Thunks from '../../../Api/thunk';
import * as SliceActions from '../../../Api/slice';
import * as Selectors from '../../../Api/selectors';
import {
    Box, Button, CircularProgress } from '@mui/material';
import TableComponent from "../../../../../Shared/Components/TableComponent";
import AiProcessingResultPopup from './AiProcessingResultPopup';
import { Typography, styled  } from '@mui/material';
import DeleteItemDialog from "./../../../../../Widgets/DeleteItemDialog";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import _ from 'lodash';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const processorStatuses = {
    READY_TO_USE: 'READY_TO_USE',
    IN_ERROR: 'IN_ERROR'
};

function AiProcessorsScreen({
                        fetchAiProcessors,
                        aiProcessors,
                        processDocument,
                        aiProcessingLabel,
                        updateProcessor,
                        removeProcessor,
                        aiProcessingIcon,
                        resumeTraining,
                        setShowingAiProcessingResultDialog
                    }) {

    const tableColumns = [
        {
            name: 'Id',
            value: 'ProcessorId',
            align: 'left',
        },
        {
            name: 'Name',
            value: 'Name',
            align: 'left',
        },
        {
            name: 'Status',
            value: 'Status',
            align: 'left',
        },
        {
            name: 'Created',
            value: 'CreationTime',
            align: 'left',
        },
        {
            align: 'left',
            name: 'Actions',
            value: 'Actions',
        }
    ];

    const [loadingAiProcessors, setLoadingAiProcessors] = useState(false);
    const [processedFileName, setProcessedFileName] = useState(null);
    const [processedFile, setProcessedFile] = useState(null);
    const [selectedAiProcessor, setSelectedAiProcessor] = useState(null);
    
    const [openDeletingDialog, setOpenDeletingDialog] = useState(false);
    const [deletingDialogData, setDeletingDialogData] = useState(null);

    const handleSelectedRow = (row) => {
        if(row.Status === processorStatuses.READY_TO_USE) {
            return row.ProcessorId === _.get(selectedAiProcessor, 'ProcessorId');
        }
    }

    const handleRowItemClick = (row) => {
        const originalSelectedAiProcessor = aiProcessors.find(x => x.ProcessorId === row.ProcessorId);
        setSelectedAiProcessor(originalSelectedAiProcessor);
        setProcessedFileName(null);
    }

    const handleAttachingProcessorFile = (e) => {
        setProcessedFile(e.target.files[0]);
        setProcessedFileName(e.target.value.split('\\').at(-1))
    }

    const handleProcessDocumentsClick = () => {
        setShowingAiProcessingResultDialog(true);
        const formData = new FormData();
        formData.append('file', processedFile);
        const data = formData;

        processDocument({data});
    }

    const handleIsActiveAiProcessor = (item) => {
        const processorId = item?.Id;
        const patchArray = [
          { op: 'replace', path: '/IsActive', value: true },
        ];
        updateProcessor({ patchArray, processorId });
    }
      
    const handleDeleteProcessorClick = (item) => {
        setDeletingDialogData({deleteAction: () => removeProcessor(item.Id), itemName: item.Name});
        setOpenDeletingDialog(true);
    }

    const calculateRows = () => {
        return aiProcessors 
          ? aiProcessors.map((item, index) => {
                const creationTime = new Date(item.CreationTime);
                const isReadyToUse = item.Status === processorStatuses.READY_TO_USE;
                return {
                ...item,
                CreationTime: `${creationTime.toLocaleDateString()} ${creationTime.toLocaleTimeString()}`,
                Actions: 
                    (isReadyToUse ? <Box key={`aiProcessorId-${index}`}>
                        {!item.IsActive  
                            ? 
                                <Box>
                                    <Button
                                        variant="customRoundedText"
                                        size="small"
                                        onClick={() => {
                                            handleIsActiveAiProcessor(item)
                                        }}
                                        >
                                        Set Active
                                    </Button>
                                    <Button
                                        sx={{
                                            marginLeft: '0.8rem',
                                        }}
                                        variant="customRoundedTextDanger"
                                        size="small"
                                        onClick={() => handleDeleteProcessorClick(item)}
                                    >
                                        Delete
                                    </Button>     
                                </Box>
                            :                          
                                <Button
                                    sx={{
                                        marginLeft: '0.8rem',
                                    }}
                                    variant="customRoundedText"
                                    size="small"
                                    disabled={true}
                                >
                                    Active
                                </Button> 
                        }              
                    </Box>
                    :
                        item.ShowResumeButton ?                             
                            <Button
                                variant="customRoundedText"
                                size="small"
                                onClick={() => {
                                    resumeTraining(item.Id);
                                }}
                            >
                                Resume Training
                            </Button> 
                        : item.Status === processorStatuses.IN_ERROR
                            ? 
                                <Button
                                    sx={{
                                        marginLeft: '0.8rem',
                                    }}
                                    variant="customRoundedTextDanger"
                                    size="small"
                                    onClick={() => handleDeleteProcessorClick(item)}
                                >
                                    Delete
                                </Button>
                            :   null
                        )
                };
            })
          : [];
      }
      

    useEffect(() => {
        fetchAiProcessors().then(() => {
            setLoadingAiProcessors(false);
        });
    }, [fetchAiProcessors]);

    return (
        <Box>
            {loadingAiProcessors ?
                <CircularProgress/> :
                <TableComponent
                    columns={tableColumns}
                    rows={calculateRows()}
                    enableTablePagination={true}
                    handleSelectedRow={handleSelectedRow}
                    handleRowItemClick={handleRowItemClick}
                />}
            
            <AiProcessingResultPopup processingFileName={processedFileName} />

            <DeleteItemDialog
                openDialog={openDeletingDialog}
                setOpenDialog={setOpenDeletingDialog}
                {...deletingDialogData}
            />

            {!loadingAiProcessors && aiProcessors.some(x => x.IsActive) && 
                <Box sx={{position: 'absolute', bottom: '37px', right: 20, display: 'flex'}}>
                    {processedFile && processedFileName &&
                        <Typography variant="h6" component="h6">Your file name is: {processedFileName}</Typography>}
                    <Button
                        sx={{marginLeft: 5}}
                        component="label"
                        variant="customRounded"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload file
                        <VisuallyHiddenInput type="file" onChange={handleAttachingProcessorFile}/>
                    </Button>
                    {processedFile && processedFileName &&
                        <Button 
                            component="label"
                            sx={{marginLeft: 5}}
                            variant="customRounded"
                            onClick={handleProcessDocumentsClick}
                            startIcon={aiProcessingIcon}
                        >
                            {aiProcessingLabel}
                        </Button>
                    }                        
                </Box>}
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = {
    setShowingAiProcessingResultDialog: SliceActions.setShowingAiProcessingResultDialog
}

export default connect(mapStateToProps, mapDispatchToProps)(AiProcessorsScreen);
