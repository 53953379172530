import {useLocation} from 'react-router-dom';

export const generateGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
    function(c) {
       var uuid = Math.random() * 16 | 0, v = c === 'x' ? uuid : (uuid & 0x3 | 0x8);
       return uuid.toString(16);
    });
 }

 export const useQuery = () => {
   return new URLSearchParams(useLocation().search);
}