import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import * as Selectors from '../../Api/selectors';
import * as Thunks from '../../Api/thunk';
import * as Actions from '../../Api/actions';
import * as SliceActions from '../../Api/slice';
import PreviewImageList from '../../../../Widgets/PreviewPages/PreviewImageList';
import { useNavigate, useParams } from 'react-router-dom';
import ArchiveIcon from '@mui/icons-material/Archive';
import _ from 'lodash';
import { LabelingModule } from 'modules/LabelingModule';
import { getLabeledAreaView } from 'Common/CoreComponents/constants';
import { SampleDetailsEditor } from './SampleDetailsEditor';

function PreviewPages(props) {
    const {
        pageLabeledAreasMap,
        labelOptions,
        fetchLabels,
        selectedDocumentType,
        selectedFileDocumentType,
        selectedPages,
        saveDataSample,
        entityList,
        setEntityList,
        fetchEntityTypes,
        entityTypes,
        updateDataSample,
        addNewEntity,
        updateEntity,
        deleteEntity,
        documentTypes,
        setSelectedFileDocumentType,
        selectedDocument,
        filteredDocuments,
        getDataSamplePreview,
        setSelectedPages,
        setSelectedDocumentType,
        setSelectedFileFilter,
        fileFilter,
        isFromFile,
        filteredFiles,
        selectedFile,
        dataSamples,
        selectedDataSamples,
        sampleFilter,
        setSelectedFile,
        setSelectedDocument,
        addLabeledArea,
        removeLabeledArea,
        updateLabeledArea,
        currentSample,
        setCurrentSample
    } = props;
    const selectedDocumentIndex = isFromFile
        ? filteredFiles && filteredFiles.findIndex(doc => doc.Id === selectedFile?.DocumentFK) + 1
        : filteredDocuments && filteredDocuments.findIndex(doc => doc.Id === selectedDocument?.DocumentFK) + 1

    const filteredSamples = dataSamples && Array.isArray(dataSamples)
        ? dataSamples.filter((x) =>
            (!sampleFilter.Name || x.Name.toLowerCase().includes(sampleFilter?.Name.toLowerCase())) &&
            (!sampleFilter.Id || x.Id == sampleFilter?.Id) &&
            (!sampleFilter || !sampleFilter.Status || x.Status === sampleFilter.Status))
        : [];
    const generatedSampleName =
    currentSample?.Name ||
    (selectedDocumentType?.Name
        ? `${selectedDocumentType.Name.replace(/\s/g, '')}_${selectedDocumentIndex}`
        : selectedFileDocumentType?.Name && isFromFile
            ? `${selectedFileDocumentType.Name.replace(/\s/g, '')}_${selectedDocumentIndex}`
            : null
    );

    const { sampleId } = useParams();
    const isNewSample = sampleId === 'new';
    const [showLabeledAreas, setShowLabeledAreas] = useState(true);
    function showLabeledAreasClickHandler() {
        setShowLabeledAreas(!showLabeledAreas);
    }
    const [isNavigated, setNavigated] = useState(false);
    const navigate = useNavigate();
    const { unselectSample } = Actions.useCustomDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const isPopoverOpen = Boolean(anchorEl);
    const popoverId = isPopoverOpen ? 'simple-popover' : undefined;

    //#region Effects
    useEffect(() => {
        fetchEntityTypes();
    }, [fetchEntityTypes]);

    useEffect(() => {
        async function getPages() {
            if (isNewSample || !isNavigated) {
                setNavigated(true);
                return;
            }
            await fetchAndUpdatePageData();
        }
        setCurrentSample({ ...currentSample, Name: generatedSampleName });
        getPages();
        fetchLabels();
        return () => {
            setEntityList([]);
        };
    }, []);

    useEffect(() => {
        async function getPages() {
            if (isNewSample) {
                return;
            }
            await fetchAndUpdatePageData();
        }
        getPages();
        return () => {
            setEntityList([]);
        };
    }, [sampleId]);

    useEffect(() => {
        if (isNewSample && (_.isEmpty(currentSample?.DocumentPages))) {
            navigate(-1);
        }
    }, [isNewSample, currentSample]);
    //#endregion Effects

    //#region Functions
    const fetchAndUpdatePageData = async () => {
        const dataSamplePreview = await getDataSamplePreview(sampleId);
        const pages = _.get(dataSamplePreview, 'payload.DocumentPages') || null;
        const docTypeFk = _.get(dataSamplePreview, 'payload.DocumentTypeFK') || null;
        const docType = documentTypes.find(x => x.Id === docTypeFk) || {};
        setSelectedDocumentType(docType);
        setSelectedPages(pages);
    };

    const handleSaveDataSample = async (newDocPages) => {
        const { Id, ...newSample } = currentSample;
        await saveDataSample({ ...newSample, 
            DocumentTypeFK: _.isEmpty(selectedDocumentType) ? selectedFileDocumentType.Id : selectedDocumentType.Id, 
            DocumentPages: newDocPages, 
            EntityList: currentSample?.EntityList || [], 
            LabeledAreas: currentSample?.LabeledAreas || {}
        })
        setSelectedPages([]);
        const prevScannedPages = isFromFile ? selectedFile?.ScannedPages : selectedDocument?.ScannedPages;
        const updatedScannedPages = {
            DocumentFK: isFromFile ? selectedFile.DocumentFK : selectedDocument.DocumentFK,
            ScannedPages: prevScannedPages.filter(page => !newDocPages[0]?.ScannedPages?.includes(page))
        }
        setCurrentSample({});
        isFromFile ? setSelectedFile(updatedScannedPages) : setSelectedDocument(updatedScannedPages);
        navigate(-1);
    };

    const selectDataSampleById = async (sampleId) => {
        const dataSample = dataSamples.find(dataSample => dataSample.Id === sampleId) || selectedDataSamples.find(dataSample => dataSample.Id === sampleId);
        const selectedDocType = dataSample && documentTypes.find((type) => type.Id === dataSample.DocumentTypeFK);
        setSelectedDocumentType(selectedDocType);
    }

    const handleNextSampleButton = (currentSample) => {
        const currentSampleIndex = currentSample && filteredSamples.length > 0
            ? filteredSamples.findIndex((sample) => sample.Id === currentSample?.Id)
            : null;
        if (currentSampleIndex !== -1 && currentSampleIndex < filteredSamples.length - 1) {
            const nextSample = filteredSamples ? filteredSamples[currentSampleIndex + 1] : null;
            selectDataSampleById(nextSample?.Id);
            navigate(`/documents/preview/${nextSample?.Id}`);
        }
    };

    const handlePreviousSampleButton = (currentSample) => {
        const currentSampleIndex = currentSample && filteredSamples.length > 0
            ? filteredSamples.findIndex((sample) => sample.Id === currentSample?.Id)
            : null;
        if (currentSampleIndex !== -1 && currentSampleIndex > 0) {
            const previousSampleId = filteredSamples ? filteredSamples[currentSampleIndex - 1] : null;
            selectDataSampleById(previousSampleId?.Id);
            navigate(`/documents/preview/${previousSampleId?.Id}`);
        }
    };
    //#endregion Functions

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '2rem' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '40%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: 'auto' }}>
                    <SampleDetailsEditor {...{
                        pageLabeledAreasMap,
                        labelOptions,
                        selectedDocumentType,
                        selectedFileDocumentType,
                        selectedPages,
                        entityList,
                        entityTypes,
                        updateDataSample,
                        addNewEntity,
                        updateEntity,
                        deleteEntity,
                        documentTypes,
                        setSelectedFileDocumentType,
                        selectedDocumentIndex,
                        setSelectedDocumentType,
                        setSelectedFileFilter,
                        fileFilter,
                        isFromFile,
                        removeLabeledArea,
                        currentSample,
                        setCurrentSample,
                        sampleId,
                        showLabeledAreasClickHandler,
                        showLabeledAreas,
                        setEntityList,
                        generatedSampleName
                    }} />
                </Box>
                {!isNewSample
                    ? <Box style={{ position: 'fixed', bottom: 0, width: '35%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px', transformOrigin: 'bottom left' }}>
                        <Button
                            aria-describedby={popoverId}
                            variant="customRounded"
                            style={{ flex: '0 0 auto' }}
                            onClick={() => navigate(`/documents/samples/${selectedDocumentType?.Id}`)}
                            startIcon={<ChevronLeftIcon />}
                        >
                            Back to List
                        </Button>
                        {!isNewSample
                            ? <Box sx={{ display: 'flex', gap: 5 }}>
                                <Button
                                    disabled={currentSample?.Id === _.get(filteredSamples, '[0].Id', null) || _.isEmpty(filteredSamples)}
                                    aria-describedby={popoverId}
                                    variant="customRounded"
                                    style={{ flex: '0 0 auto' }}
                                    onClick={() => handlePreviousSampleButton(currentSample)}
                                    startIcon={<ChevronLeftIcon />}
                                >
                                    Previous Sample
                                </Button>
                                <Button
                                    disabled={currentSample?.Id === filteredSamples[filteredSamples.length - 1]?.Id || _.isEmpty(filteredSamples)}
                                    aria-describedby={popoverId}
                                    variant="customRounded"
                                    style={{ flex: '0 0 auto' }}
                                    onClick={() => handleNextSampleButton(currentSample)}
                                    endIcon={<NavigateNextIcon />}
                                >
                                    Next Sample
                                </Button>
                            </Box>
                            : null}
                    </Box>
                    : null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '60%', maxWidth: '56rem' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%', mb: '0.4rem' }}>
                    <Typography variant="h6">Check selected pages:</Typography>
                    {isNewSample
                        ? <Button
                            sx={{ width: '12rem', position: 'absolute', right: 0 }}
                            onClick={() => {
                                setCurrentSample({ ...currentSample, DocumentPages: selectedPages });
                                handleSaveDataSample(currentSample?.DocumentPages);
                            }}
                            variant="customRounded"
                            startIcon={<ArchiveIcon />}
                            disabled={!(currentSample?.Name && (!_.isEmpty(selectedDocumentType) || !_.isEmpty(selectedFileDocumentType)))}
                        >Save sample</Button>
                        : null}
                </Box>
                <PreviewImageList
                    key="full-size-images"
                    selectedDocumentPages={currentSample?.DocumentPages || []}
                    propertyMap={'CdnUrl'}
                    keyMap={'ScannedPageId'}
                    renderLabelingModule={(data) => {
                        const { index, imgRef, pageId } = data;
                        if (!pageLabeledAreasMap[pageId])
                            return null;

                        return <LabelingModule {...{
                            showLabeledAreas,
                            imgRef,
                            labeledAreas: pageLabeledAreasMap[pageId],
                            labelOptions,
                            addLabeledAreaHandler: (labeledArea) => addLabeledArea({ pageId, labeledArea, sampleId }),
                            updateLabeledAreaHandler: (labeledArea, labeledAreaId) => updateLabeledArea({ labeledAreaId, pageId, labeledArea, sampleId }),
                        }} />;
                    }}
                />
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const documentTypes = Selectors.getDocumentTypes(state);
    const selectedDocumentType = Selectors.getSelectedDocumentType(state);
    const selectedFileDocumentType = Selectors.getSelectedFileDocumentType(state);
    const entityTypes = Selectors.getEntityTypes(state);
    const selectedDocument = Selectors.getSelectedDocument(state);
    const selectedFile = Selectors.getSelectedFile(state);
    const entityList = Selectors.getEntityList(state);
    const filteredDocuments = Selectors.getDocuments(state);
    const selectedPages = Selectors.getSelectedPages(state);
    const isFromFile = Selectors.getIsFilePages(state);
    const documentFilter = Selectors.getSelectedDocumentFilter(state);
    const fileFilter = Selectors.getSelectedFileFilter(state);
    const filteredFiles = Selectors.getFiles(state);
    const dataSamples = Selectors.getDataSamples(state);
    const selectedDataSamples = Selectors.getSelectedDataSamples(state);
    const sampleFilter = Selectors.getDocSampleFilter(state);
    const labelOptions = Selectors.getLabels(state);
    const currentSample = Selectors.getCurrentSample(state);
    const sharedCurrentPages = Selectors.getSharedCurrentPages(state);
    const sharedIdentifyPages = Selectors.getSharedIdentifyPages(state);

    const newSmaple = currentSample?.Id === 'new';
    const _selectedPages = currentSample?.DocumentPages || selectedPages;
    const pages =
        _selectedPages
            .map(document => ({
                ...document,
                ScannedPages: newSmaple ?
                document.ScannedPages
                        .filter(page => !page.IsIdentified
                            && !(sharedCurrentPages || []).find(x => x === page.ScannedPageId)
                            && !(sharedIdentifyPages || []).find(x => x === page.ScannedPageId))
                    : document.ScannedPages
            }));
    const pagesMap = pages
        .flatMap(r => r.ScannedPages)
        .reduce((acc, p) => ({
            ...acc,
            [p.ScannedPageId]: (currentSample?.LabeledAreas[p.ScannedPageId] ?? [])
                .map((labeledAreaDto , index) => getLabeledAreaView(labeledAreaDto, labelOptions, index))
        }), {});
    return {
        currentSample,
        pageLabeledAreasMap: pagesMap,
        labelOptions,
        entityTypes,
        documentTypes,
        selectedPages,
        selectedDocumentType,
        selectedFileDocumentType,
        selectedDocument: isFromFile ? selectedFile : selectedDocument,
        entityList,
        filteredDocuments,
        documentFilter,
        fileFilter,
        isFromFile,
        filteredFiles,
        selectedFile,
        dataSamples,
        selectedDataSamples,
        sampleFilter
    };
};

const mapDispatchToProps = {
    fetchEntityTypes: Thunks.fetchEntityTypes,
    fetchLabels: Thunks.fetchLabels,
    saveDataSample: Thunks.saveDataSample,
    updateDataSample: Thunks.updateDataSample,
    addNewEntity: Thunks.addNewEntity,
    updateEntity: Thunks.updateEntity,
    deleteEntity: Thunks.deleteEntity,
    getDataSamplePreview: Thunks.getDataSamplePreview,
    setSelectedDocumentType: SliceActions.setSelectedDocumentType,
    setSelectedFileDocumentType: SliceActions.setSelectedFileDocumentType,
    setEntityList: SliceActions.setEntityList,
    setSelectedFileFilter: SliceActions.setSelectedFileFilter,
    setSelectedPages: SliceActions.setSelectedPages,
    setSelectedFile: SliceActions.setSelectedFile,
    setSelectedDocument: SliceActions.setSelectedDocument,
    addLabeledArea: Thunks.addLabeledArea,
    removeLabeledArea: Thunks.removeLabeledArea,
    updateLabeledArea: Thunks.updateLabeledArea,
    setCurrentSample: SliceActions.setCurrentSample
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPages);
