import React, { useEffect, useState } from 'react';
import * as Selectors from './selectors.js';
import * as Thunks from './JobsThunk.js';
import * as SliceJob from './JobsSlice.js'
import * as SelectorsDoc from '../../Api/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Autocomplete, TextField, CircularProgress, Typography, Toolbar, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import _ from 'lodash';
import TableComponent from '../../../../Shared/Components/TableComponent';
import DoneIcon from '@mui/icons-material/Done';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PreviewImageList from '../../../../Widgets/PreviewPages/PreviewImageList';

const tableColumns = [
    {
        align: 'left',
        name: 'Name',
        value: 'FileName',
        columnMaxWidth: '16rem',
        isTitleShown: true,
    },
    {
        align: 'left',
        name: 'Validated',
        value: 'Identified',
    }
];

function JobResultValidation({
    setSelectedJob,
    selectedJobFile,
    setSelectedJobFile,
    updateJobFilePage,
    documentTypes,
    fetchJobFilePreview,
    fetchJobFiles,
    fileFilter,
    selectedJob,
    jobFiles
}) {

    const [loadingJobFiles, setLoadingJobFiles] = useState(false);
    const [loadingFilePreview, setLoadingFilePreview] = useState(false);
    const navigate = useNavigate();
    let { jobId, fileId } = useParams();

    useEffect(() => {
        if (!jobId) return;
        setLoadingJobFiles(true);
        fetchJobFiles(jobId).then(() => {
            setLoadingJobFiles(false);
        });
    }, [jobId, fetchJobFiles])

    useEffect(() => {
        if (!fileId) {
            setSelectedJobFile({});
            return;
        }
        setLoadingFilePreview(true);
        fetchJobFilePreview(fileId).then(() => {
            setLoadingFilePreview(false);
        });
    }, [fileId, fetchJobFilePreview])

    const getRows = () => {
        return selectedJob.Files.map((value) => {
            if (!fileFilter.IsIdentified && value.IdentifiedPages === value.PagesCount) {
                return null;
            }
            const validated = value.ValidatedPages !== value.PagesCount
                ? `${value.ValidatedPages}/${value.PagesCount}`
                : <DoneIcon sx={{ color: 'primary.main' }} />;
            const documentTypeName = documentTypes && documentTypes.find(x => x.Id === value.DocumentTypeFk)?.Name;

            return {
                Id: value.Id,
                FileName: value.FileName || documentTypeName,
                Identified: validated,
                PagesCount: value.PagesCount
            }
        }).filter(row => row !== null);
    };
    //#region Functions
    const handleRowItemClick = (value) => {
        if (selectedJobFile.Id !== value.Id) {
            navigate(`/jobs/validation/${selectedJob.Id}/${value.Id}`);
        }
    }

    const handleSelectedRow = (row) => {
        return row.Id === selectedJobFile.Id;
    }

    const patchJobFilePage = (pageId, array) => {
        const patchArray = array.map(x => {
            return {
                op: 'replace',
                path: `/${x.path}`,
                value: x.value
            }
        });
        updateJobFilePage({ pageId, patchArray });
    }

    const updateScannedPages = (docTypeFk, scannedPageIndex, updatedJobFile) => {
        updatedJobFile.ScannedPages[scannedPageIndex].ValidatedDocumentTypeFk = docTypeFk;
        const updatedPages = updatedJobFile.ScannedPages.filter(page => page.ValidatedDocumentTypeFk !== null);
        return { updatedPages};
    };

    const handleStatusChange = (event, docType, image) => {
        const updatedJobFile = _.cloneDeep(selectedJobFile);
        const docTypeFk = documentTypes.find(x => x.Name.trim().toLowerCase() == docType?.trim().toLowerCase())?.Id || null;
        const scannedPageIndex = updatedJobFile?.ScannedPages.findIndex(
            page => page?.ScannedPageId === image?.ScannedPageId
        );
        if (scannedPageIndex !== -1) {
            const { updatedPages } = updateScannedPages(docTypeFk, scannedPageIndex, updatedJobFile);
            if (updatedPages && selectedJob && selectedJob.Files) {
                setSelectedJobFile(updatedJobFile);
                if (!image.ValidatedDocumentTypeFk)
                    setSelectedJob({
                        ...selectedJob,
                        Files: selectedJob.Files.map((file) => {
                            if (file.Id === image.AiJobFileFk)
                                return {...file,
                                    ValidatedPages: file.ValidatedPages !== file.PagesCount 
                                        ? file.ValidatedPages + 1 
                                        : file.ValidatedPages}
                            return file;
                        })
                    })
                patchJobFilePage(image?.Id, [{ path: 'ValidatedDocumentTypeFk', value: docTypeFk }]);
            }
        }
    };

    const handleNextPageToValidateButton = (image, isLastImage) => {
        if (isLastImage) {
            const currentIndex = jobFiles.findIndex(jobFile => jobFile.Id === selectedJobFile.Id);
            if (currentIndex !== -1 && currentIndex < jobFiles.length - 1) {
                setLoadingFilePreview(true);
                fetchJobFilePreview(jobFiles[currentIndex + 1].Id).then(() => {
                    setLoadingFilePreview(false);
                });
            }
        }
        const toolbarId = `toolbar-${image.ScannedPageId}-${image.ValidatedDocumentTypeFk}`;
        const toolbarElements = Array.from(document.querySelectorAll(`.toolbar`)).filter(
            (toolbarElement) => toolbarElement.id.endsWith('-NotValidated')
        );
        let scrollToNext = true;
        toolbarElements.forEach((toolbarElement, index) => {
            if (scrollToNext) {
                toolbarElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                scrollToNext = false;
            }
            if (toolbarElement.id === toolbarId) {
                scrollToNext = true;
            }
        });
    };

    const renderToolbar = (idx, image) => {
        if(!selectedJob?.Status || selectedJob?.Status !== 'Completed') return null;
        const notValidatedPages = selectedJobFile?.ScannedPages.filter((x) => x.ValidatorFk === null);
        const currentPageIndex = notValidatedPages.findIndex((page) => page.ScannedPageId === image.ScannedPageId);
        const isLastPageInNotValidatedPages = currentPageIndex === notValidatedPages.length - 1;
        const isLastImage = isLastPageInNotValidatedPages || idx === selectedJobFile?.ScannedPages.length - 1;
        const docType = documentTypes.find(x => x.Id === image?.RecognizedDocumentTypeFk);
        const docTypeValidation = image?.ValidatorFk !== null && image?.ValidatedDocumentTypeFk !== null
            ? documentTypes.find(x => x.Id === image?.ValidatedDocumentTypeFk)?.Name
            : image?.ValidatedDocumentTypeFk == null && image?.ValidatorFk !== null
                ? "Unknown Document type"
                : null;
        const isLastJobFile = jobFiles.findIndex(jobFile => jobFile.Id === selectedJobFile.Id) === jobFiles.length - 1;
        const propbabilities = _.cloneDeep(image?.AiProbabilities);
        if (_.isArray(propbabilities)) {
            propbabilities.push({
                "DocumentTypeName": "Unknown Document type",
                "Probability": "Unknown"
            });
        }
        return (
            <Toolbar
                id={`toolbar-${image.ScannedPageId}-${!_.isNil(image.ValidatorFk) ? image.ValidatorFk : 'NotValidated'}`}
                className="toolbar"
            >
                <Typography variant="h6" component="div">
                    Page: {idx + 1}
                </Typography>
                <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                    {docType?.Name || 'Not Recognized'}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginLeft: 'auto'
                }}>
                    <FormControl sx={{
                        marginLeft: 2, width: '15rem',
                        borderRadius: '2px'
                    }}>
                        <Autocomplete
                            key="document-types-autocomplete"
                            size="small"
                            sx={{ width: '100%', marginRight: '1rem' }}
                            options={propbabilities.map(option => option.DocumentTypeName)}
                            value={docTypeValidation}
                            //  getOptionLabel={(option) => option.DocumentTypeName || "Unknown Document type"}
                            renderInput={(params) => (
                                <TextField {...params} key={params.id} label="Document Type Validation" variant="outlined" />
                            )}
                            groupBy={(option) => _.get(propbabilities.find(item => item.DocumentTypeName === option), 'Probability') || "Unknown"}
                            onChange={(event, docType) => handleStatusChange(event, docType, image)}
                        />
                    </FormControl>
                    <Box sx={{
                        ml: 5, width: '12rem', display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant="customRounded"
                            size="medium"
                            color="primary"
                            endIcon={<NavigateNextIcon />}
                            onClick={() => { handleNextPageToValidateButton(image, isLastImage) }}
                            disabled={isLastJobFile && isLastImage}
                        >
                            {isLastImage ? "Next file" : "Next to validate"}
                        </Button>
                    </Box>
                </Box>
            </Toolbar>
        );
    };


    //#endregion Functions
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            minHeight: '87dvh'
        }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', marginTop: '0.5rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>Job files:</Typography>
                        </Box>
                        {loadingJobFiles
                            ? <CircularProgress />
                            : selectedJob.Files?.length < 1 || _.isEmpty(selectedJob.Files) ?
                                <Box sx={{ color: 'primary.main' }}>Job files list empty</Box> :
                                <TableComponent
                                    columns={tableColumns}
                                    rows={getRows()}
                                    handleRowItemClick={handleRowItemClick}
                                    handleSelectedRow={handleSelectedRow}
                                    tableContainerSx={{ maxHeight: 'calc(100dvh - 15.4rem)' }} />
                        }
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <Box>{_.isNil(selectedJobFile?.Id)
                            ? <Typography>Preview:</Typography>
                            : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>Preview selected job file: {selectedJobFile?.Id}</Typography>
                            </Box>}
                        </Box>
                        {loadingFilePreview
                            ? <CircularProgress />
                            : _.isNil(selectedJobFile) || _.isEmpty(selectedJobFile) ?
                                <Box sx={{ color: 'primary.main' }}>Please select job file</Box> :
                                <PreviewImageList
                                    key="full-size-images"
                                    selectedDocumentPages={[selectedJobFile]}
                                    propertyMap={'CdnUrl'}
                                    keyMap={'PageNumber'}
                                    renderToolbar={renderToolbar}
                                    selectedJobFile={selectedJobFile}/>
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: _.isEmpty({}) ? 'flex-end' : 'space-between',
                alignItems: 'center'
            }}>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const filteredFiles = SelectorsDoc.getFiles(state);
    const selectedJob = Selectors.getSelectedJob(state);
    const selectedJobFile = Selectors.getSelectedJobFile(state);
    const documentTypes = SelectorsDoc.getDocumentTypes(state);
    const fileFilter = SelectorsDoc.getSelectedFileFilter(state);
    const selectedFile = SelectorsDoc.getSelectedFile(state);
    const sharedCurrentPages = SelectorsDoc.getSharedCurrentPages(state);
    const sharedIdentifyPages = SelectorsDoc.getSharedIdentifyPages(state);
    const jobFiles = Selectors.getJobFiles(state);
    return {
        sharedIdentifyPages,
        sharedCurrentPages,
        filteredFiles,
        fileFilter,
        selectedFile,
        selectedJob,
        selectedJobFile,
        documentTypes,
        jobFiles
    }
}

const mapDispatchToProps = {
    setSelectedJob: SliceJob.setSelectedJob,
    fetchJobFiles: Thunks.fetchJobFiles,
    fetchJobFilePreview: Thunks.fetchJobFilePreview,
    setSelectedJobFile: SliceJob.setSelectedJobFile,
    updateJobFilePage: Thunks.updateJobFilePage
}

export default connect(mapStateToProps, mapDispatchToProps)(JobResultValidation);
