import React from 'react';
import {connect} from 'react-redux';
import * as SliceActions from '../../../Api/slice';
import * as Selectors from '../../../Api/selectors';
import {
    Box, Button, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle,
    } from '@mui/material';
import { prettyPrintJson } from 'pretty-print-json';
import { Typography  } from '@mui/material';
import _ from 'lodash';

function AiProcessingResultPopup({
            processingFileName,
            aiProcessingResult,
            showAiProcessingResultDialog,
            setShowingAiProcessingResultDialog,
            setAiProcessingResult,
            error
        })
{

    const handleCloseDialog = () => {
        setAiProcessingResult(null);
        setShowingAiProcessingResultDialog(false);
    }

    return (
        <Box>
            <Dialog
                open={showAiProcessingResultDialog}
                onClose={() => handleCloseDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Result of AI Document processing for ${processingFileName}`}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: '20px'}}>
                        {aiProcessingResult && !_.isEmpty(aiProcessingResult)
                            ? <pre
                                dangerouslySetInnerHTML={{ __html: prettyPrintJson.toHtml(aiProcessingResult) }}></pre>
                            : error
                                ? <Typography>{error}</Typography>
                                : <CircularProgress color="inherit"/>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog()}>Ok</Button>
                        </DialogActions>
            </Dialog>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const aiProcessingResult = Selectors.getAiProcessingResult(state);
    const showAiProcessingResultDialog = Selectors.getShowingAiProcessingResultDialog(state);
    const error = Selectors.getError(state);
    return {
        aiProcessingResult,
        showAiProcessingResultDialog,
        error
    }
}

const mapDispatchToProps = {
    setShowingAiProcessingResultDialog: SliceActions.setShowingAiProcessingResultDialog,
    setAiProcessingResult: SliceActions.setAiProcessingResult
}

export default connect(mapStateToProps, mapDispatchToProps)(AiProcessingResultPopup);
