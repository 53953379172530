import { HTTP_METHODS } from './HttpMethods';
import _ from 'lodash';
let _baseApiOrigin = '';

export class FetchAjaxProxy {
    static SHARED_APIS = {

        get BASE_API_ORIGIN() {
            return _baseApiOrigin;
        },
        set BASE_API_ORIGIN(baseApi) {
            // TODO: we need to understand what is it for and get rid of comments 
            
            // if (_baseApiOrigin) {
            //     throw new Error('Overwrite BASE_API_ORIGIN is not allowed');
            // }
            try {
                const url = new URL(baseApi);
                _baseApiOrigin = url.origin;
            } catch (error) {
                throw new Error(`An error occured when trying to set ${baseApi} to BASE_API_ORIGIN.\r\n${error}`);
            }
        }
    };
    constructor(path) {
        this.apiAddress = path;
    }

    setBaseApiUrl = (baseApiUrl) => {
        this.apiAddress = `${baseApiUrl}/${this.apiAddress}`;
    }

    sendPatch(data, path) {
        return this.fetchRequest(path, HTTP_METHODS.PATCH, data);
    }

    sendPost(data, path) {
        return this.fetchRequest(path, HTTP_METHODS.POST, data);
    }

    sendPut(data, path) {
        return this.fetchRequest(path, HTTP_METHODS.PUT, data);
    }

    sendGet(path, parameters) {
        return this.fetchRequest(path, HTTP_METHODS.GET, parameters);
    }

    sendOptions(path) {
        return this.fetchRequest(path, HTTP_METHODS.OPTIONS);
    }

    sendDelete(path, data) {
        return this.fetchRequest(path, HTTP_METHODS.DELETE, data);
    }

    sendFilePostRequest = async (data, path) => {
        const urlParts = [this.apiAddress];
        if (!_.isNil(path) && path !== '') urlParts.push(path);
        const params = {
            method: HTTP_METHODS.POST,
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Cache-Control': 'no-cache, no-Store',
                'Time-Zone': new Date().getTimezoneOffset()
            },
            body: data
        };
        return (await fetch(urlParts.join('/'), params))
            .json();
    }

    fetchRequest = async (path, type, data) => {
        const urlParts = [this.apiAddress];
        if (!_.isNil(path) && path !== '') urlParts.push(path);
        const params = {
            method: type,
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Cache-Control': 'no-cache, no-Store',
                'Time-Zone': new Date().getTimezoneOffset(),
                'Content-Type': 'application/json'
            },
            ...(!_.isNil(data) && type !== HTTP_METHODS.GET) && {
                body: JSON.stringify(data)
            },
        };
        const response = await fetch(urlParts.join('/'), params);
        if (response.redirected) {
            window.location = `${FetchAjaxProxy.SHARED_APIS.BASE_API_ORIGIN}/account/login?returnUrl=${window.location}`;
            return {};
        }
        const res = await response.json();
        if (response.ok) return res;

        if (res.error && res.details)
            throw new Error(`${res.details}`);

        if (res.status && res.title)
            throw new Error(`${res.status} ${res.title}`);
        
        return {};
    }
}
