import React, { useEffect, useState } from 'react';
import * as Selectors from '../../Api/selectors';
import * as RootSelectors from '../../../../Shared/Infrastructure/Selectors/RootSelectors';
import * as Thunks from '../../Api/thunk';
import * as SliceActions from '../../Api/slice';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '../../../../Shared/Helpers';
import { connect } from 'react-redux';
import {
    Autocomplete, Box, Button, CircularProgress,
    Divider, FormControl, MenuItem, Select,
    TextField, Tooltip, Typography, Checkbox
} from '@mui/material';
import DocumentFilter from '../../../../Widgets/DocumentSelection/Ui/DocumentFilter';
import _ from 'lodash';
import TableComponent from '../../../../Shared/Components/TableComponent';
import DocumentImageList from '../../../../Widgets/DocumentSelection/Ui/ImageList';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { documentHubProxy } from '../../../../Common/CoreComponents/SignalRProxy';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import * as Actions from "../../Api/actions";

const rowsPerPageArray = [10, 15, 20, 50, 100];

const tableColumns = [
    {
        align: 'left',
        name: 'Identified',
        value: 'Identified',
    },
    {
        align: 'left',
        name: 'Created',
        value: 'Created',
    },
    {
        align: 'left',
        name: 'Actions',
        value: 'Actions',
    }
];

function DocumentSelection({
    documentTypes,
    documentPackageTypes,
    companies,
    fetchDocuments,
    sharedCurrentPages,
    sharedIdentifyPages,
    setEntityList,
    filteredDocuments,
    selectedDocumentType,
    clearDocumentFilter,
    documentFilter,
    fetchDocumentPreview,
    selectedDocument,
    setSelectedDocument,
    setSelectedDocumentFilter,
    setSelectedDocumentType,
    setIsFilePages,
    changeSelectedPages,
    fetchFilteredCompanies,
    initialSample,
    dataSamples,
    identifyDocumentPage,
    identifyDocument,
    setDocumentAsIdentified,
    setCurrentSample,
    currentSample,
    selectedPages,
    setSelectedPages
}) {
    const [deletedDocument, showDeleteModal] = useState(null);

    const openDeleteModal = (deletedDocument) => {
        showDeleteModal(deletedDocument);
    }
    const closeDeleteModal = () => {
        showDeleteModal(null);
    }
    const handleDeleteButtonClick = (deletedDocument) => {
        if (!deletedDocument) return;
        identifyDocument(deletedDocument);
        setSelectedDocument(null);
        showDeleteModal(null);
    };
    const transformedDocuments = () => {
        if (!_.isArray(filteredDocuments)) {
            return [];
        }

        return filteredDocuments
            .map((value) => {
                if (!documentFilter.IsIdentified && value.IdentifiedPages === value.PagesCount) {
                    return null;
                }

                const identified = value.IdentifiedPages !== value.PagesCount
                    ? (value.IdentifiedPages + '/' + value.PagesCount)
                    : <DoneIcon sx={{ color: 'primary.main' }} />;

                const deleteButton = value.IdentifiedPages !== value.PagesCount
                    ? <Button
                        variant="customRoundedTextDanger"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            openDeleteModal(value.Id)
                        }}
                    >
                        Delete
                    </Button>
                    : null;

                return {
                    Id: value.Id,
                    Created: `${new Date(value.Created).toLocaleDateString()} ${new Date(value.Created).toLocaleTimeString()}`,
                    Identified: identified,
                    Actions: deleteButton
                }
            })
            .filter(row => row !== null);
    };

    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [loadingDocumentPreview, setLoadingDocumentPreview] = useState(false);
    const [includeIdentifiedPage, setIncludeIdentifiedPages] = useState(false);
    
    
    const { unselectSample } = Actions.useCustomDispatch();
    const navigate = useNavigate();
    const query = useQuery();

    useEffect(() => {
        setCurrentSample({});
        setSelectedPages([]);
        const documentTypeFk = parseInt(query.get('DocumentTypeFk'), 10);
        if (!documentTypeFk) return;

        const documentId = query.get('DocumentId');
        const companyId = query.get('CompanyId');
        const createdFrom = query.get('CreatedFrom');
        const createdTo = query.get('CreatedTo');
        const isIdentified = query.get('IsIdentified');
        const itemsCount = query.get('ItemsPerPage');
        const documentPackageTypes = query.get('DocumentPackageTypes');
        const isPrintable = query.get('IsPrintable');
        const loanFk = query.get('LoanFk');
        const simpleId = query.get('SimpleId');
        const page = query.get('PageNumber');

        const filter = {
            ...(!_.isNil(companyId) ? { CompanyId: parseInt(companyId, 10) } : {}),
            ...(!_.isNil(documentTypeFk) ? { DocumentTypeFk: documentTypeFk } : {}),
            ...(!_.isNil(simpleId) ? { SimpleId: parseInt(simpleId, 10) } : {}),
            ...(createdFrom ? { CreatedFrom: createdFrom } : {}),
            ...(createdTo ? { CreatedTo: createdTo } : {}),
            ...(!_.isNil(isIdentified) ? { IsIdentified: (isIdentified.toLowerCase() === 'true') } : {}),
            ...(itemsCount ? { ItemsPerPage: parseInt(itemsCount, 10) } : {}),
            ...(page ? { PageNumber: parseInt(page, 10) } : {}),
            ...(loanFk ? { LoanFk: loanFk } : {}),
            ...(!_.isEmpty(documentPackageTypes) ? { DocumentPackageTypes: documentPackageTypes.split(',').map(Number) } : {}),
            ...(!_.isNil(isPrintable) ? { IsPrintable: (isPrintable.toLowerCase() === 'true') } : {}),
        };
        const docType = documentTypes.find(x => x.Id === documentTypeFk);
        setSelectedDocumentType(docType);
        setSelectedDocumentFilter(filter);
        setIsFilePages(false);
        
        if (!documentId) return;

        setLoadingDocumentPreview(true);
        fetchDocumentPreview({ documentFK: documentId }).then(() => {
            setLoadingDocumentPreview(false);
        });
    }, [])

    function isArrayAndNotEmpty(variable) {
        if (Array.isArray(variable)) {
            if (variable.length === 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    useEffect(() => {
        let resultUri = selectedDocument.DocumentFK ? `?DocumentId=${selectedDocument.DocumentFK}&` : `?`;
        let needPrefix = false;
        for (const [key, value] of Object.entries(documentFilter)) {
            if (documentFilter[key] !== null && documentFilter[key] !== '' && isArrayAndNotEmpty(documentFilter[key])) {
                if (needPrefix) resultUri += '&';
                resultUri += `${key}=${value}`;
                needPrefix = true;
            }
        }
        navigate(resultUri);
    }, [documentFilter, selectedDocument]);

    useEffect(() => {
        if (!documentFilter.DocumentTypeFk) {
            return;
        }

        setLoadingDocuments(true);
        fetchDocuments(documentFilter).then(() => {
            setLoadingDocuments(false);
        });
    }, [fetchDocuments, documentFilter]);

    useEffect(() => {
        fetchFilteredCompanies();
    }, [fetchFilteredCompanies]);

    const handleRemovingAllSelectedImages = () => {
        unselectSample();
    }

    const handleChangePage = (event, newPage) => {
        const newValidatePage = newPage < 1 ? 1 : newPage;
        setSelectedDocumentFilter({ PageNumber: newValidatePage });
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setSelectedDocumentFilter({ ItemsPerPage: newRowsPerPage, PageNumber: 1 });
    }

    const handleDocumentTypeChange = (event, docType) => {
        setSelectedDocumentType(docType);
        setSelectedDocument(null);
        const documentTypeFk = _.isNil(docType) ? null : docType.Id;
        setSelectedDocumentFilter({
            DocumentTypeFk: documentTypeFk,
            PageNumber: 1,
            IsIdentified: null,
            LoanFk: null,
            SimpleId: null
        });
    }

    const handleDateToFilterChange = (date) => {
        if (date === 'Invalid Date')
            return;

        setSelectedDocumentFilter({ CreatedTo: date });
    }

    const handleDateFromFilterChange = (date) => {
        if (date === 'Invalid Date')
            return;

        setSelectedDocumentFilter({ CreatedFrom: date });
    }

    const handlePackageTypeChange = (event, value) => {
        const valueId = value.map((x) => x.Id)
        setSelectedDocumentFilter({ DocumentPackageTypes: valueId || null });
        setSelectedDocument(null);
    }
    const handleFilterChange = (event, value) => {
        const selectedType = documentTypes.includes(value)
            ? 'DocumentTypeFk'
            : 'CompanyId';
        setSelectedDocumentFilter({ [selectedType]: value?.Id || null });
    }

    const handleRowItemClick = (document) => {
        setIsFilePages(false);
        const wasIdentifiedPageInDocument = dataSamples?.DocumentPages.some(page => 
            page.ScannedPageId === selectedDocument?.ScannedPages?.ScannedPageId);

        if (wasIdentifiedPageInDocument || document?.Id !== selectedDocument?.DocumentFK) {
            setSelectedPages([]);
            setLoadingDocumentPreview(true);
            fetchDocumentPreview({ documentFK: document?.Id ? document?.Id : selectedDocument?.DocumentFK }).then(() => {
                setLoadingDocumentPreview(false);
            });
        }
    }

    const handleSelectedRow = (row) => {
        return row.Id === selectedDocument.DocumentFK;
    }

    const isImageIdentifyOrSelectedByOther = (image) => {
        return image.IsIdentified 
        || (sharedCurrentPages || []).find(x => x === image.ScannedPageId)
        || (sharedIdentifyPages || []).find(x => x === image.ScannedPageId)
    }

    const handleImageSelection = (value) => {
        setIsFilePages(false);
        if (selectedPages.length === 0) {
            setSelectedPages([selectedDocument]);
            documentHubProxy.updateCurrentPages(
                selectedDocument.ScannedPages
                    .filter(image => !isImageIdentifyOrSelectedByOther(image))
                    .map(x => x.ScannedPageId), true);
            return;
        }
        changeSelectedPages(value);
        let isSelected = true;
        if (selectedPages.length !== 0)
            isSelected = selectedPages[0].ScannedPages
                .findIndex(x => x.ScannedPageId === value.ScannedPageId) === -1;

        documentHubProxy.updateCurrentPages([value.ScannedPageId], isSelected);
    }

    const handleContinueButton = (value) => {
        setIsFilePages(false);
        setCurrentSample({
            Id: 'new',
            DocumentPages: selectedPages,
            LabeledAreas: selectedPages[0]
                .ScannedPages
                .reduce((acc, { ScannedPageId }) => {
                    acc[ScannedPageId] = [];
                    return acc;
                }, {})
        });
        if (selectedPages.length <= 0) {
            setSelectedPages([selectedDocument]);
        }
        setSelectedPages([]);
    }

    const isImageSelected = (value) => {
        const selectedDocumentPage = selectedPages?.find(x => x.DocumentFK === selectedDocument.DocumentFK);
        const documentImages = selectedDocumentPage?.ScannedPages?.filter(x => x.IsIdentified === false) || [];
        return documentImages.some(x => x.ScannedPageId === value.ScannedPageId);
    }

    const selectedPackageTypes = documentPackageTypes.filter((x) => _.get(documentFilter, 'DocumentPackageTypes') && documentFilter.DocumentPackageTypes.includes(x.Id));
    const sortedCompanies = companies.reduce((unique, current) => {
        const existingItem = unique.find((x) => x.Name === current.Name);
        if (!existingItem) {
            unique.push(current);
        } else if (current.Id < existingItem.Id) {
            unique[unique.indexOf(existingItem)] = current;
        }
        return unique;
    }, []);
    const selectedCompany = _.get(documentFilter, 'CompanyId')
        ? _.find(sortedCompanies, { Id: _.get(documentFilter, 'CompanyId') })
        : null;
    const nonPrintableDocumentTypes = documentTypes.filter(type => !type.IsPrintable);
    const printableDocumentTypes = documentTypes.filter(type => type.IsPrintable);

    const handlePrintableCheckboxChange = (event) => {
        clearDocumentFilter();
        setSelectedDocumentFilter({ IsPrintable: event.target.checked });
        setSelectedDocumentType(null);
    };

    const handleSearchByLoanId = (event) => {
        const value = event.target.value.trim();
        const LoanFk = Number.isInteger(parseInt(value, 10)) ? null : value;
        const SimpleId = Number.isInteger(parseInt(value, 10)) ? value : null;

        setSelectedDocumentFilter({ SimpleId, LoanFk });
        setSelectedDocument(null);
    }

    const handleIdentificationDocumentsChange = (event) => {
        setSelectedDocumentFilter({ IsIdentified: event.target.checked });
        setSelectedDocument(null)
    };

    const handleIdentificationPagesChange = (event) => {
        setIncludeIdentifiedPages(event.target.checked);
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            minHeight: '87dvh'
        }}>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ width: '25%', display: 'flex', flexDirection: 'row' }}>
                        <Autocomplete
                            key="document-types-autocomplete"
                            size="small"
                            sx={{ width: '100%', marginRight: '1rem' }}
                            options={documentFilter.IsPrintable ? printableDocumentTypes : nonPrintableDocumentTypes}
                            value={_.isEmpty(selectedDocumentType) ? null : selectedDocumentType}
                            getOptionLabel={(option) => option.Name}
                            renderInput={(params) => (
                                <TextField {...params} key={params.id} label="Document Types" variant="outlined" />
                            )}
                            groupBy={(option) => option.Name}
                            onChange={handleDocumentTypeChange}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>Printable</Typography>
                            <Tooltip title='Printable document types'>
                                <Checkbox
                                    size='small'
                                    checked={documentFilter.IsPrintable === true}
                                    onChange={handlePrintableCheckboxChange}
                                />
                            </Tooltip>
                        </Box>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <Button
                                variant="customRounded"
                                size="medium"
                                onClick={handleRemovingAllSelectedImages}
                                startIcon={<CloseOutlinedIcon />}
                                disabled={selectedPages.length === 0}
                            >Unselect</Button>
                        </Box>
                    </Box>

                    <DocumentFilter
                        documentPackageTypesOptions={documentPackageTypes}
                        companiesOptions={sortedCompanies}
                        getOptionalLabel={(option) => option.Name || ""}
                        isOptionEqualToValue={(option, value) => option.Id === value.Id}
                        onToDateChange={handleDateToFilterChange}
                        onDateFromChange={handleDateFromFilterChange}
                        valuePackageType={selectedPackageTypes || null}
                        valueCompany={selectedCompany || null}
                        value={documentFilter}
                        documentPackageTypesLabel="Package Types"
                        onChange={handleFilterChange}
                        onChangePackageType={handlePackageTypeChange}
                        onChangeLoanFk={handleSearchByLoanId}
                        disabled={_.isEmpty(selectedDocumentType)}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>Documents:</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>Include Identified</Typography>
                                <Tooltip title='Identified documents'>
                                    <Checkbox
                                        size='small'
                                        checked={documentFilter.IsIdentified === true}
                                        onChange={handleIdentificationDocumentsChange}
                                    />
                                </Tooltip>
                            </Box>
                        </Box>
                        {loadingDocuments
                            ? <CircularProgress />
                            : filteredDocuments.length < 1
                                || _.isEmpty(filteredDocuments)
                                || _.isEmpty(selectedDocumentType)
                                ? <Box sx={{ color: 'primary.main' }}>Please select Document Type</Box>
                                : <TableComponent
                                    columns={tableColumns}
                                    rows={transformedDocuments()}
                                    handleRowItemClick={handleRowItemClick}
                                    handleSelectedRow={handleSelectedRow}
                                    tableContainerSx={{maxHeight: 'calc(100dvh - 14.6rem)'}}
                                />}
                        {deletedDocument && (
                            <Modal open={deletedDocument !== null}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Are you sure you want to delete this Document?
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between" mt={2}>
                                        <Button variant="customRoundedTextDanger" color="error"
                                            onClick={() => handleDeleteButtonClick(deletedDocument)}
                                            style={{ marginRight: '1rem' }}>
                                            Yes, delete
                                        </Button>
                                        <Button variant="customRoundedText" color="secondary"
                                            onClick={closeDeleteModal}>
                                            No
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>
                        )}
                    </Box>
                    <Divider sx={{ marginLeft: '1rem', marginRight: '1rem' }} orientation="vertical" flexItem />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                        {_.isNil(selectedDocument?.DocumentFK) && (_.isEmpty(selectedDocumentType) || selectedDocumentType == null)
                            ? <div>Preview:</div>
                            : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>Preview selected document: {selectedDocument.DocumentFK}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>Identified Pages</Typography>
                                    <Tooltip title='Include Identified pages'>
                                        <Checkbox
                                            size='small'
                                            checked={includeIdentifiedPage}
                                            onChange={handleIdentificationPagesChange}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        }
                        {loadingDocumentPreview
                            ? <CircularProgress />
                            : _.isNil(selectedDocument?.DocumentFK)
                                ? <Box sx={{ color: 'primary.main' }}>Please select Document</Box>
                                : <DocumentImageList
                                    images={selectedDocument?.ScannedPages}
                                    sharedCurrentPages={sharedCurrentPages}
                                    sharedIdentifyPages={sharedIdentifyPages}
                                    propertyMap={'CdnUrl'}
                                    handleImageSelection={handleImageSelection}
                                    isImageSelected={isImageSelected}
                                    keyMap={'ScannedPageId'}
                                    pageNumberMap={'PageNumber'}
                                    includeIdentifiedPage={includeIdentifiedPage}
                                    identifyDocumentPage={identifyDocumentPage}
                                    documentFK={selectedDocument?.DocumentFK}
                                />
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: _.isEmpty(initialSample) ? 'flex-end' : 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <Link to={_.isNil(selectedDocument.DocumentFK) ? '#' : '/documents/preview/new'}>
                            <Tooltip title="Preview selected images">
                                <Box>
                                    <Button
                                        variant="customRounded"
                                        size="medium"
                                        startIcon={<VisibilityOutlinedIcon />}
                                        disabled={_.isNil(selectedPages) 
                                            || selectedPages.length === 0 
                                            || selectedPages[0].ScannedPages.length === 0}
                                        onClick={() => {
                                            handleContinueButton(selectedDocument)
                                        }}
                                    >Continue</Button>
                                </Box>
                            </Tooltip>
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2rem' }}>
                        <Typography>Page:</Typography>
                        <TextField
                            sx={{ width: '4rem', marginLeft: '1rem' }}
                            variant="standard"
                            type="number"
                            value={documentFilter.PageNumber}
                            onChange={(e) => {
                                const newPage = parseInt(e.target.value, 10);
                                handleChangePage(e, _.isNaN(newPage) ? 0 : newPage)
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2rem' }}>
                        <Typography>Rows per page:</Typography>
                        <FormControl variant="standard" sx={{ width: '4rem', marginLeft: '1rem' }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={documentFilter.ItemsPerPage}
                                label="Age"
                                onChange={handleChangeRowsPerPage}
                            >
                                {rowsPerPageArray.map((value) => {
                                    return (
                                        <MenuItem key={`rows-per-page-${value}`} value={value}>{value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const documentTypes = Selectors.getDocumentTypes(state);
    const documentPackageTypes = RootSelectors.getDocumentPackageTypes(state);
    const companies = Selectors.getFilteredCompanies(state);
    const filteredDocuments = Selectors.getDocuments(state);
    const selectedDocumentType = Selectors.getSelectedDocumentType(state);
    const documentFilter = Selectors.getSelectedDocumentFilter(state);
    const selectedDocument = Selectors.getSelectedDocument(state);
    const selectedPages = Selectors.getSelectedPages(state);
    const sharedCurrentPages = Selectors.getSharedCurrentPages(state);
    const sharedIdentifyPages = Selectors.getSharedIdentifyPages(state);
    const currentSample = Selectors.getCurrentSample(state);
    return {
        currentSample,
        sharedIdentifyPages,
        sharedCurrentPages,
        documentTypes,
        documentPackageTypes,
        companies,
        filteredDocuments,
        selectedDocumentType,
        documentFilter,
        selectedDocument,
        selectedPages
    }
}

const mapDispatchToProps = {
    fetchDocuments: Thunks.fetchDocuments,
    fetchFilteredCompanies: Thunks.fetchFilteredCompanies,
    fetchDocumentPreview: Thunks.fetchDocumentPreview,
    setSelectedDocumentFilter: SliceActions.setSelectedDocumentFilter,
    setSelectedDocumentType: SliceActions.setSelectedDocumentType,
    clearDocumentFilter: SliceActions.clearDocumentFilter,
    setSelectedPages: SliceActions.setSelectedPages,
    changeSelectedPages: SliceActions.changeSelectedPages,
    setSelectedDocument: SliceActions.setSelectedDocument,
    setEntityList: SliceActions.setEntityList,
    setIsFilePages: SliceActions.setIsFilePages,
    identifyDocumentPage: Thunks.identifyDocumentPage,
    identifyDocument: Thunks.identifyDocument,
    setDocumentAsIdentified: SliceActions.setDocumentAsIdentified,
    setCurrentSample: SliceActions.setCurrentSample
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSelection);
