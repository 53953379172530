import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Autocomplete, Box, CircularProgress, FormControl, MenuItem, Select,
TextField, Typography, Button, Tooltip, Checkbox, IconButton } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import * as Thunks from '../../Api/thunk';
import * as SliceActions from '../../Api/slice';
import * as Selectors from '../../Api/selectors';
import TableComponent from '../../../../Shared/Components/TableComponent';
import _ from 'lodash';
import * as Actions from '../../Api/actions';

const rowsPerPageArray = [10, 15, 20, 50, 100];

export const tabs = ['Samples', 'Selected'];
export const debounceFunction = _.debounce((func) => {
    func();
}, 500);

function DocumentTypeSamples(props) {
    const {
        fetchDataSamplesWithFilter,
        dataSamples,
        documentTypes,
        deleteDataSample,
        setSelectedDocumentType,
        addSelectedDataSample,
        selectedDataSamples,
        selectedSampleId,
        setIsFilePages,
        sampleFilter,
        setSampleFilter,
        changeFilter,
        dataSamplesCountByFilter,
        fetchDataSamplesView,
        setCurrentSample,
        getDataSamplePreview,
        currentSample
    } = props;

    const tableColumns = [
        {
            name: '',
            value: 'Select',
            align: 'left',
        },
        {
            name: 'Id',
            value: 'Id',
        },
        {
            name: 'Sample name',
            value: 'Name',
        },
        {
            align: 'left',
            name: 'Document Type',
            value: 'DocumentTypeFK',
        },
        {
            align: 'left',
            name: 'Status',
            value: 'Status',
        },
        {
            align: 'left',
            name: 'Is Submitted',
            value: 'IsSubmitted',
        },
        {
            align: 'left',
            name: 'Confidence',
            value: 'ConfidenceLevel',
        },
        {
            align: 'left',
            name: 'Created',
            value: 'Created',
        },
        {
            align: 'left',
            name: 'By',
            value: 'Creator',
        },
        {
            align: 'left',
            name: 'Actions',
            value: 'Actions',
        }
    ];

    const navigate = useNavigate();
    let { docTypeFk = null } = useParams();
    const [filter, setFilter] = useState(sampleFilter);
    const [loadingDataSamples, setLoadingDataSamples] = useState(false);
    const [pageNumberInput, setPageNumberInput] = useState(sampleFilter?.PageNumber);
    const [isNavigated, setNavigated] = useState(false);
    
    useEffect(() => {
        if (!isNavigated) {
            setNavigated(true);
            return;
        }
        setLoadingDataSamples(true);
        fetchDataSamplesWithFilter(sampleFilter).then(() => {
            setLoadingDataSamples(false);
        });
    },  [fetchDataSamplesWithFilter, sampleFilter, isNavigated]);

    useEffect(() => {
        if (!isNavigated) {
            setNavigated(true);
            return;
        }
            debounceFunction(() => {
                setSampleFilter({ Id: parseInt(filter?.Id), Name: filter?.Name, PageNumber: 1, IncludeLabels: filter?.IncludeLabels });
                setPageNumberInput(1);
            });
    }, [filter, setSampleFilter]);
    
    const handleChangeFilter = (e, propertyName) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const newValue = propertyName === 'IncludeLabels' ? 
            (filter[propertyName] === null ? true : 
            (filter[propertyName] === true ? false : null)) 
            : value;
        
        const newFilterValue = { ...filter, [propertyName]: newValue };
        setFilter(newFilterValue);
    };
    //#region Functions 
    const { unselectSample } = Actions.useCustomDispatch();

    const handleRowItemClick = (row) => {
        selectDataSampleById(row.Id);
    }
    const handleSelectedRow = (row) => {
        return row.Id === selectedSampleId;
    }

    const selectDataSampleById = (sampleId) => {
        const dataSample = dataSamples.find(dataSample => dataSample.Id === sampleId) || selectedDataSamples.find(dataSample => dataSample.Id === sampleId);
        const selectedDocType = dataSample && documentTypes.find((type) => type.Id === dataSample.DocumentTypeFK);
        setSelectedDocumentType(selectedDocType);
    }

    const handleCheckedRow = (row) => {
        return selectedDataSamples.some(x => x.Id == row.Id);
    }
    const handleSelectRow = (row) => {
        addSelectedDataSample(row);
    }
    const handlePreviewRow = (row) => {
        setIsFilePages(false);
        selectDataSampleById(row.Id);
        navigate(`/documents/preview/${row.Id}`);
    }

    const handleDeleteRow = (row) => {
        deleteDataSample(row.Id);
        unselectSample();
    }

    const getDocumentSampleRows = (dataSamples, documentTypes) => {
        if (_.isEmpty(dataSamples) || !Array.isArray(dataSamples)) {
            return [];
        }
    
        const selectedDocumentTypeName = dataSamples && Array.isArray(dataSamples)
            ? dataSamples.map((x) => {
                const sampleType = documentTypes.find(type => type.Id === x.DocumentTypeFK);
                return sampleType?.Name;
            })
                .find(name => name !== null)
            : null;

        const formattedRows = dataSamples.map((x) => {
            const createdDate = new Date(x.Created);
            const modifiedDate = _.isNil(x.Modified) ? null : new Date(x.Modified);
            const formattedCreatedDate = `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`;
            const formattedModifiedDate = _.isNil(modifiedDate) ? null : `${modifiedDate.toLocaleDateString()} ${modifiedDate.toLocaleTimeString}`;
    
            return {
                Id: x.Id,
                Select: (
                    <Tooltip title='Select Sample'>
                        <Checkbox
                            disabled={x.Status !== "Validated"}
                            size='small'
                            checked={handleCheckedRow(x)}
                            onClick={() => handleSelectRow(x)}
                        />
                    </Tooltip>
                ),
                Name: x.Name,
                Status: x.Status,
                DocumentTypeFK: selectedDocumentTypeName,
                IsSubmitted: x.IsSubmitted 
                ? <CheckOutlinedIcon sx={{ color: 'primary.main' }} />
                : <CloseOutlinedIcon sx={{ color: 'primary.main' }} />,
                Modified: formattedModifiedDate,
                Created: formattedCreatedDate,
                Creator: x.Creator,
                ConfidenceLevel: `${x.ConfidenceLevel}%`,
                Actions:
                    (
                        <Box>
                            <Button
                                variant="customRoundedText"
                                size="small"
                                startIcon={<VisibilityOutlinedIcon />}
                                onClick={() => {
                                    handlePreviewRow(x);
                                }}
                            >
                                Preview
                            </Button>
                            <Button
                                variant="customRoundedTextDanger"
                                size="small"
                                startIcon={<DeleteIcon />}
                                disabled={x.IsSubmitted}
                                onClick={() => {
                                    handleDeleteRow(x);
                                }}
                            >
                                Delete
                            </Button>
                        </Box>
                    )
            };
        });
        
        return formattedRows;
    }
    
    const handleChangePage = (event, newPage) => {
        const newValidatePage = newPage < 1 ? 1 : newPage;
        setPageNumberInput(newValidatePage);
        changeFilter({ ...sampleFilter, PageNumber: newValidatePage });
    }
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPageNumberInput(1);
        changeFilter({ ...sampleFilter, ItemsPerPage: newRowsPerPage, PageNumber: 1 });
    } 

    const handleFilterStatusChange = (event, value) => {
        setSampleFilter({ Status: value || null, PageNumber: 1 });
        setPageNumberInput(1);
    }
    //#endregion Functions

    const docType = documentTypes.find(x => x.Id == docTypeFk);
    const statusOptions = !_.isEmpty(dataSamples)
        ? ['Identified', 'Validated', 'Rejected']
        : [];

    const calculateRowRange = (pageNumber, itemsPerPage, dataSamplesCountByFilter) => {
        const startIdx = (pageNumber - 1) * itemsPerPage + 1;
        const endIdx = Math.min(pageNumber * itemsPerPage, dataSamplesCountByFilter);
        return !_.isEmpty(dataSamples) ? `${startIdx}-${endIdx} of ${dataSamplesCountByFilter}` : null;
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row',  justifyContent: 'space-between', marginTop: '0.3em', height: '3.5em'}}>
                    <Box style={{ display: 'inline-flex', gap: 20 }}>
                        <Link to={'/documents/samples'}>Document Types </Link>
                        {'>'}
                        <Link>{_.get(docType, 'Name')}</Link>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', whiteSpace: 'nowrap', overflowX: 'auto', marginBottom: '0.3em'   }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>Include labels</Typography>
                            <Tooltip title='Samples with labels'>
                                <Checkbox
                                    size='small'
                                    checked={filter?.IncludeLabels === true}
                                    indeterminate={filter?.IncludeLabels === null} 
                                    onChange={(e) => handleChangeFilter(e, 'IncludeLabels')}
                                />
                            </Tooltip>
                        </Box>
                        <TextField
                            id="outlined-basic"
                            label="Id"
                            variant="outlined"
                            size="small"
                            onChange={(e) => handleChangeFilter(e, 'Id')}
                            value={filter?.Id || ''}
                            style={{ marginRight: '1rem', maxWidth: '5rem' }}
                        />
                             <TextField
                                size="small"
                                sx={{ width: '100%' }}
                                variant="outlined"
                                label="Sample name"
                                value={_.get(filter, 'Name') || ""}
                                onChange={(e)=>handleChangeFilter(e, 'Name')}
                                style={{ marginRight: '1rem', width: '15rem' }}
                            />
                        <Autocomplete
                            key="document-package-types-autocomplete"
                            size="small"
                            freeSolo
                            options={statusOptions}
                            value={sampleFilter?.Status}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option || ""}
                            isOptionEqualToValue={(option, value) => option.Id === value.Id}
                            renderInput={(params) => (
                                <TextField {...params} label={"Status"} variant="outlined" />
                            )}
                            onChange={handleFilterStatusChange}
                            style={{ width: '10rem' }}
                        />
                    </Box>

                </Box>
            </Box>
            {loadingDataSamples
                ? <CircularProgress />
                : <Box>
                    <TableComponent
                        columns={tableColumns}
                        rows={getDocumentSampleRows(dataSamples, documentTypes)}
                        handleRowItemClick={handleRowItemClick}
                        handleSelectedRow={handleSelectedRow}
                        selectedSampleId={selectedSampleId}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2rem' }}>
                            <Typography>Rows per page:</Typography>
                            <FormControl variant="standard" sx={{ width: '4rem', marginLeft: '1rem' }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={sampleFilter?.ItemsPerPage || sampleFilter?.ItemsPerPage}
                                    label="Age"
                                    onChange={handleChangeRowsPerPage}
                                >
                                    {rowsPerPageArray.map((value) => {
                                        return (
                                            <MenuItem key={`rows-per-page-${value}`} value={value}>{value}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2rem' }}>
                            <Typography sx={{ marginRight: '1rem' }}>Page:</Typography>
                            <>
                                <TextField
                                    variant="standard"
                                    value={pageNumberInput || sampleFilter?.PageNumber || 1}
                                    onChange={(e) => {
                                        const newPage = parseInt(e.target.value, 10);
                                        setPageNumberInput(newPage);
                                    }}
                                    onBlur={(e) => {
                                        handleChangePage(e, pageNumberInput);
                                    }}
                                    style={{ width: '7rem' }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <IconButton onClick={() => handleChangePage(null, sampleFilter.PageNumber - 1)}
                                                    disabled={sampleFilter.PageNumber === 1 || sampleFilter.ItemsPerPage > dataSamplesCountByFilter}>
                                                    <NavigateBeforeIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleChangePage(null, sampleFilter.PageNumber + 1)}
                                                    disabled={sampleFilter.PageNumber > Math.ceil(dataSamplesCountByFilter / sampleFilter.ItemsPerPage) - 1 || sampleFilter.ItemsPerPage > dataSamplesCountByFilter}>
                                                    <NavigateNextIcon />
                                                </IconButton>
                                            </>
                                        ),
                                    }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2rem' }}>
                                    <Typography sx={{ marginRight: '1rem' }}>
                                        {calculateRowRange(
                                            sampleFilter?.PageNumber,
                                            sampleFilter?.ItemsPerPage,
                                            dataSamplesCountByFilter
                                        )}
                                    </Typography>
                                </Box>

                            </>
                        </Box>
                    </Box>
                </Box>}
        </Box>
    );
}

const mapStateToProps = (state) => {
    const dataSamples = Selectors.getDataSamples(state); 
    const dataSamplesCountByFilter = Selectors.getDataSamplesCountByFilter(state);
    const documentTypes = Selectors.getDocumentTypes(state);
    const selectedDocumentPages = Selectors.getSelectedPages(state);
    const samplesPagination = Selectors.getSamplesPagination(state);
    const selectedDataSamples = Selectors.getSelectedDataSamples(state);
    const selectedSampleId = _.get(Selectors.getCurrentSample(state), 'Id') || -1;
    const sampleFilter = Selectors.getDocSampleFilter(state);
    const currentSample = Selectors.getCurrentSample(state);
    return {
        dataSamples,
        documentTypes,
        selectedDocumentPages,
        samplesPagination,
        selectedDataSamples,
        selectedSampleId,
        dataSamplesCountByFilter,
        sampleFilter,
        currentSample
    };
};

const mapDispatchToProps = {
    fetchDataSamplesWithFilter: Thunks.fetchDataSamplesWithFilter,
    fetchDataSamplesView: Thunks.fetchDataSamplesView,
    deleteDataSample: Thunks.deleteDataSample,
    saveDataSet: Thunks.saveDataSet,
    setSamplesPagination: SliceActions.setSamplesPagination,
    setSelectedDocumentType: SliceActions.setSelectedDocumentType,
    setSelectedDocumentFilter: SliceActions.setSelectedDocumentFilter,
    addSelectedDataSample: SliceActions.addSelectedDataSample,
    setSelectedDataSamples: SliceActions.setSelectedDataSamples,
    setCurrentSample: SliceActions.setCurrentSample,
    deselectDataSamplesByDocTypeFk: SliceActions.deselectDataSamplesByDocTypeFk,
    selectAllDataSamples: Thunks.selectAllDataSamples,
    getDataSamplePreview: Thunks.getDataSamplePreview,
    setIsFilePages: SliceActions.setIsFilePages,
    setSampleFilter: SliceActions.setSampleFilter
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeSamples);
