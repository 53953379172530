import {
    changeSharedCurrentPages,
    changeFilteredDocs,
    setSharedCurrentPages,
    setDocuments,
    setPageAsIdentified,
    setDocumentAsIdentified,
    setSelectedDocument,
    setSelectedDocumentFilter,
    setSelectedPages,
    setSelectedDocumentType,
    setSelectedFileDocumentType,
    setError,
    setCurrentSample,
} from "./slice";
import {
    setJob
} from "../Ui/Jobs/JobsSlice"
import {useDispatch} from "react-redux";
import { documentHubProxy, aiClassifierHubProxy } from '../../../Common/CoreComponents/SignalRProxy';

export const useCustomDispatch = () => {
    const dispatch = useDispatch();

    const unselectSample = () => {
        dispatch(setSelectedDocumentType({}));
        dispatch(setSelectedFileDocumentType({}));
        dispatch(setSelectedPages([]));
        dispatch(setDocuments([]));
        dispatch(setSelectedDocument({
            DocumentFK: null,
            ScannedPages: [],
        }));
        dispatch(setSelectedDocumentFilter({DocumentTypeFk: null}));
        dispatch(setCurrentSample({}));
    }

    return {
        unselectSample,
    }
}

export const useDocumentHub = () => {
    const dispatch = useDispatch();

    const connectToDocumentHub = () => {
        documentHubProxy.onReceiveCurrentPages((pages, isSelected) => {
            dispatch(changeSharedCurrentPages({pages, isSelected}));
        });

        documentHubProxy.onReceiveInitialState((initialData) => {
            dispatch(setSharedCurrentPages(initialData.sharedCurrentPages));
        });

        documentHubProxy.onReceiveDisconnectedUserState((disconnectedUserData) => {
            dispatch(changeSharedCurrentPages({pages: 
                disconnectedUserData.sharedCurrentPages, isSelected: false}));
        });

        documentHubProxy.onReceiveIdentifyDocumentPages((documentId, scannedPages, isIdentify) => {
            dispatch(changeFilteredDocs({documentId, scannedPages, isIdentify}));
        });

        documentHubProxy.onReceiveDeletingPage((pageId, isDocument) => {
            dispatch(isDocument ? setDocumentAsIdentified(pageId) : setPageAsIdentified(pageId));
        });

        documentHubProxy.onReceiveJobUpdate((job) => {
            dispatch(setJob(job));
        });

        documentHubProxy.onDisconnect((error) => {
            dispatch(setError(error ? error :'You have been disconnected from the document/files hub!'));
        });

        documentHubProxy.connect(setError);
    }

    return {
        connectToDocumentHub,
    }
};