import { FetchAjaxProxy } from './FetchAjaxProxy';

export const API_PROXY_PREFIXES = {
    SHARED: '/shared/api/',
    ADMIN: '/admin/api/',
    USER: '/user/api/',
    CLIENT: '/client/api/',
    ZIPCITY: '/zipcity/api/',
    DOCREVIEWER: '/docreviewer/api',
    AI: '/api'
};

export class ProxyFactory {
    static CreateSharedProxy(subPath) {
        return new FetchAjaxProxy(API_PROXY_PREFIXES.SHARED + subPath);
    }

    static CreateAdminProxy(subPath) {
        return new FetchAjaxProxy(API_PROXY_PREFIXES.ADMIN + subPath);
    }

    static CreateUserProxy(subPath) {
        return new FetchAjaxProxy(API_PROXY_PREFIXES.USER + subPath);
    }

    static CreateZipCityProxy(subPath) {
        return new FetchAjaxProxy(API_PROXY_PREFIXES.ZIPCITY + subPath);
    }

    static CreateAIProxy(subPath) {
        const urlParts = [API_PROXY_PREFIXES.AI];
        if (subPath) urlParts.push(subPath);
        return new FetchAjaxProxy(urlParts.join('/'));
    }
}
