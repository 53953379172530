import React, { useState } from 'react';
import JobRunner from './JobRunner';
import { JobsList } from './JobsList';

export function JobsComponent(props) {
    const { } = props;

    const [showCreate, setShowCreate] = useState(false);

    if (showCreate) {
        return <JobRunner {...{ setShowCreate }} />
    }
    return (<JobsList setShowCreate={setShowCreate} />)
}
