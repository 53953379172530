import React from 'react';
import {Outlet} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import _ from "lodash";
import * as Selectors from '../Api/selectors';
import * as SliceActions from '../Api/slice';

function ErrorBoundary({error, setError}) {
    return (
        <Box>
            <Dialog
                open={!_.isNil(error)}
                onClose={() => window.location.reload()}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Something went wrong"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {typeof error === 'string' ? error : String(error)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.location.reload()}>Reload</Button>
                </DialogActions>
            </Dialog>

            <Outlet/>
        </Box>); 
}

const mapStateToProps = (state) => ({
    error: Selectors.getError(state)
})

const mapDispatchToProps = {
    setError: SliceActions.setError
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
