import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Popover, FormControl, Autocomplete } from '@mui/material';
import Fab from '@mui/material/Fab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TableComponent from 'Shared/Components/TableComponent';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@mui/styles";
import _ from 'lodash';

const useStyles = makeStyles({
    paper: {
        '& .MuiAutocomplete-option': {
            overflow: 'initial',
        }
    }
});

export function EntityList(props) {
    const { entityTypes, entityList,
        currentSample,
        sampleId,
        isNewSample,
        popoverId,
        debounceFunction,
        setEntityList,
        deleteEntity,
        updateEntity,
        setCurrentSample,
        addNewEntity,
        isPopoverOpen,
        anchorEl, 
        setAnchorEl,
        showEntityList,
    } = props;

    const [addedEntityType, setAddedEntityType] = useState('');
    const [addedEntityText, setAddedEntityText] = useState('');
    const [isTableExpanded, setIsTableExpanded] = useState(false);
    const classes = useStyles();

    const columns = [
        {
            name: 'Text',
            value: 'Text',
            align: 'left',
        },
        {
            name: 'Type',
            value: 'Type',
            align: 'left',
        },
        {
            name: 'Actions',
            value: 'Actions',
            align: 'left'
        }
    ];

    const calculateRows = () => {
        const rows = (currentSample?.EntityList || entityList).map((row, idx) => {
            return {
                Text: (
                    <TextField
                        variant="standard"
                        defaultValue={row.Text}
                        onChange={(e) => handleEditEntityText(row, e, idx)}
                        title={row.Text}
                        sx={{
                            minWidth: '18rem',
                            width: '100%',
                            "& input": {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        }}
                    />
                ),
                Type: (
                    <Autocomplete
                        disableClearable
                        defaultValue={row.Type}
                        classes={{ paper: classes.paper }}
                        sx={{ width: '14rem' }}
                        options={entityTypes.map(x => x.Name)}
                        renderInput={(params) => (
                            <TextField {...params} key={params.id} variant="standard" />
                        )}
                        onChange={(e) => handleEditEntityType(row, e, idx)}
                    />
                ),
                Actions: (
                    <Button
                        variant="customRoundedTextDanger"
                        onClick={() => {
                            handleDeleteEntityListItemClick(idx);
                        }}
                        sx={{ ml: 0 }}
                    >
                        <DeleteIcon />
                    </Button>
                )
            };
        });

        return rows;
    };

    //#region Functions
    const handlePopoverClick = (event) => {
        setAddedEntityText('');
        setAddedEntityType('');
        setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const hideTable = () => {
        setIsTableExpanded(!isTableExpanded);
    };

    const handleDeleteEntityListItemClick = (idx) => {
        if (isNewSample) {
            const entityList = Array.isArray(currentSample?.EntityList) ? currentSample.EntityList : [];
            const newEntityList = _.cloneDeep(entityList);
            newEntityList.splice(idx, 1);
            setEntityList(newEntityList);
            setCurrentSample({...currentSample, EntityList: newEntityList})
            return;
        }
        debounceFunction(() => {
            deleteEntity({ sampleId, idx});
        })
    }

    const handleEditEntityText = (item, e, idx) => {
        const addedEntityType = { Text: e.target.value, Type: item.Type };
        if (isNewSample) {
            const entityList = Array.isArray(currentSample?.EntityList) ? currentSample.EntityList : [];
            if (entityList
                .some(x => x.Text === addedEntityType.Text && x.Type === addedEntityType.Type))
                return;
            const newEntityList = _.cloneDeep(entityList);
            newEntityList[idx] = addedEntityType;
            setCurrentSample({...currentSample, EntityList: newEntityList})
            setEntityList(newEntityList);
            return;
        }
        if (!currentSample?.EntityList
            .some(x => x.Text === e.target.value && x.Type === item.Type))
            debounceFunction(() => {
                updateEntity({ sampleId, updatedEntity: addedEntityType, idx});
            })
    }

    const handleEditEntityType = (item, e, idx) => {
        const addedEntityType = { Text: item.Text, Type: e.target.textContent };
        if (isNewSample) {
            const entityList = Array.isArray(currentSample?.EntityList) ? currentSample.EntityList : [];
            if (entityList
                .some(x => x.Text === addedEntityType.Text && x.Type === addedEntityType.Type))
                return;
            const newEntityList = _.cloneDeep(entityList);
            newEntityList[idx] = addedEntityType;
            setCurrentSample({...currentSample, EntityList: newEntityList})
            setEntityList(newEntityList);
            return;
        }
        if (!currentSample?.EntityList
            .some(x => x.Text === e.target.value && x.Type === item.Type))
            debounceFunction(() => {
                updateEntity({ sampleId, updatedEntity: addedEntityType, idx});
            })
    }

    const handleSubmitAddedNewEntity = (e) => {
        e.preventDefault();
        if (!addedEntityType)
            return;
        const addedEntity = { Text: addedEntityText, Type: addedEntityType };
        if (isNewSample) {
            const entityList = Array.isArray(currentSample?.EntityList) ? currentSample.EntityList : [];

            if (entityList
                .some(x => x.Text === addedEntityText && x.Type === addedEntityType))
                return;

            const newEntityList = _.cloneDeep(entityList);
            newEntityList.push(addedEntity);
            setCurrentSample({...currentSample, EntityList: newEntityList})
            setEntityList(newEntityList);
            handlePopoverClose();
            return;
        }
        if (!currentSample?.EntityList
            .some(x => x.Text === addedEntityText && x.Type === addedEntityType))
            debounceFunction(() => {
                addNewEntity({ sampleId, addedEntity });
            })
        handlePopoverClose();
    }
    //#endregion Functions

    return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', maxHeight: 'calc(100% - 358px)' }}>
        {showEntityList &&
            <Box sx={{ maxHeight: 'calc(100% - 132px)', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1em' }}>
                    <Typography variant="h7">Entity List for recognizer</Typography>
                    <Fab color="primary" size="small" sx={{ ml: '1em' }} aria-label="add" onClick={hideTable}>
                        {isTableExpanded
                            ? <ExpandLessIcon />
                            : <ExpandMoreIcon />}
                    </Fab>
                    {isTableExpanded && (
                        <>
                            <Button
                                aria-describedby={popoverId}
                                variant="customRounded"
                                onClick={handlePopoverClick}
                                startIcon={<AddIcon />}
                                sx={{ ml: 'auto' }}
                            >
                                Add Entity
                            </Button>
                            <Popover
                                id={popoverId}
                                open={isPopoverOpen}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                slotProps={{
                                    paper: {
                                        component: 'form',
                                        onSubmit: (e) => handleSubmitAddedNewEntity(e),
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', p: '1rem 1.25rem 0.5rem' }}>
                                    <Typography variant="h6" sx={{ mb: '0.5rem' }}>Add Entity</Typography>
                                    <TextField
                                        value={addedEntityText}
                                        autoFocus
                                        required
                                        name="entityText"
                                        variant="standard"
                                        label="Entity Text"
                                        sx={{ mb: '1em' }}
                                        onChange={(e) => setAddedEntityText(e.target.value)}
                                    />
                                    <FormControl required variant="standard" >
                                        <Autocomplete
                                            disableClearable
                                            value={addedEntityType}
                                            defaultValue={""}
                                            options={entityTypes.map(x => x.Name)}
                                            renderInput={(params) => (
                                                <TextField label={'Entity Type'} {...params} key={params.id} variant="standard" />
                                            )}
                                            onChange={(e) => setAddedEntityType(e.target.textContent)}
                                        />
                                        <Button type="submit" sx={{ mt: '0.5rem' }}>Add</Button>
                                    </FormControl>
                                </Box>
                            </Popover>
                        </>
                    )}
                </Box>
                {isTableExpanded && (
                    <TableComponent
                        columns={columns}
                        rows={calculateRows()}
                        enableTablePagination={true}
                        handleSelectedRow={() => { }}
                        handleRowItemClick={() => { }}
                        tableContainerSx={{ maxHeight: 'calc(100dvh - 29.4rem)' }}
                    />
                )}
            </Box>
        }
    </Box>);
}