import React from 'react';
import {
    Box, Typography, Button,
    Checkbox
    ,
} from '@mui/material';
import TableComponent from 'Shared/Components/TableComponent';
import { getLabeledAreaId } from 'Common/CoreComponents/constants';


export function LabedledAreaList(props) {
    const { labelOptions, isSampleSubmitted,
        showLabeledAreas, showLabeledAreasClickHandler,
        pageLabeledAreasMap,
        removeLabeledAreaHandler
    } = props;
    if (!labelOptions || !labelOptions.length) return null;
    const labeledAreasRows = Object.entries(pageLabeledAreasMap)
        .flatMap(([pageId, pageLabels], index) => {
            return pageLabels
                .map((r) => {
                    const labeledAreaId = getLabeledAreaId(r);
                    return {
                        ...r,
                        label: `${r.label} [${r.index}] p.${index}`,
                        actions:
                            <Box key={`label-row-key-${pageId}-${labeledAreaId}`}>
                                <Button
                                    variant="customRoundedTextDanger"
                                    onClick={() => removeLabeledAreaHandler({ pageId, labeledAreaId })}
                                >
                                    Delete
                                </Button>
                            </Box>,
                        color:
                            <Box
                                key={`label-row-color-key-${pageId}-${labeledAreaId}`}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: r.color,
                                    display: 'inline-block',
                                    marginRight: '8px'
                                }}
                            />
                    }
                });
        });
    const labeledAreasColumns = [
        {
            name: 'Label',
            value: 'label',
            align: 'left'
        },
        {
            name: 'Color',
            value: 'color',
            align: 'left'
        },
        {
            name: 'Actions',
            value: 'actions',
            align: 'left'
        }
    ];;
    return (<Box sx={{ display: 'flex', justifyContent: 'space-between', maxHeight: 'calc(100% - 358px)' }}>
        <Box sx={{ maxHeight: 'calc(100% - 132px)', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1em' }}>
                <Typography variant="h7">Show Labeled Areas</Typography>
                <Checkbox checked={showLabeledAreas} onChange={showLabeledAreasClickHandler} />
            </Box>
            {showLabeledAreas && (
                <TableComponent
                    columns={labeledAreasColumns}
                    rows={labeledAreasRows}
                    enableTablePagination={true}
                    handleSelectedRow={() => { }}
                    handleRowItemClick={() => { }}
                    tableContainerSx={{ maxHeight: 'calc(100dvh - 29.4rem)' }}
                />
            )}
        </Box>
    </Box>);
}