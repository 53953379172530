import React from 'react';
import {
    Box, Button, Typography,
    Dialog, DialogActions, DialogContent, DialogTitle,
    } from '@mui/material';
import {
    ERROR_COLOR,
} from "../Shared/Styles/Colors";

function DeleteItemDialog(
    {openDialog, setOpenDialog, itemName, deleteAction}) {

    const handleDeleteButtonClick = () => {
        deleteAction();
        setOpenDialog(false);
    }

    return (
        <Box sx={{
            display: 'block'
        }}>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle sx={{color: ERROR_COLOR}}>
                    {`Warning`}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Typography sx={{mb:'1em'}}>
                            Are you sure you want to delete <span style={{fontWeight:'bold'}}>{itemName}</span>?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='customRoundedText' onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button variant='customRoundedTextDanger' onClick={() => handleDeleteButtonClick()}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DeleteItemDialog;
