import React from 'react';
import {connect} from 'react-redux';
import * as Thunks from '../../../Api/thunk';
import * as Selectors from '../../../Api/selectors';
import AiProcessorsScreen from '../Shared/AiProcessorsScreen';
import FitbitTwoToneIcon from '@mui/icons-material/FitbitTwoTone';
import _ from 'lodash';
import { Box } from '@mui/material';

function Recognizers({
                        fetchRecognizers,
                        recognizers,
                        recognizeDocumentEntities,
                        updateRecognizer               
                    }) {

    return (
        <Box>
            <AiProcessorsScreen
                fetchAiProcessors={fetchRecognizers}
                aiProcessors={recognizers}
                processDocument={recognizeDocumentEntities}
                aiProcessingLabel={'Recognize Document Entities'}
                aiProcessingIcon={<FitbitTwoToneIcon/>}
                updateProcessor={updateRecognizer}
            />            
        </Box>
    );
}

const mapStateToProps = (state) => {
    const recognizers = Selectors.getRecognizers(state);

    return {
        recognizers
    }
}

const mapDispatchToProps = {
    fetchRecognizers: Thunks.fetchRecognizers,
    recognizeDocumentEntities: Thunks.recognizeDocumentEntities,
    updateRecognizer: Thunks.updateRecognizer
}

export default connect(mapStateToProps, mapDispatchToProps)(Recognizers);
