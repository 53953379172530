import React from 'react';
import {AppBar, Box, Button, CssBaseline, Toolbar, Typography, Container, Link} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {Link as RouterLink} from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import backgroundImg from '../../../img/background_home.jpeg';
import MenuBookIcon from '@mui/icons-material/MenuBook';

function Home() {
    return (
        <div>
            <Box sx={{flexGrow: 1, marginBottom: '0.5rem'}}>
                <CssBaseline/>
                <AppBar position="static">
                    <Toolbar>
                        <Box sx={{display: 'flex', alignItems: 'center', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                            <SmartToyIcon sx={{marginRight: '1rem'}}/>
                            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                QR AI
                            </Typography>
                        </Box>
                        <Button
                            component={Link}
                            href='https://docs.google.com/document/d/1VAR9MyKAuJCaYuRUVNFq_2Efi3L16-VbmKJYjFE2H2k/edit'
                            color="inherit"
                            sx={{color: 'primary.contrastText', ml: 'auto'}}
                            target="_blank"
                            variant='customSecondaryRoundedText'
                            startIcon={<MenuBookIcon/>}
                        >
                            Guide
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container maxWidth={'xl'} sx={{display: 'flex', gap: '2rem'}}>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        p: 2,
                        maxWidth: '50%',
                        textAlign: 'left',
                        pt: '4rem',
                    }}
                >
                    <Typography variant="h1" fontSize={"3rem"} fontWeight={600} sx={{mb: '2rem'}}>
                        Welcome to QR AI App!
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        <b>The QR AI Application</b> is engineered to train the artificial intelligence with the capability to identify the document itself and extract relevant loan information from documents, thereby optimizing the document recognition process.
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        The <b>File Selection</b> tab contains the various undefined documents. Users can upload undefined documents, which the AI then uses as a learning tool. This process is crucial for training the AI to handle a different types of the documents, adapting to new formats and layouts with ease. As a result, the AI becomes more proficient in recognizing documents automatically in the future.
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        Moving on to the <b>Document Selection</b> tab, here you will find documents that have already been processed and categorized into their respective types by users. You will be given a chance to identify whether the users uploaded the documents to right document types ensuring the accuracy and eliminating the human-factor errors.
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        The <b>Entities</b> feature, accessible on both the File Selection and Document Selection tabs, allows you to identify the crucial relevant information for the loan process. By linking this data to the appropriate Entity Type, you effectively train the AI to recognize and extract the necessary information, streamlining the process of auto-populating fields of the loan within the QR software.
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        With each document it processes, the AI learns and evolves, promising a smarter, more efficient way to handle the ever-growing pile of documents.
                    </Typography>
                </Box>
                <Box sx={{maxWidth: '50%', pt: '0rem',}}>
                    <img style={{width: '100%'}} src={backgroundImg} alt='img' />
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', transform: 'translateY(-4.6rem)'}} >
                        <Typography variant="subtitle1" fontSize="1.4rem" align='center' sx={{m: '1.6rem 0 1rem'}}>
                            Click the button below to continue
                        </Typography>
                        <Button
                            variant='customRounded'
                            startIcon={<ArticleIcon/>}
                            component={RouterLink}
                            to="/files"
                            size="large"
                            sx={{
                                mt: "1.4rem",
                                padding: "0.8rem 2rem",
                                width: '20rem',
                                m: '0 auto',
                            }}
                        >
                            File Selection
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default Home;
