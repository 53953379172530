import React, { useEffect, useState } from 'react';
import * as Selectors from '../../Api/selectors';
import * as RootSelectors from '../../../../Shared/Infrastructure/Selectors/RootSelectors';
import * as Thunks from '../../Api/thunk';
import * as SliceActions from '../../Api/slice';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '../../../../Shared/Helpers';
import { connect } from 'react-redux';
import {
    Autocomplete, Box, Button, CircularProgress,
    Divider, FormControl, MenuItem, Select,
    TextField, Tooltip, Typography, Checkbox
} from '@mui/material';
import DocumentFilter from '../../../../Widgets/DocumentSelection/Ui/DocumentFilter';
import _ from 'lodash';
import TableComponent from '../../../../Shared/Components/TableComponent';
import DocumentImageList from '../../../../Widgets/DocumentSelection/Ui/ImageList';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { documentHubProxy } from '../../../../Common/CoreComponents/SignalRProxy';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import * as Actions from "../../Api/actions";



function JobAnalytics({

}) {
    

    return (

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>JobAnalytics</Typography>

        </Box>

    );
}

const mapStateToProps = (state) => {
    const documentTypes = Selectors.getDocumentTypes(state);
    const documentPackageTypes = RootSelectors.getDocumentPackageTypes(state);
    const companies = Selectors.getFilteredCompanies(state);
    const filteredDocuments = Selectors.getDocuments(state);
    const selectedDocumentType = Selectors.getSelectedDocumentType(state);
    const documentFilter = Selectors.getSelectedDocumentFilter(state);
    const selectedDocument = Selectors.getSelectedDocument(state);
    const selectedDocumentPages = Selectors.getSelectedPages(state);
    const sharedCurrentPages = Selectors.getSharedCurrentPages(state);
    const sharedIdentifyPages = Selectors.getSharedIdentifyPages(state);
    return {
        sharedIdentifyPages,
        sharedCurrentPages,
        documentTypes,
        documentPackageTypes,
        companies,
        filteredDocuments,
        selectedDocumentType,
        documentFilter,
        selectedDocument,
        selectedDocumentPages
    }
}

const mapDispatchToProps = {
    fetchDocuments: Thunks.fetchDocuments,
    fetchFilteredCompanies: Thunks.fetchFilteredCompanies,
    fetchDocumentPreview: Thunks.fetchDocumentPreview,
    setSelectedDocumentFilter: SliceActions.setSelectedDocumentFilter,
    setSelectedDocumentType: SliceActions.setSelectedDocumentType,
    clearDocumentFilter: SliceActions.clearDocumentFilter,
    setSelectedPages: SliceActions.setSelectedPages,
    changeSelectedPages: SliceActions.changeSelectedPages,
    setSelectedDocument: SliceActions.setSelectedDocument,
    setEntityList: SliceActions.setEntityList,
    setIsFilePages: SliceActions.setIsFilePages,
    identifyDocumentPage: Thunks.identifyDocumentPage,
    identifyDocument: Thunks.identifyDocument,
    setDocumentAsIdentified: SliceActions.setDocumentAsIdentified
}

export default connect(mapStateToProps, mapDispatchToProps)(JobAnalytics);
