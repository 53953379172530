import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

class DocumentHubProxy {
    constructor() {
        this.hub = new HubConnectionBuilder()
            .withUrl("/docHub")
            .configureLogging(LogLevel.Information)
            .build();
        
        this.onReceiveIdentifyDocumentPages = (handler) => this.hub.on("ReceiveIdentifyDocumentPages", handler);
        this.onReceiveCurrentPages = (handler) => this.hub.on("ReceiveCurrentPages", handler);
        this.onReceiveInitialState = (handler) => this.hub.on("ReceiveInitialState", handler);
        this.onReceiveDeletingPage = (handler) => this.hub.on("ReceiveDeletingPage", handler);
        this.onReceiveDisconnectedUserState = (handler) => this.hub.on("ReceiveDisconnectedUserState", handler);

        this.updateCurrentPages = (pages, isSelected) => this.hub.invoke('UpdateCurrentPages', pages, isSelected);

        this.onReceiveJobUpdate = (handler) => this.hub.on("UpdateJob", handler);

        this.onDisconnect = (handler) => this.hub.onclose((error) => handler(error));

        this.connect = (errorHandler) => this.hub.start()
            .then(() => {
                console.log('Connection to document hub started!');
            })
            .catch(err => errorHandler(err))
    }
}

const documentHubProxy = new DocumentHubProxy();

export
{
    documentHubProxy,
};