import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import dayjs from "dayjs";
import { documentSlice_KEYS } from 'Shared/Infrastructure/Store/ConfigureStore';

export const FILE_FILTER_DEFAULT_STATE = {
    FileName: null,
    Description: null,
    ItemsPerPage: 100,
    PageNumber: 1,
    IsIdentified: null,
    CreatedFrom: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    CreatedTo: null,
    IsPrintable: false,
    ExcludedDocuments: [],
};

export const DOCUMENT_FILTER_DEFAULT_STATE = {
    ItemsPerPage: 100,
    PageNumber: 1,
    DocumentTypeFk: null,
    CreatedFrom: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    CreatedTo: null,
    CompanyId: null,
    DocumentPackageTypes: [],
    IsPrintable: false,
    LoanFk: null,
    SimpleId: null,
    IsIdentified: null,
    ExcludedDocuments: [],
};

const documentSlice = createSlice({
    name: 'documents',
    initialState: {
        documentTypes: [],
        filteredDocuments: [],
        selectedDocumentType: {},
        selectedFileDocumentType: {},
        isFilePages: null,
        documentFilter: DOCUMENT_FILTER_DEFAULT_STATE,
        selectedDocument: {
            DocumentFK: null,
            ScannedPages: [],
        },
        selectedFile: {
            DocumentFK: null,
            ScannedPages: [],
        },
        selectedPages: [],
        addCurrentPages: [],
        currentSample: {},
        dataSamples: {
            DataSamples: [],
            DataSamplesCount: 0,
        },
        showAiProcessingResultDialog: null,
        samplesPagination: {
            RowsPerPage: 100,
            Page: 0,
        },
        selectedDataSamples: [],
        selectedDataSet: {},
        dataSets: [],
        classifiers: [],
        entityList: [],
        recognizers: [],
        dataSamplesStatistics: null,
        filteredCompanies: [],
        sharedCurrentPages: [],
        sharedIdentifyPages: [],
        selectedClassifier: {},
        files: [],
        fileFilter: FILE_FILTER_DEFAULT_STATE,
        aiProcessingResult: [],
        entityTypes: [],
        labels: [],
        selectedLabel: {},
        selectedEntityTypesList: null,
        selectedLabelsList: null,
        sampleFilter: {
            Id: null,
            Name: null,
            docTypeFk: null,
            Status: null,
            PageNumber: 1,
            ItemsPerPage: 100,
            IncludeLabels: null
        },
        loading: false,
        error: null,
    },
    reducers: {
        setDocumentTypes(state, action) {
            return {
                ...state,
                documentTypes: action.payload
            }
        },

        setDataSamplesStatistics(state, action) {
            return {
                ...state,
                dataSamplesStatistics: action.payload
            }
        },

        setFilteredCompanies(state, action) {
            return {
                ...state,
                filteredCompanies: action.payload
            }
        },

        setSharedCurrentPages(state, action) {
            return {
                ...state,
                sharedCurrentPages: action.payload
            }
        },

        changeFilteredDocs(state, action) {
            let docIdx = null;
            const isFilePages = _.get(state, 'isFilePages') === true;
            const docs = _.get(state, `${isFilePages
                ? 'files'
                : 'filteredDocuments'
                }`);

            if (docs)
                docIdx = docs
                    .findIndex(x => x.Id === action.payload.documentId ?? null);

            if (!(_.isNil(docIdx) || docIdx === -1)) {
                if (action.payload.isIdentify)
                    return {
                        ...state,
                        sharedCurrentPages:
                            state.sharedCurrentPages
                                .filter(page => !action.payload.scannedPages.includes(page)),
                        [isFilePages ? 'files' : 'filteredDocuments']: docs.map(x => {
                            if (x.Id === action.payload.documentId)
                                return { ...x, IdentifiedPages: x.IdentifiedPages + action.payload.scannedPages.length };
                            return x;
                        }),
                        sharedIdentifyPages: [...state.sharedIdentifyPages, ...action.payload.scannedPages]
                    }

                const selectedDoc = _.get(state, `${isFilePages
                    ? 'selectedFile'
                    : 'selectedDocument'
                    }`);
                const selectedDocPages = _.get(state, documentSlice_KEYS.SELECTED_PAGES);

                return {
                    ...state,
                    [isFilePages ? 'files' : 'filteredDocuments']: docs.map(x => {
                        if (x.Id === action.payload.documentId)
                            return { ...x, IdentifiedPages: x.IdentifiedPages - action.payload.scannedPages.length };
                        return x;
                    }),
                    [isFilePages ? 'selectedFile' : 'selectedDocument']: {
                        ...selectedDoc,
                        ScannedPages: selectedDoc.ScannedPages.map(page => {
                            if (action.payload.scannedPages.includes(page.ScannedPageId))
                                return { ...page, IsIdentified: false }
                            return page;
                        })
                    },
                    ...(_.isArray(selectedDocPages) && selectedDocPages.length ?
                        {
                            [documentSlice_KEYS.SELECTED_PAGES]: [{
                                ...selectedDocPages[0],
                                ScannedPages: selectedDocPages[0].ScannedPages
                                    .filter(page => !action.payload.scannedPages.includes(page.ScannedPageId))
                            }]
                        } : {}),
                    sharedIdentifyPages:
                        state.sharedIdentifyPages
                            .filter(page => !action.payload.scannedPages.includes(page))
                }
            }
        },

        changeSharedCurrentPages(state, action) {
            let pageIdx = null;
            const scannedPages = _.get(state, `${documentSlice_KEYS.SELECTED_PAGES}[0].ScannedPages`);

            if (scannedPages)
                pageIdx = scannedPages
                    .findIndex(x => x.ScannedPageId === action.payload.pages[0] ?? null);

            if (_.isNil(pageIdx) || pageIdx === -1) {
                if (action.payload.isSelected)
                    return {
                        ...state,
                        sharedCurrentPages: [...state.sharedCurrentPages, ...action.payload.pages]
                    }

                return {
                    ...state,
                    sharedCurrentPages:
                        state.sharedCurrentPages
                            .filter(page => !action.payload.pages.includes(page))

                }
            }
        },

        setFiles(state, action) {
            return {
                ...state,
                files: action.payload
            }
        },

        removeEntityType(state, action) {
            return {
                ...state,
                entityTypes: state.entityTypes.filter(x => x.Id !== action.payload)
            }
        },

        removeDataSet(state, action) {
            return {
                ...state,
                dataSets: state.dataSets.filter(x => x.Id !== action.payload)
            }
        },

        setSelectedEntityType(state, action) {
            return {
                ...state,
                selectedEntityType: action.payload
            }
        },

        removeLabel(state, action) {
            return {
                ...state,
                labels: state.labels.filter(x => x.Id !== action.payload)
            }
        },

        setSelectedLabel(state, action) {
            return {
                ...state,
                selectedLabel: action.payload
            }
        },
        
        setDocuments(state, action) {
            return {
                ...state,
                filteredDocuments: action.payload
            }
        },

        setClassifiers(state, action) {
            return {
                ...state,
                classifiers: action.payload
            }
        },

        setRecognizers(state, action) {
            return {
                ...state,
                recognizers: action.payload
            }
        },

        setEntityTypes(state, action) {
            return {
                ...state,
                entityTypes: action.payload
            }
        },

        addEntityType(state, action) {
            return {
                ...state,
                entityTypes: [...state.entityTypes, action.payload]
            }
        },

        setLabels(state, action) {
            return {
                ...state,
                labels: action.payload
            }
        },

        addLabel(state, action) {
            return {
                ...state,
                labels: [...state.labels, action.payload]
            }
        },

        updateEntityTypesList(state, action) {
            return {
                ...state,
                selectedEntityTypesList:
                    (action.payload.Id === state.selectedEntityTypesList.Id
                        && action.payload.Name === state.selectedEntityTypesList.Name)
                        ? action.payload
                        : state.selectedEntityTypesList,
                entityTypesLists: state.entityTypesLists.map(x => {
                    if (x.Id === action.payload.Id)
                        return action.payload;
                    return x;
                })
            }
        },

        updateLabelsList(state, action) {
            return {
                ...state,
                selectedLabelsList:
                    (action.payload.Id === state.selectedLabelsList.Id
                        && action.payload.Name === state.selectedLabelsList.Name)
                        ? action.payload
                        : state.selectedLabelsList,
                labelsLists: state.labelsLists.map(x => {
                    if (x.Id === action.payload.Id)
                        return action.payload;
                    return x;
                })
            }
        },

        setSelectedDocumentType(state, action) {
            return {
                ...state,
                selectedDocumentType: action.payload
            }
        },

        setSelectedFileDocumentType(state, action) {
            return {
                ...state,
                selectedFileDocumentType: action.payload
            }
        },

        setSelectedDocumentFilter(state, action) {
            return {
                ...state,
                documentFilter: {
                    ...state.documentFilter,
                    ...action.payload
                }
            }
        },

        clearDocumentFilter(state, action) {
            return {
                ...state,
                documentFilter: {
                    ItemsPerPage: 10,
                    PageNumber: 1,
                    DocumentTypeFk: null,
                    CreatedFrom: null,
                    CreatedTo: null,
                    CompanyId: null,
                    DocumentPackageTypes: [],
                    IsPrintable: false,
                    LoanFk: null,
                    SimpleId: null,
                    IsIdentified: null
                },
            }
        },

        setSelectedFileFilter(state, action) {
            return {
                ...state,
                fileFilter: {
                    ...state.fileFilter,
                    ...action.payload
                }
            }
        },

        setSelectedDocument(state, action) {
            return {
                ...state,
                selectedDocument: action.payload
            }
        },

        setSelectedFile(state, action) {
            return {
                ...state,
                selectedFile: action.payload
            }
        },

        setSelectedPages(state, action) {
            const filteredPages = action.payload.map(file => ({
                ...file,
                ScannedPages: file.ScannedPages.filter(page => !page.IsIdentified
                    && !(state.sharedCurrentPages || []).find(x => x === page.ScannedPageId)
                    && !(state.sharedIdentifyPages || []).find(x => x === page.ScannedPageId))
            }));
            return {
                ...state,
                selectedPages: filteredPages
            }
        },


        setIsFilePages(state, action) {
            return {
                ...state,
                isFilePages: action.payload
            }
        },
        setPageAsIdentified(state, action) {
            const isFilePages = _.get(state, 'isFilePages', false);
            const files = _.get(state, 'files');
            const filteredDocuments = _.get(state, 'filteredDocuments');
            const scannedPages = isFilePages
                ? _.get(state, 'selectedFile.ScannedPages')
                : _.get(state, 'selectedDocument.ScannedPages');

            const updatedScannedPages = scannedPages && scannedPages.map(page => {
                if (page.ScannedPageId === action.payload) {
                    return {
                        ...page,
                        IsIdentified: true
                    };
                }
                return page;
            });

            const newState = {
                ...state,
                [isFilePages ? 'selectedFile' : 'selectedDocument']: {
                    ...state[isFilePages ? 'selectedFile' : 'selectedDocument'],
                    ScannedPages: updatedScannedPages
                },
                [isFilePages ? 'files' : 'filteredDocuments']:
                    (isFilePages ? files : filteredDocuments).map(doc => {
                        if (doc.Id === state[isFilePages ? 'selectedFile' : 'selectedDocument'].DocumentFK)
                            return {
                                ...doc,
                                IdentifiedPages: doc.IdentifiedPages + 1
                            };
                        return doc;
                    })
            };

            return newState;
        },
        setDocumentAsIdentified(state, action) {
            const isFilePages = _.get(state, 'isFilePages', false);
            const files = _.get(state, 'files');
            const filteredDocuments = _.get(state, 'filteredDocuments');

            const newState = {
                ...state,
                [isFilePages ? 'files' : 'filteredDocuments']:
                    (isFilePages ? files : filteredDocuments).map(doc => {
                        if (doc.Id === action.payload)
                            return {
                                ...doc,
                                IdentifiedPages: doc.PagesCount
                            };
                        return doc;
                    })
            };

            return newState;
        },
        
        changeSelectedPages(state, action) {
            let pageIdx = null;
            const isFilePages = _.get(state, 'isFilePages', false);;
            const scannedPages = _.get(state, `${documentSlice_KEYS.SELECTED_PAGES}[0].ScannedPages`);

            if (scannedPages)
                pageIdx = scannedPages.findIndex(x => x.ScannedPageId === action.payload.ScannedPageId);

            if (_.isNil(pageIdx))
                return {
                    ...state,
                    selectedPages: [{
                        DocumentFK: isFilePages? state.selectedFile.DocumentFK : state.selectedDocument.DocumentFK,
                        ScannedPages: [action.payload]
                    }]
                }

            if (pageIdx === -1)
                return {
                    ...state,
                    selectedPages: [{
                        DocumentFK: isFilePages? state.selectedFile.DocumentFK : state.selectedDocument.DocumentFK,
                        ScannedPages: [...state.selectedPages[0].ScannedPages, action.payload]
                            .sort((a, b) => a.PageNumber - b.PageNumber)
                    }]
                }

            return {
                ...state,
                selectedPages: [{
                    DocumentFK: isFilePages? state.selectedFile.DocumentFK : state.selectedDocument.DocumentFK,
                    ScannedPages: state.selectedPages[0].ScannedPages
                        .filter(x => x.ScannedPageId !== action.payload.ScannedPageId)
                        .sort((a, b) => a.PageNumber - b.PageNumber)
                }]
            }
        },

        removeDocumentClassifier(state, action) {
            return {
                ...state,
                classifiers: state.classifiers.filter(x => x.Id !== action.payload)
            }
        },

        setDataSamplesView(state, { payload }) {
            return {
                ...state,
                // dataSamples: payload.DataSamples,
                dataSamplesCount: payload.DataSamplesCount,
            };
        },

        addSelectedDataSamples(state, { payload }) {
            return {
                ...state,
                selectedDataSamples: _.unionBy(state.selectedDataSamples, payload, 'Id'),
            }
        },

        deselectDataSamplesByDocTypeFk(state, { payload }) {
            return {
                ...state,
                selectedDataSamples: state.selectedDataSamples.filter((x) => x.DocumentTypeFK !== +payload),
            }
        },

        setDataSamples(state, action) {
            return {
                ...state,
                dataSamples: action.payload
            }
        },

        setEntityList(state, action) {
            return {
                ...state,
                entityList: action.payload
            }
        },

        removeDataSample(state, action) {
            const dataSample = state.dataSamples.DataSamples.find(x => x.Id === action.payload);
            const docType = dataSample ? dataSample.DocumentTypeFK : null;
            const dataSamplesCount = _.cloneDeep(state.dataSamplesCount);
            let dataSamplesCountByFilter = _.cloneDeep(state.dataSamples.DataSamplesCount);

            if (docType && !_.isNil(dataSamplesCount)) {
                dataSamplesCount[docType] = { 
                    TotalCount: dataSamplesCount[docType].TotalCount - 1,
                    ...(dataSample.Status === "Validated" ? 
                        {ValidatedCount: dataSamplesCount[docType].ValidatedCount - 1} : {})
                };
                dataSamplesCountByFilter = dataSamplesCountByFilter - 1;
            }

            return {
                ...state,
                dataSamples: {
                    DataSamples: state.dataSamples.DataSamples.filter(x => x.Id !== action.payload),
                    DataSamplesCount: dataSamplesCountByFilter
                },
                selectedDataSamples: state.selectedDataSamples.filter(x => x.Id !== action.payload),
                dataSamplesCount,
            };
        },



        setSampleFilter(state, action) {
            return {
                ...state,
                sampleFilter: {
                    ...state.sampleFilter,
                    ...action.payload
                }
            }
        },

        setSamplesPagination(state, action) {
            return {
                ...state,
                samplesPagination: { ...state.samplesPagination, ...action.payload }
            }
        },

        setSelectedDataSamples(state, action) {
            return {
                ...state,
                selectedDataSamples: action.payload
            }
        },

        addSelectedDataSample(state, action) {
            if (state.selectedDataSamples.some(x => x.Id == action.payload.Id)) {
                return {
                    ...state,
                    selectedDataSamples: state.selectedDataSamples.filter(x => x.Id !== action.payload.Id)
                }
            }

            return {
                ...state,
                selectedDataSamples: [...state.selectedDataSamples, action.payload]
            }
        },

        updateDocumentClassifier(state, action) {
            return {
                ...state,
                classifiers: state.classifiers.map(x => {
                    if (x.ProcessorId === action.payload.ProcessorId)
                        return action.payload;
                    if (action.payload.IsActive && x.IsActive)
                        return { ...x, IsActive: false };
                    return x;
                })
            }
        },

        updateEntityRecognizer(state, action) {
            return {
                ...state,
                recognizers: state.recognizers.map(x => {
                    if (x.ProcessorId === action.payload.ProcessorId)
                        return action.payload;
                    if (action.payload.IsActive && x.IsActive)
                        return { ...x, IsActive: false };
                    return x;
                })
            }
        },

        setAiProcessingResult(state, action) {
            return {
                ...state,
                aiProcessingResult: action.payload
            }
        },

        setShowingAiProcessingResultDialog(state, action) {
            return {
                ...state,
                showAiProcessingResultDialog: action.payload
            }
        },

        setDataSets(state, action) {
            return {
                ...state,
                dataSets: action.payload
            }
        },

        setDataSetProcessed(state, action) {
            return {
                ...state,
                dataSets: state.dataSets.map(x => {
                    if (x.Id === action.payload)
                        return { ...x, IsProcessed: true };
                    return x;
                })
            }
        },

        setClassifierProcessed(state, action) {
            return {
                ...state,
                classifiers: state.classifiers.map(x => {
                    if (x.Id === action.payload)
                        return { ...x, ShowResumeButton: false };
                    return x;
                })
            }
        },

        setSelectedDataSet(state, action) {
            return {
                ...state,
                selectedDataSet: action.payload
            }
        },

        setLoading(state, action) {
            return {
                ...state,
                loading: action.payload
            }
        },

        setError(state, action) {
            return {
                ...state,
                error: action.payload
            }
        },
        setCurrentSample(state, action) {
            return {
                ...state,
                currentSample: action.payload
            }
        }
    },
});

export const {
    setDocumentTypes,
    setSelectedFile,
    setDocuments,
    setSelectedDocumentType,
    setSelectedFileDocumentType,
    updateDocumentClassifier,
    setSelectedDocumentFilter,
    setRecognizers,
    setSelectedDocument,
    setSelectedPages,
    setSharedCurrentPages,
    setDataSamples,
    addSelectedDataSamples,
    deselectDataSamplesByDocTypeFk,
    setDataSamplesView,
    removeDataSample,
    setSamplesPagination,
    addEntityType,
    setEntityTypes,
    updateEntityTypesList,
    addSelectedDataSample,
    setSelectedDataSamples,
    setDataSets,
    removeEntityType,
    setSelectedEntityType,
    setSelectedDataSet,
    setClassifiers,
    setEntityList,
    setAiProcessingResult,
    setFilteredCompanies,
    setFiles,
    clearDocumentFilter,
    setShowingAiProcessingResultDialog,
    setSelectedFileFilter,
    changeSelectedPages,
    setDataSamplesStatistics,
    setLoading,
    setIsFilePages,
    changeSharedCurrentPages,
    changeFilteredDocs,
    updateEntityRecognizer,
    setPageAsIdentified,
    setDocumentAsIdentified,
    setSampleFilter,
    setLabels,
    addLabel,
    setSelectedLabel,
    removeDocumentClassifier,
    removeLabel,
    setClassifierProcessed,
    removeDataSet,
    setDataSetProcessed,
    setError,
    setCurrentSample
} = documentSlice.actions;
export default documentSlice;
