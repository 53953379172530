import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import * as Thunks from '../../../../Api/thunk';
import * as Selectors from '../../../../Api/selectors';
import * as Slice from '../../../../Api/slice';
import TableComponent from "../../../../../../Shared/Components/TableComponent";
import DeleteItemDialog from "../../../../../../Widgets/DeleteItemDialog";
import EditNoteIcon from '@mui/icons-material/EditNote';
import {
    Box, Button, TextField, Dialog, 
    DialogActions, DialogContent, DialogTitle
    } from '@mui/material';
import _ from 'lodash';

function EntityTypes({
        fetchEntityTypes,
        entityTypes, 
        selectedEntityType,
        setSelectedEntityType,
        addEntityType,
        deleteEntityType,
        error,
        setError
    }) {

    const columns = [
        {
            name: 'Name',
            value: 'Name',
            align: 'left'
        },
        {
            name: 'Actions',
            value: 'Actions',
            align: 'left'
        }
    ];

    const calculateEntityTypesRows = () => {
        return entityTypes.map((item, index) => {
            return {
                ...item,
                Actions: 
                    <Box
                        key={`list-row-key-${index}`}    
                    >
                        <Button variant="customRoundedTextDanger"
                            onClick={() => handleDeleteEntityTypeClick(item)}
                        >
                            Delete
                        </Button>
                    </Box>
            }
        });
    }

    const regex = /^[A-Z0-9_]+$/;
    const [addedEntityType, setAddedEntityType] = useState(null);
    const [openAddingDialog, setOpenAddingDialog] = useState(false);

    const [openDeletingDialog, setOpenDeletingDialog] = useState(false);
    const [deletingDialogData, setDeletingDialogData] = useState(null);

    const handleSelectedRow = (row) => {
        return row.Id === _.get(selectedEntityType, 'Id');
    }

    const handleDeleteEntityTypeClick = (item) => {
        setDeletingDialogData({deleteAction: () => deleteEntityType(item.Id), itemName: item.Name});
        setOpenDeletingDialog(true);
    }

    const handleRowItemClick = (row) => {
        const entityType = entityTypes.find(x => x.Id === row.Id);
        setSelectedEntityType(entityType);
    }

    const handleAddingEntityType = () => {
        if (!regex.test(addedEntityType)) {
            setError(" Entity Type must contain caps or underscores");
            return;
        }
        addEntityType({Name: addedEntityType});
        setError(null);
        setAddedEntityType(null);
        setOpenAddingDialog(false);
    }

    const handleCloseAddedDialog = () => {
        setAddedEntityType(null);
        setError(null);
        setOpenAddingDialog(false);
    }

    useEffect(() => {
        fetchEntityTypes();
    }, [fetchEntityTypes]);

    return (
        <Box>
            <Box
                sx={{
                    mb: '15px',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <Button
                    variant="customRounded"
                    startIcon={<EditNoteIcon/>}
                    size="large"
                    onClick={() => setOpenAddingDialog(true)}
                >
                    Add new type
                </Button>                
            </Box>

            <Box
                sx={{
                    display: 'flex', 
                    justifyContent:'space-between',
                    columnGap: '3em'
                }}
            >
                <Box sx={{width: '100%'}}>
                    <TableComponent
                        columns={columns}
                        rows={calculateEntityTypesRows()}
                        enableTablePagination={true}
                        handleSelectedRow={handleSelectedRow}
                        handleRowItemClick={handleRowItemClick}
                    />
                </Box>
            </Box>

            <Dialog
                open={openAddingDialog}
                onClose={() => handleCloseAddedDialog()}
            >
                <DialogTitle>
                    {`Add Entity Type`}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <TextField
                            error={!_.isNil(error)}
                            variant="standard"
                            label="Entity Type Name"
                            helperText={error}
                            onChange={(e) => setAddedEntityType(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='customRoundedText' onClick={() => handleCloseAddedDialog()}>Cancel</Button>
                    <Button variant='customRoundedText' onClick={() => handleAddingEntityType()}>Add</Button>
                </DialogActions>
            </Dialog>

            <DeleteItemDialog
                openDialog={openDeletingDialog}
                setOpenDialog={setOpenDeletingDialog}
                {...deletingDialogData}
            />
        </Box>
    );
}

const mapStateToProps = (state) => {
    const entityTypes = Selectors.getEntityTypes(state);
    const selectedEntityType = Selectors.getSelectedEntityType(state);
    const error = Selectors.getError(state);

    return {
        entityTypes,
        selectedEntityType,
        error
    }
}

const mapDispatchToProps = {
    fetchEntityTypes: Thunks.fetchEntityTypes,
    addEntityType: Thunks.createEntityType,
    deleteEntityType: Thunks.deleteEntityType,
    setSelectedEntityType: Slice.setSelectedEntityType,
    setError: Slice.setError
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityTypes);
