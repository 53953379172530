import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { aiAppInfoProxy } from './Common/CoreComponents/AjaxProxy';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './Pages/Home/Ui/Home';
import NotFound from './Pages/NotFound/Ui/NotFound';
import ConfigureStore from './Shared/Infrastructure/Store/ConfigureStore';
import DocumentsMenu from './Pages/Documents/Ui/DocumentsMenu';
import DocumentSelection from './Pages/Documents/Ui/DocumentSelection/DocumentSelection';
import FileSelection from './Pages/Documents/Ui/FileSelection/FileSelection';
import Classifiers from './Pages/Documents/Ui/AiProcessing/Classifier/Classifiers';
import PreviewPages from './Pages/Documents/Ui/PreviewPages/PreviewPages';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import {
    BORDER_COLOR,
    CONTRAST_TEXT_COLOR,
    DISABLED_COLOR, ERROR_COLOR,
    PRIMARY_COLOR,
    PRIMARY_LIGHT_COLOR,
    SECONDARY_COLOR
} from "./Shared/Styles/Colors";
import DataSamples from "./Pages/Documents/Ui/DataSamples/DataSamples";
import DataSets from "./Pages/Documents/Ui/DataSets/DataSets";
import Recognizers from './Pages/Documents/Ui/AiProcessing/Recognizer/Recognizers';
import EntityTypes from './Pages/Documents/Ui/AiProcessing/Recognizer/EntityTypes/EntityTypes';
import ErrorBoundary from './Pages/Documents/Ui/ErrorBoundary';
import { JobsComponent } from './Pages/Documents/Ui/Jobs/JobsComponent';
import JobResultValidation from './Pages/Documents/Ui/Jobs/JobResultValidation';
import JobAnalytics from './Pages/Documents/Ui/Jobs/JobAnalytics';
import Labels from './Pages/Documents/Ui/AiProcessing/Recognizer/Labels/Labels';

function App() {

    useEffect(() => {
        aiAppInfoProxy.getAppInfo();
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: PRIMARY_COLOR,
                light: PRIMARY_LIGHT_COLOR,
                contrastText: CONTRAST_TEXT_COLOR,
            },
            secondary: {
                main: SECONDARY_COLOR,
                contrastText: CONTRAST_TEXT_COLOR,
            },
            error: {
                main: ERROR_COLOR,
            }
        },
        components: {
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'customRounded' },
                        style: {
                            textTransform: 'none',
                            borderRadius: '25px',
                            backgroundColor: PRIMARY_COLOR,
                            color: CONTRAST_TEXT_COLOR,
                            '&:hover': {
                                backgroundColor: CONTRAST_TEXT_COLOR,
                                color: BORDER_COLOR
                            },
                            '&:disabled': {
                                backgroundColor: DISABLED_COLOR,
                                color: CONTRAST_TEXT_COLOR,
                                pointerEvents: "auto"
                            }
                        }
                    },
                    {
                        props: { variant: 'customRoundedDanger' },
                        style: {
                            textTransform: 'none',
                            borderRadius: '25px',
                            backgroundColor: ERROR_COLOR,
                            color: CONTRAST_TEXT_COLOR,
                            '&:hover': {
                                backgroundColor: CONTRAST_TEXT_COLOR,
                                color: BORDER_COLOR
                            },
                            '&:disabled': {
                                backgroundColor: DISABLED_COLOR,
                                color: CONTRAST_TEXT_COLOR,
                                pointerEvents: "auto"
                            }
                        }
                    },
                    {
                        props: { variant: 'customSecondaryRoundedText' },
                        style: {
                            textTransform: 'none',
                            borderRadius: '25px',
                            color: PRIMARY_COLOR,
                            '&:hover': {
                                backgroundColor: CONTRAST_TEXT_COLOR,
                                color: BORDER_COLOR
                            },
                            '&:disabled': {
                                backgroundColor: DISABLED_COLOR,
                                color: CONTRAST_TEXT_COLOR,
                                pointerEvents: "auto"
                            }
                        }
                    },
                    {
                        props: { variant: 'customRoundedText' },
                        style: {
                            textTransform: 'none',
                            borderRadius: '25px',
                            color: PRIMARY_COLOR,
                            '&:hover': {
                                backgroundColor: PRIMARY_COLOR,
                                color: CONTRAST_TEXT_COLOR
                            },
                            '&:disabled': {
                                backgroundColor: DISABLED_COLOR,
                                color: CONTRAST_TEXT_COLOR,
                                pointerEvents: "auto"
                            }
                        }
                    },
                    {
                        props: { variant: 'customRoundedTextDanger' },
                        style: {
                            textTransform: 'none',
                            borderRadius: '25px',
                            marginLeft: '1rem',
                            color: ERROR_COLOR,
                            '&:hover': {
                                backgroundColor: ERROR_COLOR,
                                color: CONTRAST_TEXT_COLOR
                            },
                            '&:disabled': {
                                backgroundColor: DISABLED_COLOR,
                                color: CONTRAST_TEXT_COLOR,
                                pointerEvents: "auto"
                            }
                        }
                    }
                ]
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: PRIMARY_COLOR,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        "&:hover": {
                            backgroundColor: 'white',
                        },
                    },
                },
            },
        }
    });

    const router = createBrowserRouter([
        {
            path: "",
            element: <ErrorBoundary />,
            children: [
                {
                    path: "/home",
                    element: <Home />,
                },
                {
                    path: "/documents",
                    element: <DocumentsMenu />,
                    errorElement: <NotFound />,
                    children: [
                        {
                            index: true,
                            element: <DocumentSelection />,
                        },
                        {
                            // path: "preview",
                            path: "preview/:sampleId?",
                            element: <PreviewPages />,
                        },
                        {
                            path: "samples/:docTypeFk?",
                            element: <DataSamples />,
                        },
                        {
                            path: "sets",
                            element: <DataSets />,
                        },
                        {
                            path: "classifiers",
                            element: <Classifiers />,
                        },
                        {
                            path: "recognizers",
                            element: <Recognizers />,
                        },
                        {
                            path: "recognizers/entity-types",
                            element: <EntityTypes />,
                        },
                        {
                            path: "recognizers/labels",
                            element: <Labels/>,
                        },
                    ],
                },
                {
                    path: "*",
                    element: <NotFound />,
                },
                {
                    path: "/files",
                    element: <DocumentsMenu />,
                    errorElement: <NotFound />,
                    children: [
                        {
                            index: true,
                            element: <FileSelection />,
                        }
                    ],
                },
                {
                    path: "/jobs",
                    element: <DocumentsMenu />,
                    errorElement: <NotFound />,
                    children: [
                        {
                            path: "runner",
                            element: <JobsComponent />,
                        },
                        {
                            path: "validation/:jobId/:fileId?",
                            element: <JobResultValidation />,
                        },
                        {
                            path: "analytics",
                            element: <JobAnalytics />,
                        }
                    ],
                }
            ]
        }
    ]);

    const store = ConfigureStore();

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
            </ThemeProvider>
        </Provider>
    );
}

export default App;
