import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import * as Thunks from '../../../../Api/thunk';
import * as Selectors from '../../../../Api/selectors';
import * as Slice from '../../../../Api/slice';
import TableComponent from "../../../../../../Shared/Components/TableComponent";
import DeleteItemDialog from "../../../../../../Widgets/DeleteItemDialog";
import AddIcon from '@mui/icons-material/Add';
import {
    Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import _ from 'lodash';
import { COLOR_MAP } from 'Common/CoreComponents/constants';

function Labels({
    fetchLabels,
    labels,
    setSelectedLabel,
    selectedLabel,
    addLabel,
    removeLabel,
    updateLabel,
    error,
    setError
}) {

    const columns = [
        {
            name: 'Name',
            value: 'Name',
            align: 'left'
        },
        {
            name: 'Color',
            value: 'Color',
            align: 'left'
        },
        {
            name: 'Actions',
            value: 'Actions',
            align: 'left'
        }
    ];
    
    const [addedLabel, setAddedLabel] = useState({ name: '', Color: 'green' });
    const [openAddingDialog, setOpenAddingDialog] = useState(false);
    const [openDeletingDialog, setOpenDeletingDialog] = useState(false);
    const [deletingDialogData, setDeletingDialogData] = useState(null);
    const [isColorSelect, isColorChange] = useState(false);

    //#region Functions 
    const handleSelectedRow = (row) => {
        return row.Id === _.get(selectedLabel, 'Id');
    }

    const handleColorClick = (item) => {
        setAddedLabel(item);
        setSelectedLabel(item);
        isColorChange(true);
        setOpenAddingDialog(true);
    };

    const handleDeleteLabel = (item) => {
        setDeletingDialogData({ deleteAction: () => removeLabel(item.Id), itemName: item.Name });
        setOpenDeletingDialog(true);
    }
    
    const calculateRows = () => {
        return labels.map((item, index) => {
            return {
                ...item,
                Actions:
                    <Box key={`label-list-row-key-${index}`}>
                        <Button
                            variant="customRoundedText"
                            onClick={() => handleColorClick(item)}
                        >
                            Change
                        </Button>
                        <Button
                            variant="customRoundedTextDanger"
                            onClick={() => handleDeleteLabel(item)}
                        >
                            Delete
                        </Button>
                    </Box>,
                Color:
                    <Box
                        key={`color-box-${index}`}
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: item.Color,
                            display: 'inline-block',
                            marginRight: '8px'
                        }}
                    />
            }
        });
    }
    const handleRowItemClick = (row) => {
        const label = labels.find(x => x.Id === row.Id);
        setSelectedLabel(label);
    }

    const handleAddLabel = () => {
        if (!addedLabel.name || !addedLabel.Color) {
            setError('Name and Color fields are required.');
            return;
        }
        addLabel({ name: addedLabel.name, Color: addedLabel.Color });
        setSelectedLabel({ name: addedLabel.name, Color: addedLabel.Color });
        handleCloseDialog();
    }

    const patchLabel = async (e) => {
        const array = [{ path: 'Color', value: e.target.value }]
        const patchArray = array.map(x => {
            return {
                op: 'replace',
                path: `/${x.path}`,
                value: x.value
            };
        });
        const labelId = selectedLabel?.Id;

        await updateLabel({ labelId, patchArray, labels });
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setAddedLabel({ name: '', Color: 'green' });
        setError(null);
        setOpenAddingDialog(false);
        isColorChange(false);
    }

    useEffect(() => {
        fetchLabels();
    }, [fetchLabels]);
    const colorSelect = (
        <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
            <InputLabel id="color-select-label">Color</InputLabel>
            <Select
                labelId="color-select-label"
                id="color-select"
                value={addedLabel?.Color || selectedLabel?.Color}
                onChange={(e) => setAddedLabel(prev => ({ ...prev, Color: e.target.value }))}
                fullWidth
            >
                {Object.keys(COLOR_MAP).map(color => (
                    <MenuItem
                        key={color}
                        value={color}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '100px' }}>
                            {color}
                            <div
                                key={`color-item-${color}`}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: color,
                                    display: 'inline-block',
                                    marginRight: '8px'
                                }}
                            />
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const renderLabelDialog = () => {
        return (
            <Dialog open={openAddingDialog} onClose={handleCloseDialog}>
                {!isColorSelect && <DialogTitle>{`Add Label`}</DialogTitle>}
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {!isColorSelect && (
                            <TextField
                                error={!_.isNil(error)}
                                variant="standard"
                                label="Label Name"
                                helperText={error}
                                onChange={(e) => setAddedLabel(prev => ({ ...prev, name: e.target.value }))}
                                fullWidth
                            />
                        )}
                        {colorSelect}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='customRoundedText' onClick={handleCloseDialog}>Cancel</Button>
                    {!isColorSelect
                        ? (<Button variant='customRoundedText' onClick={handleAddLabel}>Add</Button>)
                        : (<Button
                            variant='customRoundedText'
                            onClick={() => patchLabel({ target: { value: addedLabel.Color } })}
                        >
                            Change
                        </Button>
                        )}
                </DialogActions>
            </Dialog>
        );
    };
    //#endregion Functions

    return (
        <Box>
            <Box
                sx={{
                    mb: '15px',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <Button
                    variant="customRounded"
                    startIcon={<AddIcon />}
                    size="large"
                    onClick={() => setOpenAddingDialog(true)}
                >
                    Add new label
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    columnGap: '3em'
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <TableComponent
                        columns={columns}
                        rows={calculateRows()}
                        enableTablePagination={true}
                        handleSelectedRow={handleSelectedRow}
                        handleRowItemClick={handleRowItemClick}
                    />
                </Box>
            </Box>
            {renderLabelDialog()}
            <DeleteItemDialog
                openDialog={openDeletingDialog}
                setOpenDialog={setOpenDeletingDialog}
                {...deletingDialogData}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    const labels = Selectors.getLabels(state);
    const selectedLabel = Selectors.getSelectedLabel(state);
    const error = Selectors.getError(state);
    return {
        labels,
        selectedLabel,
        error
    }
}

const mapDispatchToProps = {
    fetchLabels: Thunks.fetchLabels,
    addLabel: Thunks.createLabel,
    setSelectedLabel: Slice.setSelectedLabel,
    updateLabel: Thunks.updateLabel,
    removeLabel: Thunks.deleteLabel,
    setError: Slice.setError
}

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
