import { useEffect, useRef, useState } from "react";
import * as PropTypes from 'prop-types';
import _ from "lodash";
import * as canvasHelper from './canvas-helper';
import { COLOR_MAP, getLabeledAreaId } from "Common/CoreComponents/constants";
import { Button, Box, Fab, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

const POINT_PIXELS = 16;
const LINE_COLOR = 'red';
const POINT_COLOR = '#45D38C';
const TEXT_COLOR = '#fc03a1';
const TEXT_FONT = '16px sans-serif';

export function LabelingModule(props) {
    const { labelOptions, showLabeledAreas } = props;
    const [labelingModeActive, setLabelingModeActive] = useState(false);
    const [labelKey, setSelectedLabelKey] = useState('');
    const [points, setPoints] = useState([]);
    const [labeledAreas, setLabeledAreas] = useState([]);
    const [labeledAreasDrawn, setLabeledAreasDrawn] = useState([]);
    const [labelOptionsMap, setLabelOptionsMap] = useState({});
    const [canvasMeta, setCanvasMeta] = useState(null);
    const [labeledAreaPointHovered, setLabeledAreaPointHovered] = useState(null);
    const [labeledAreaPointMoving, setLabeledAreaPointMoving] = useState(null);

    const canvasRef = useRef(null);
    const prevLabeledAreas = useRef([]);
    const prevCanvasMeta = useRef(null);
    const resizeHandlerRef = useRef(_.debounce(reesizeWindowHandler, 250, {
        'trailing': true
    }));
    const imgRef = useRef(null);
    //#region effects
    useEffect(() => {
        console.log('mount');
        imgRef.current = props.imgRef.current;
        imgRef.current.addEventListener('load', imageLoadedHandler);
        window.addEventListener('resize', resizeHandlerRef.current);
        return () => {
            console.log('un-mount', imgRef.current);
            window.removeEventListener('resize', resizeHandlerRef.current);
            imgRef.current.removeEventListener('load', imageLoadedHandler);
        };
    }, []);

    useEffect(() => {
        if (!labelOptions) return;
        setLabelOptionsMap(labelOptions.reduce((acc, option) => {
            return { ...acc, [option.Id]: option };
        }, {}));
    }, [labelOptions]);

    useEffect(() => {
        if (!canvasMeta) return;

        const labeledAreasUpdated = !_.isEqual(prevLabeledAreas.current, labeledAreas);
        const canvasMetaUpdated = !_.isEqual(prevCanvasMeta.current, canvasMeta);
        if (!(labeledAreasUpdated || canvasMetaUpdated))
            return;
        
        console.log(`redrawing areas: areas updated - ${labeledAreasUpdated}, canvas meta updated - ${canvasMetaUpdated}`);
        const updatedAreas = _.differenceBy(prevLabeledAreas.current, labeledAreas, r => JSON.stringify(r));
        console.log(updatedAreas, labeledAreas, labeledAreasDrawn);
        const updatedAreaIds = [];
        for (let index = 0; index < updatedAreas.length; index++) {
            const areaId = getLabeledAreaId(updatedAreas[index]);
            canvasHelper.clearSelectedArea(getCanvasContext(), convertToPixels(updatedAreas[index].points), POINT_PIXELS);
            console.log(`clearing area: id=${areaId}`);
            updatedAreaIds.push(areaId);
        }
        if (updatedAreaIds.length) {
            for (let index = 0; index < updatedAreaIds.length; index++) {
                const drawnAreaId = updatedAreaIds[index];
                const drawnAreaIndex = labeledAreasDrawn.indexOf(drawnAreaId);
                if(drawnAreaIndex === -1) continue;
                labeledAreasDrawn.splice(drawnAreaIndex, 1);
            }
            setLabeledAreasDrawn(labeledAreasDrawn);
        }

        prevLabeledAreas.current = labeledAreas;
        prevCanvasMeta.current = canvasMeta;
        drawLabeledAreas(labeledAreas);
    }, [labeledAreas, canvasMeta, labeledAreasDrawn]);

    useEffect(() => {
        setLabeledAreas(props.labeledAreas);
    }, [props.labeledAreas]);

    useEffect(() => {
        if (showLabeledAreas) {
            drawLabeledAreas(labeledAreas);
            return;
        }
        setLabeledAreasDrawn([]);
        clearCanvas();
    }, [showLabeledAreas]);
    //#endregion

    //#region event handlers
    function keyDownHandler(evt) {
        console.log('key down', labelingModeActive, points);
        if (evt.keyCode !== 90 || !evt.ctrlKey || !labelingModeActive || !points.length)
            return;

        undoPoint();
    }
    function reesizeWindowHandler(evt) {
        imageLoadedHandler(evt);
        setLabeledAreasDrawn([]);
    }
    function canvasClickHandler(evt) {
        if (labeledAreaPointHovered && !labeledAreaPointMoving) {
            setLabeledAreaPointMoving(labeledAreaPointHovered);
            return;
        }
        if (labeledAreaPointMoving) {
            const { updateLabeledAreaHandler } = props;
            console.log('redraw area');
            const hoveredAreaPoints = [...labeledAreaPointMoving.hoveredArea.points];
            const hoveredAreaId = getLabeledAreaId(labeledAreaPointMoving.hoveredArea);
            canvasHelper.clearSelectedArea(getCanvasContext(), convertToPixels(hoveredAreaPoints), POINT_PIXELS);
            setLabeledAreasDrawn(labeledAreasDrawn.filter(r => r !== hoveredAreaId));
            const pointIndex = hoveredAreaPoints.findIndex(p =>
                p.x === labeledAreaPointMoving.hoveredPoint.x
                && p.y === labeledAreaPointMoving.hoveredPoint.y
            );
            const newPoint = adjustCoordinatesToOriginalSize(evt, evt.target, canvasMeta);
            hoveredAreaPoints.splice(pointIndex, 1, newPoint);
            const labeledArea = { ...labeledAreaPointMoving.hoveredArea, points: hoveredAreaPoints };
            updateLabeledAreaHandler(labeledArea, hoveredAreaId);
            setLabeledAreaPointMoving(null);
            return;
        }
        if (!labelingModeActive || points.length === 4)
            return;

        const point = adjustCoordinatesToOriginalSize(evt, evt.target, canvasMeta);
        const updatedPoints = [...points, point];
        const ctx = getCanvasContext();
        setPoints(updatedPoints);
        canvasHelper.drawArea(ctx, convertToPixels(updatedPoints), LINE_COLOR, POINT_COLOR, POINT_PIXELS);
    }

    function canvasMouseMoveHandler(evt) {
        if (!labelingModeActive) return;
        const point = adjustCoordinatesToOriginalSize(evt, evt.target, canvasMeta);
        const hoveredAreaPoint = getLabeledAreaPointHovered(point);
        console.log(hoveredAreaPoint);
        if (labeledAreaPointHovered === hoveredAreaPoint || labeledAreaPointMoving) return;
        setLabeledAreaPointHovered(hoveredAreaPoint);
    }

    function addLabelClickHandler(evt) {
        if (labelingModeActive) {
            setLabelingModeActive(false);
            canvasHelper.clearSelectedArea(getCanvasContext(), convertToPixels(points));
            restoreDefaultState();
            return;
        }
        setLabelingModeActive(true);
    };

    function labelChangeHandler(evt) {
        const labelKey = evt.target.value;
        const label = labelOptionsMap[labelKey];
        setSelectedLabelKey(labelKey);
        canvasHelper.clearSelectedArea(getCanvasContext(), convertToPixels(points), POINT_PIXELS);
        const area = { points, color: label.Color, label: label.Name, index: labeledAreas.length };
        drawLabeledArea(area);
        const areaId = getLabeledAreaId(area);
        const exists = labeledAreasDrawn.includes(areaId);
        if (exists)
            return;
        setLabeledAreasDrawn([...labeledAreasDrawn, areaId]);
    }

    function imageLoadedHandler(evt) {
        canvasRef.current.style.position = 'absolute';
        canvasRef.current.style.top = `${imgRef.current.offsetTop}px`;
        canvasRef.current.style.left = 0;
        canvasRef.current.style.width = `${imgRef.current.width}px`;
        canvasRef.current.style.height = `${imgRef.current.height}px`;
        if (_.isEqual(prevCanvasMeta.current, getCanvasMeta())) return;
        canvasRef.current.width = imgRef.current.naturalWidth;
        canvasRef.current.height = imgRef.current.naturalHeight;
        setCanvasMeta(getCanvasMeta());
    }

    //#endregion

    //#region canvas
    function getCanvasMeta() {
        const naturalWidth = imgRef.current.naturalWidth;
        const naturalHeight = imgRef.current.naturalHeight;
        const width = +canvasRef.current.style.width.replace(/[^0-9]/ig, '');
        const height = +canvasRef.current.style.height.replace(/[^0-9]/ig, '');
        return { scale: width / naturalWidth, width: naturalWidth, height: naturalHeight };
    }
    function adjustCoordinatesToOriginalSize(evt, canvas, canvasMeta) {
        const { scale, width, height } = canvasMeta;
        const container = canvas.parentNode;
        const offsetTop = container.offsetTop + canvas.offsetTop;
        const canvasRect = canvas.getBoundingClientRect();
        const x = evt.clientX - container.offsetLeft;
        const y = evt.clientY - offsetTop + Math.abs(offsetTop - canvasRect.top);
        return { x: (x / scale / width), y: (y / scale / height) };
    }
    function getCanvasContext() {
        return canvasRef.current.getContext('2d');
    }
    function clearCanvas() {
        const ctx = getCanvasContext();
        ctx.clearRect(0, 0, imgRef.current.naturalWidth, imgRef.current.naturalHeight);
    }
    function getLabeledAreaPointHovered(point) {
        for (let areaIndex = 0; areaIndex < labeledAreas.length; areaIndex++) {
            const labeledArea = labeledAreas[areaIndex];
            for (let pointIndex = 0; pointIndex < labeledArea.points.length; pointIndex++) {
                const areaPoint = labeledArea.points[pointIndex];
                const pointArea = {
                    x1: areaPoint.x - (POINT_PIXELS / 2) / canvasMeta.width,
                    y1: areaPoint.y - (POINT_PIXELS / 2) / canvasMeta.height,
                    x2: areaPoint.x + (POINT_PIXELS / 2) / canvasMeta.width,
                    y2: areaPoint.y + (POINT_PIXELS / 2) / canvasMeta.height,
                }
                if (point.x >= pointArea.x1 && point.x <= pointArea.x2 &&
                    point.y >= pointArea.y1 && point.y <= pointArea.y2
                ) {
                    const hoveredPoint = {
                        hoveredArea: labeledArea,
                        hoveredPoint: areaPoint
                    };
                    console.log(hoveredPoint);
                    return hoveredPoint;
                }
            }
        }
        return null;
    }
    function getCanvasCursor() {
        if (!labelingModeActive) return 'default';
        if (labeledAreaPointMoving) return 'all-scroll';
        if (labeledAreaPointHovered) return 'grab';
        return 'crosshair';
    }
    //#endregion

    //#region drawing
    function convertToPixels(relativePoints) {
        return relativePoints
            .map((p) => ({
                x: p.x * canvasMeta.width,
                y: p.y * canvasMeta.height
            }));
    }
    function drawLabeledArea(labeledArea) {
        console.log('draw area');
        var ctx = getCanvasContext()
        canvasHelper.drawArea(ctx, convertToPixels(labeledArea.points), LINE_COLOR, POINT_COLOR, POINT_PIXELS);
        canvasHelper.drawText(ctx, convertToPixels(labeledArea.points)[0], `${labeledArea.label} [${labeledArea.index}]`, TEXT_FONT, TEXT_COLOR);
        const colorEntry = COLOR_MAP[labeledArea.color.toLowerCase()];
        const [_, r, g, b, a] = /^rgba\((\d+),(\d+),(\d+),(\d+)\)/.exec(colorEntry.rgba)
        canvasHelper.fillSelectedArea(ctx, convertToPixels(labeledArea.points), `rgba(${r},${g},${b},0.35)`);
    }
    function drawLabeledAreas(labeledAreas) {
        for (let index = 0; index < labeledAreas.length; index++) {
            const labeledArea = labeledAreas[index];
            const areaId = getLabeledAreaId(labeledArea);
            console.log(labeledAreasDrawn, areaId);
            if (labeledAreasDrawn.includes(areaId)) {
                console.log(`${areaId} has been drawn. skipping...`)
                continue;
            }
            drawLabeledArea(labeledArea);
        }
        setLabeledAreasDrawn(labeledAreas.map((_) => getLabeledAreaId(_)));
    }

    //#endregion
    function restoreDefaultState() {
        setPoints([]);
        setSelectedLabelKey('');
        setLabelingModeActive(false);
    }
    function saveLabel(labeledArea) {
        const { addLabeledAreaHandler } = props;
        restoreDefaultState();
        addLabeledAreaHandler(labeledArea);
    }
    function undoPoint() {
        console.log('undo point');
        const _points = points.slice();
        _points.splice(-1, 1);
        const ctx = getCanvasContext();
        canvasHelper.clearSelectedArea(ctx, convertToPixels(points));
        canvasHelper.drawArea(ctx, convertToPixels(_points), LINE_COLOR, POINT_COLOR, POINT_PIXELS);
        setPoints(_points);
    }
    return <>
        <canvas
            tabIndex={0}
            style={{ cursor: getCanvasCursor() }}
            ref={r => canvasRef.current = r}
            onClick={canvasClickHandler}
            onMouseMove={_.debounce(canvasMouseMoveHandler, 25)}
            onKeyDownCapture={keyDownHandler}
        />
        {showLabeledAreas ?
            <Box sx={[
                { position: 'sticky', top: 0, padding: '0.4rem' },
                labelingModeActive && { boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.2)', backgroundColor: 'white' },
            ]}>
                <Fab
                    color="primary"
                    aria-label="edit"
                    disabled={false}
                    variant="customRounded"
                    size="small"
                    onClick={addLabelClickHandler}
                    sx={{ mr: '1rem' }}
                >
                    {labelingModeActive
                        ? <CancelIcon />
                        : <EditIcon />}
                </Fab>
                {labelingModeActive
                    ? <>
                        <label>Points: {points.length} of 4 selected</label>
                        {points.length === 4
                            ? <>
                                <FormControl sx={{ width: '14rem', ml: '1rem' }} size="small">
                                    <InputLabel id="color-select-label">Label</InputLabel>
                                    <Select
                                        size="small"
                                        value={labelKey}
                                        label="Label"
                                        onChange={labelChangeHandler}
                                    >
                                        {[<MenuItem key={`label-option-key-default`} value={''}><pre /></MenuItem>,
                                        ...labelOptions
                                            .map((label) => <MenuItem key={`label-option-key-${label.Id}`} value={label.Id}>
                                                {label.Name}
                                            </MenuItem>)]}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant='customRounded'
                                    sx={{ ml: "1rem" }}
                                    onClick={() => {
                                        saveLabel({
                                            points,
                                            label: labelOptionsMap[labelKey].Name,
                                            color: labelOptionsMap[labelKey].Color,
                                            labelKey: +labelKey
                                        });
                                    }} disabled={!labelKey}>Save</Button>
                            </>
                            : null}
                    </>
                    : null}
            </Box>
            : null}
    </>;
}
LabelingModule.propTypes = {
    imgRef: PropTypes.shape({ 'current': PropTypes.object }).isRequired,
    showLabeledAreas: PropTypes.bool.isRequired,
    labeledAreas: PropTypes.array.isRequired,
    labelOptions: PropTypes.array.isRequired,
    addLabeledAreaHandler: PropTypes.func.isRequired,
    updateLabeledAreaHandler: PropTypes.func.isRequired
};