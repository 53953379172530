import * as React from 'react';
import {Autocomplete, Box, TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _ from "lodash";


function DocumentFilter({
                            documentPackageTypesOptions,
                            companiesOptions,
                            getOptionalLabel,
                            isOptionEqualToValue,
                            documentPackageTypesLabel,
                            onChange,
                            onChangePackageType,
                            onDateFromChange,
                            onToDateChange,
                            onChangeLoanFk,
                            valuePackageType,
                            valueCompany,
                            value,
                            disabled
                        }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Box sx={{ marginLeft: 'auto', maxWidth: '10rem' }}>
                <TextField
                    id="outlined-basic"
                    label="Loan Number"
                    variant="outlined"
                    value={value.LoanFk || value.SimpleId || ''}
                    onChange={onChangeLoanFk}
                    size="small"
                    disabled={disabled}
                />
            </Box>
            <Box sx={{ minWidth: '10rem' }}>
                <Autocomplete
                    key="document-package-types-autocomplete"
                    size="small"
                    multiple
                    options={documentPackageTypesOptions}
                    disableCloseOnSelect
                    getOptionLabel={getOptionalLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={valuePackageType}
                    renderInput={(params) => (
                        <TextField {...params} label={documentPackageTypesLabel} variant="outlined"/>
                    )}
                    onChange={onChangePackageType}
                    disabled={disabled}
                /></Box>
            <Box sx={{minWidth: '15rem'}}>
                <Autocomplete
                    key="companies-autocomplete"
                    size="small"
                    options={companiesOptions}
                    getOptionLabel={getOptionalLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={valueCompany}
                    renderInput={(params) => (
                        <TextField {...params} label={"Companies"} variant="outlined"/>
                    )}
                    groupBy={(option) => option.Name}
                    onChange={onChange}
                    disabled={disabled}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{maxWidth: '11rem'}}
                        key="from-date-picker"
                        label="Created From"
                        slotProps={{textField: {size: 'small'}}}
                        value={dayjs(_.get(value, 'CreatedFrom', null))}
                        onChange={(date) => onDateFromChange(_.isNil(date) ? null : dayjs(date, 'YYYY-MM-DD'))}
                        disabled={disabled}
                    />
                    <DatePicker
                        sx={{marginLeft: '0.5rem', maxWidth: '11rem'}}
                        key="to-date-picker"
                        label="Created To"
                        slotProps={{textField: {size: 'small'}}}
                        value={value && value.CreatedTo ? dayjs(value.CreatedTo, 'YYYY-MM-DD') : null}
                        onChange={(date) => onToDateChange(_.isNil(date) ? null : dayjs(date, 'YYYY-MM-DD'))}
                        disabled={disabled}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
}

export default DocumentFilter;
