import React, { useState } from 'react';
import {
    Box, Typography, TextField, Select, MenuItem, 
    InputLabel, FormControl, Autocomplete, Tooltip, Checkbox, FormLabel,
    RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import _ from 'lodash';
import { LabedledAreaList } from './LabeledAreaList';
import { EntityList } from './EntityList';

const debounceFunction = _.debounce((func) => {
    func();
}, 500);

export function SampleDetailsEditor(props) {
    const {
        pageLabeledAreasMap,
        labelOptions,
        selectedDocumentType,
        selectedFileDocumentType,
        entityList,
        entityTypes,
        updateDataSample,
        addNewEntity,
        updateEntity,
        deleteEntity,
        documentTypes,
        setSelectedFileDocumentType,
        selectedDocumentIndex,
        setSelectedDocumentType,
        setSelectedFileFilter,
        fileFilter,
        isFromFile,
        removeLabeledArea,
        currentSample,
        setCurrentSample,
        setEntityList,
        showLabeledAreasClickHandler,
        showLabeledAreas,
        sampleId,
        generatedSampleName
    } = props;

    const [sampleName, setSampleName] = useState(generatedSampleName);
    const isNewSample = sampleId === 'new';
    const [showEntityList, setShowEntityList] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const isPopoverOpen = Boolean(anchorEl);
    const popoverId = isPopoverOpen ? 'simple-popover' : undefined;

    //#region Functions
    const createChanges = (field, value) => {
        const updateHandlers = {
            Name: () => ({ [field]: value }),
            ConfidenceLevel: () => {
                const roundedValue = Math.round(+value) || 10;
                return {
                    [field]: Math.min(Math.max(roundedValue, 10), 100)
                };
            },
            IsSeveralDocsPerPage: () => ({ [field]: value }),
            DocumentTypeFK: () => {
                const sampleNewName = `${value?.Name.replace(/\s/g, '')}_${selectedDocumentIndex}`;
                setSampleName(sampleNewName);
                return {
                    DocumentTypeFK: value?.Id,
                    Name: value ? sampleNewName : null
                };
            },
            Status: () => ({ [field]: value }),
        };
    
        return updateHandlers[field] ? updateHandlers[field]() : {};
    };
    
    const handleChange = (field, value) => {
        const updates = createChanges(field, value);
        debounceFunction(() => {
            const updatedSample = { ...currentSample, ...updates };
            setCurrentSample(updatedSample);
            if (!isNewSample) {
                updateDataSample({
                    sampleId,
                    updates: Object.entries(updates).map(([key, val]) => ({ path: key, value: val }))
                });
            }
        });
    };
    //#endregion Functions

    const nonPrintableDocumentTypes = documentTypes.filter(type => !type.IsPrintable);
    const printableDocumentTypes = documentTypes.filter(type => type.IsPrintable);
    const handlePrintableCheckboxChange = (event) => {
        setSelectedFileFilter({ IsPrintable: event.target.checked });
    };
    return (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: 'auto' }}>
        <Box sx={{ display: 'block', flexDirection: 'row', alignItems: 'center', width: '20rem' }}>
            <Autocomplete
                disableClearable
                disabled={currentSample?.IsSampleSubmitted || currentSample?.IsSubmitted}
                key="document-types-autocomplete"
                size="small"
                sx={{ width: '100%', marginRight: '1rem' }}
                options={fileFilter.IsPrintable ? printableDocumentTypes : nonPrintableDocumentTypes || null}
                value={isFromFile ? selectedFileDocumentType : selectedDocumentType}
                getOptionLabel={(option) => _.get(option, 'Name', '')}
                renderInput={(params) => (
                    <TextField {...params} key={params.id} label="Document Types" variant="outlined" />
                )}
                groupBy={(option) => option.Name}
                onChange={(event, value) => {
                    isFromFile ? setSelectedFileDocumentType(value) : setSelectedDocumentType(value);
                    handleChange('DocumentTypeFK', value);
                }}
            />
            {_.isEmpty(selectedDocumentType) && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Printable</Typography>
                    <Tooltip title="Printable document types">
                        <Checkbox
                            size="small"
                            checked={fileFilter?.IsPrintable === true}
                            onChange={handlePrintableCheckboxChange}
                        />
                    </Tooltip>
                </Box>
            )}
        </Box>
        <TextField
            sx={{ width: '20rem' }}
            id="outlined-basic"
            label="Sample name"
            variant="outlined"
            value={currentSample?.Name || sampleName || ''}
            onChange={(e) => {
                const newValue = e.target.value;
                setSampleName(newValue);
                handleChange('Name', newValue);
            }}
            size="small"
            InputLabelProps={{
                shrink: !!(currentSample?.Name || generatedSampleName),
            }}
            inputProps={{
                max: 100,
                min: 0,
                step: 10,
            }}
        />
        <FormControl sx={{ width: '20rem' }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
                size="small"
                value={currentSample?.Status || 'Identified'}
                label="Status"
                onChange={(e) => handleChange('Status', e.target.value)}
            >
                <MenuItem value={'Identified'}>Identified</MenuItem>
                <MenuItem value={'Validated'}>Validated</MenuItem>
                <MenuItem value={'Rejected'}>Rejected</MenuItem>
            </Select>
        </FormControl>
        <FormControlLabel id="several-docs-form"
            control={
                <Checkbox
                    checked={currentSample?.IsSeveralDocsPerPage || false}
                    onChange={(e) => handleChange('IsSeveralDocsPerPage', e.target.checked)}
                />}
            label="Sample contains several documents per page" />
        <FormControl>
            <FormLabel id="confidence-level-form">Confidence Level</FormLabel>
            <RadioGroup
                row
                aria-labelledby="confidence-level-form"
                name="confidence-level-form"
                value={currentSample?.ConfidenceLevel || 10}
                onChange={(e) => handleChange('ConfidenceLevel', e.target.value)}
            >
                <FormControlLabel value={10} control={<Radio />} label="low" />
                <FormControlLabel value={50} control={<Radio />} label="mid" />
                <FormControlLabel value={100} control={<Radio />} label="high" />
            </RadioGroup>
        </FormControl>
        <EntityList {...{
            entityTypes, entityList,
            currentSample,
            debounceFunction,
            sampleId,
            isNewSample,
            popoverId,
            isPopoverOpen,
            anchorEl,
            setAnchorEl,
            addNewEntity,
            updateEntity,
            deleteEntity,
            showEntityList,
            setCurrentSample,
            setEntityList 
        }} />
        <LabedledAreaList {...{
            removeLabeledAreaHandler: ({ labeledAreaId, sampleId, pageId}) => removeLabeledArea({ labeledAreaId, sampleId, pageId }),
            labelOptions,
            pageLabeledAreasMap,
            showLabeledAreas,
            showLabeledAreasClickHandler
        }} />
    </Box>
    );
}
