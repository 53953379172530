import {configureStore} from '@reduxjs/toolkit';
import rootSlice from '../Slices/RootSlice';
import documentSlice from '../../../Pages/Documents/Api/slice';
import jobsSlice from '../../../Pages/Documents/Ui/Jobs/JobsSlice';

export const documentSlice_KEYS = {
    SELECTED_PAGES: 'selectedPages'
};
const DEFAULT_STATE = {
    [documentSlice_KEYS.SELECTED_PAGES]: []
};
function ConfigureStore() {
    return configureStore({
        key: documentSlice_KEYS.PAGES,
        reducer: {
            [documentSlice_KEYS.SELECTED_PAGES]: DEFAULT_STATE[documentSlice_KEYS.SELECTED_PAGES],
            [rootSlice.name]: rootSlice.reducer,
            [documentSlice.name]: documentSlice.reducer,
            [jobsSlice.name]: jobsSlice.reducer,
        }
    });
}

export default ConfigureStore;
