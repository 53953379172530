import React, { useState, useEffect } from 'react';
import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography, Button } from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import ClearIcon from '@mui/icons-material/Clear';
import _ from "lodash";
import Modal from '@mui/material/Modal';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CloseIcon from '@mui/icons-material/Close';

function DocumentImageList({ images, propertyMap, handleImageSelection,
    sharedCurrentPages, sharedIdentifyPages,
    isImageSelected, keyMap, pageNumberMap, includeIdentifiedPage,
    identifyDocumentPage, documentFK, hideControlButtons
}) {

    const [modalImageRotation, setModalImageRotation] = useState(0);
    const [selectedImage, showScaleImage] = useState(null);
    const [deletedImage, showDeleteModal] = useState(null);

    const isImageIdentifyOrSelectedByOther = (image) => {
        return image.IsIdentified
            || (sharedCurrentPages || []).includes(image.ScannedPageId)
            || (sharedIdentifyPages || []).includes(image.ScannedPageId)
    }

    const filteredImages = includeIdentifiedPage
        ? images
        : images.filter(image => !isImageIdentifyOrSelectedByOther(image));

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (selectedImage && (event.which === 8 || event.which === 27)) {
                closeImageModal();
                return;
            };

            if (selectedImage && (event.which === 65 || event.which === 37)) {
                onBackButtonClick();
                return;
            };

            if (selectedImage && (event.which === 68 || event.which === 39)) {
                onForwardButtonClick();
                return;
            };

            if (selectedImage && (event.which === 13 || event.which === 32)) {
                handleImageSelection(selectedImage);
                return;
            };
        };

        document.addEventListener('keyup', handleKeyDown);

        return () => {
            document.removeEventListener('keyup', handleKeyDown);
        };
    }, [selectedImage, filteredImages, sharedCurrentPages, sharedIdentifyPages]);

    const openImageModal = (image) => {
        showScaleImage(image);
    }

    const onForwardButtonClick = () => {
        const imgIndex = filteredImages.indexOf(selectedImage);
        showScaleImage(imgIndex === filteredImages.length - 1 ? filteredImages[0] : filteredImages[imgIndex + 1])
        setModalImageRotation(0);
    }

    const onBackButtonClick = () => {
        const imgIndex = filteredImages.indexOf(selectedImage);
        showScaleImage(imgIndex === 0 ? filteredImages[filteredImages.length - 1] : filteredImages[imgIndex - 1])
        setModalImageRotation(0);
    }

    const closeImageModal = () => {
        showScaleImage(null);
        setModalImageRotation(0);
    }

    const rotateImageRight = () => {
        setModalImageRotation(modalImageRotation + 90);
    }

    const rotateImageLeft = () => {
        setModalImageRotation(modalImageRotation - 90);
    }

    const openDeleteModal = (deletedImage) => {
        showDeleteModal(deletedImage);
    }

    const closeDeleteModal = () => {
        showDeleteModal(null);
    }

    const handleDeleteButtonClick = (deletedImage) => {
        if (!deletedImage) return;
        const pageIdentification = {
            IdentifiedPageId: deletedImage?.ScannedPageId,
            DocumentFK: documentFK,
            AiDataSampleId: null
        };

        identifyDocumentPage(pageIdentification);
        showDeleteModal(null);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };

    return (
        <Box>
            {selectedImage && (
                <Modal
                    open={selectedImage !== null}
                >
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'auto',
                        position: 'relative'
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', gap: '1rem', }}>
                            <IconButton sx={{ mr: '1rem' }} onMouseDown={onBackButtonClick}>
                                <ArrowBackIosNewIcon fontSize="large" sx={{ transform: 'translateX(-0.1rem)' }} />
                            </IconButton>
                            <IconButton onMouseDown={rotateImageLeft} sx={{ mr: '1rem' }}>
                                <RotateRightIcon fontSize="large" />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            maxWidth: '100dvw',
                            maxHeight: '98dvh',
                            display: 'flex',
                            justifyContent: 'center',
                            overflow: 'hidden',
                        }}>
                            <img
                                onMouseDown={closeImageModal}
                                style={{
                                    objectFit: 'contain',
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    transform: `rotate(${modalImageRotation}deg)`,
                                }}
                                loading="lazy"
                                src={selectedImage[propertyMap]}
                                alt={selectedImage[keyMap]}
                            />
                        </Box>
                        <ImageListItemBar
                            sx={{
                                objectFit: 'contain',
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }}
                            title={`Page ${_.isNil(pageNumberMap) ? filteredImages.indexOf(selectedImage) + 1 : selectedImage[pageNumberMap]}`}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.8)' }}
                                    onMouseDown={() => handleImageSelection(selectedImage)}
                                >
                                    {_.isFunction(isImageSelected) && isImageSelected(selectedImage)
                                        ? <CheckBoxOutlinedIcon />
                                        : <CheckBoxOutlineBlankOutlinedIcon />}
                                </IconButton>
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', gap: '1rem' }}>
                            <IconButton sx={{ position: 'fixed', top: '1rem', ml: '1rem' }} onMouseDown={closeImageModal}>
                                <CloseIcon fontSize="large" sx={{ color: '#343333' }} />
                            </IconButton>
                            <IconButton sx={{ ml: '1rem' }} onMouseDown={onForwardButtonClick}>
                                <ArrowForwardIosIcon fontSize="large" sx={{ transform: 'translateX(0.1rem)' }} />
                            </IconButton>
                            <IconButton onMouseDown={rotateImageRight} sx={{ ml: '1rem' }}>
                                <RotateLeftIcon fontSize="large" />
                            </IconButton>
                        </Box>
                        {isImageIdentifyOrSelectedByOther(selectedImage) && (
                            <Box style={{
                                position: 'absolute',
                                writingMode: 'vertical-lr',
                                textOrientation: 'mixed',
                                transform: 'rotate(225deg)',
                                color: 'grey',
                                fontSize: '7em',
                                opacity: 0.25,
                                letterSpacing: '0.1em'
                            }}>
                                Identified
                            </Box>
                        )}
                    </Box>
                </Modal>
            )}
            <ImageList sx={{ width: '100%', maxHeight: 'calc(100dvh - 260px)' }}
                cols={3}
                rowHeight={"auto"}>
                {filteredImages.map((image, index) => (
                    <ImageListItem key={`image-${image[keyMap]}`} sx={{ minHeight: '5rem', opacity: isImageIdentifyOrSelectedByOther(image) ? 0.4 : 1 }}>
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            {!hideControlButtons && !image.IsIdentified
                                ? <IconButton onClick={() => openDeleteModal(image)} aria-label="delete" size="medium">
                                    <ClearIcon color="error" />
                                </IconButton>
                                : null}
                        </div>
                        {deletedImage && (
                            <Modal open={deletedImage !== null}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Are you sure you want to delete this page?
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between" mt={2}>
                                        <Button variant="customRoundedTextDanger" color="error"
                                            onClick={() => handleDeleteButtonClick(deletedImage)}
                                            style={{ marginRight: '1rem' }}>
                                            Yes, Delete
                                        </Button>
                                        <Button variant="customRoundedText" color="secondary"
                                            onClick={closeDeleteModal}>
                                            No
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>

                        )}
                        <img
                            loading="lazy"
                            src={image[propertyMap]}
                            alt={image[keyMap]}
                            onClick={() => openImageModal(image)}
                        />
                        <ImageListItemBar
                            title={`Page ${_.isNil(pageNumberMap) ? index + 1 : image[pageNumberMap]}`}
                            actionIcon={!hideControlButtons && !isImageIdentifyOrSelectedByOther(image)
                                ? <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.8)' }}
                                    onClick={() => handleImageSelection(image)}
                                >
                                    {_.isFunction(isImageSelected) && isImageSelected(image)
                                        ? <CheckBoxOutlinedIcon />
                                        : <CheckBoxOutlineBlankOutlinedIcon />}
                                </IconButton>
                                : null}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}


export default DocumentImageList;
