import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as JobThunks from './JobsThunk';
import * as JobSelectors from './selectors';
import * as SliceJob from './JobsSlice.js'
import { Box, Button } from '@mui/material';
import TableComponent from '../../../../Shared/Components/TableComponent';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

function _JobsList(props) {
    const {
        rows,
        fetchJobs,
        setShowCreate,
        startJob,
        resetJob,
        stopJob,
        cancelJob,
        setSelectedJob
    } = props;

    useEffect(() => {
        fetchJobs();
    }, [])
    let navigate = useNavigate();
    // Created = 0,
    // Pending = 1,
    // ExtractingInProgress = 2,
    // Extracted = 3,
    // ClassifyingInProgress = 4,
    // Cancelled = 5,
    // Error = 6,
    // Completed = 7,

    const rowsWithActions = rows.map((row) => {
        const { Status, Id } = row;
        return ({
            ...row,
            Actions: <Box>
                {['Created', 'Cancelled', 'Completed'].includes(Status)
                    ? <Button onClick={() => startJob(Id)}>
                        Start
                    </Button>
                    : null}
                {
                    Status === 'Error' 
                    ? <Button onClick={() => resetJob(Id)}>
                        Reset
                    </Button>
                    : null
                }
                {['Pending', 'ExtractingInProgress', 'Extracted', 'ClassifyingInProgress'].includes(Status)
                    ? <Fragment>
                        <Button onClick={() => stopJob(Id)}>
                            Stop
                        </Button>
                        <Button onClick={() => cancelJob(Id)}>
                            Cancel
                        </Button>
                    </Fragment>
                    : null}
            </Box>,
            GoToValidate: <Box>
                {Status === 'Completed'
                ? <Button onClick={() => {
                        setSelectedJob({ Id:Id, Status: Status });
                        navigate(`/jobs/validation/${Id}`);
                    }}>
                        Validate
                    </Button>
                : <Button
                    variant="customRoundedText"
                    size="small"
                    startIcon={<VisibilityOutlinedIcon />}
                    onClick={() => {
                        setSelectedJob({ Id:Id });
                        navigate(`/jobs/validation/${Id}`);
                    }}
                >
                    Preview
                </Button>}
        </Box>
        })
    })

    return (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <Button sx={{ width: '200px' }} variant='customRounded' onClick={() => {
            console.log('typeof(setShowCreate)', typeof (setShowCreate))
            if (_.isFunction(setShowCreate)) {
                setShowCreate(true)
            }
        }}>
            Create New
        </Button>
        <TableComponent
            columns={columns}
            rows={rowsWithActions}
            enableTablePagination={false}
            handleSelectedRow={_.noop}
            handleRowItemClick={_.noop}
        />
    </Box>)
}

const mapStateToProps = (state, ownProps) => {
    const jobs = JobSelectors.getJobs(state);
    const jobsArr = jobs.map((job) => {
        const { Created, Started, Finished } = job;
        const CreatedDate = new Date(Created).toLocaleDateString();
        const StartedDate = Started ? new Date(Started).toLocaleDateString() : '';
        const FinishedTimeMs = _.isNil(Finished) ? null : new Date(Finished) - new Date(Started);
        const FinishedTimeMins = FinishedTimeMs ? (FinishedTimeMs / (1000 * 60)).toFixed(2) : '';
        return ({
            ...job,
            CreatedDate,
            StartedDate,
            FinishedTime: FinishedTimeMins,
        })
    })
    const rows = _.orderBy(jobsArr, ['Created'], ['desc'])
    return {
        rows,
    }
}

const mapDispatchToProps = {
    fetchJobs: JobThunks.fetchJobs,
    startJob: JobThunks.startJob,
    resetJob: JobThunks.resetJob,
    stopJob: JobThunks.stopJob,
    cancelJob: JobThunks.cancelJob,
    setSelectedJob: SliceJob.setSelectedJob,
}

export const JobsList = connect(mapStateToProps, mapDispatchToProps)(_JobsList);

const columns = [
    {
        name: 'Id',
        value: 'Id',
        filterable: false,
    },
    {
        name: 'Status',
        value: 'Status',
        filterable: true,
        filter: (x, value) => (x.Status || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Description',
        value: 'Description',
        filterable: true,
        filter: (x, value) => (x.Description || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Created',
        value: 'CreatedDate',
        filterable: true,
        filter: (x, value) => (x.Created || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Started',
        value: 'StartedDate',
        filterable: true,
        filter: (x, value) => (x.Created || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Finished (mins)',
        value: 'FinishedTime',
        filterable: true,
        filter: (x, value) => (x.Created || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Classifier',
        value: 'ProcessorFk',
        filterable: true,
        filter: (x, value) => x.ProcessorFk.toString().includes(value?.toString()),
    },
    {
        name: 'Actual',
        value: 'Actual',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Actual) && x.Actual.toString().includes(value?.toString()),
    },
    {
        name: 'Recognized',
        value: 'Recognized',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Recognized) && x.Recognized.toString().includes(value?.toString()),
    },
    {
        name: 'Not Recognized',
        value: 'NotRecognized',
        filterable: true,
        filter: (x, value) => !_.isNil(x.NotRecognized) && x.NotRecognized.toString().includes(value?.toString()),
    },
    {
        name: 'Incorrect',
        value: 'Incorrect',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Incorrect) && x.Incorrect.toString().includes(value?.toString()),
    },
    {
        name: 'Accuracy',
        value: 'Accuracy',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Accuracy) && x.Accuracy.toString().includes(value?.toString()),
    },
    {
        align: 'left',
        name: 'Actions',
        value: 'Actions',
    },
    {
        align: 'left',
        name: 'Validate',
        value: 'GoToValidate',
    }
]
