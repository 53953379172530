import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    setDocumentTypes,
    setDocuments,
    setFiles,
    setClassifiers,
    removeDocumentClassifier,
    setError,
    setLoading,
    setRecognizers,
    setDataSetProcessed,
    setSelectedFile,
    setAiProcessingResult,
    setEntityTypes,
    setSelectedDocument, setDataSamples, removeDataSample, setDataSets,
    addSelectedDataSamples,
    setDataSamplesView,
    updateDocumentClassifier,
    removeEntityType,
    removeDataSet,
    setFilteredCompanies,
    addEntityType,
    setClassifierProcessed,
    setDataSamplesStatistics,
    updateEntityRecognizer,
    setEntityList,
    setLabels,
    addLabel,
    removeLabel,
    setSelectedLabel,
    setCurrentSample
} from './slice';
import {
    dataSampleProxy,
    dataSetProxy,
    documentProxy,
    documentTypeProxy,
    classifierProxy,
    fileProxy,
    recognizerProxy, 
    identificationProxy
} from '../../../Common/CoreComponents/AjaxProxy';
import _ from 'lodash';
import { getLabeledAreaDto, getLabeledAreaId, getLabeledAreaView } from 'Common/CoreComponents/constants';

const apiTypes = {
    removeClassifier: 'removeClassifier',
    resumeDataSetCreating: 'resumeDataSetCreating',
    fetchDocumentTypes: 'fetchDocumentTypes',
    fetchFilteredCompanies: 'fetchFilteredCompanies',
    deleteEntityType: 'deleteEntityType',
    fetchEntityTypes: 'fetchEntityTypes',
    createEntityType: 'createEntityType',
    resumeClassifierTraining: 'resumeClassifierTraining',
    fetchDocuments: 'fetchDocuments',
    fetchDocumentPreview: 'fetchDocumentPreview',
    fetchDataSamplesView: 'fetchDataSamplesView',
    selectAllDataSamples: 'selectAllDataSamples',
    getDataSamplePreview: 'getDataSamplePreview',
    fetchDataSamplesWithFilter: 'fetchDataSamplesWithFilter',
    fetchDataSampleCreationStatistics: 'fetchDataSampleCreationStatistics',
    fetchClassifiers: 'fetchClassifiers',
    saveDataSample: 'saveDataSample',
    updateDataSample: 'updateDataSample',
    addNewEntity: 'addNewEntity',
    updateEntity: 'updateEntity',
    deleteEntity:'deleteEntity',
    createLabeledArea: 'createLabeledArea',
    removeLabeledArea: 'removeLabeledArea',
    updateLabeledArea: 'updateLabeledArea',
    deleteDataSample: 'deleteDataSample',
    deleteDataSet: 'deleteDataSet',
    fetchDataSets: 'fetchDataSets',
    saveDataSet: 'saveDataSet',
    classifyDocument: 'classifyDocument',
    submitTrainClassifier: 'submitTrainClassifier',
    updateClassifier: 'updateClassifier',
    updateRecognizer: 'updateRecognizer',
    fetchRecognizers: 'fetchRecognizers',
    fetchFiles: 'fetchFiles',
    recognizeDocumentEntities: 'recognizeDocumentEntities',
    deleteImage: 'deleteImage',
    createLabel: 'createLabel',
    fetchLabels: 'fetchLabels',
    deleteLabel: 'deleteLabel',
    updateLabel: 'updateLabel'
}
export const fetchDocumentTypes = createAsyncThunk(apiTypes.fetchDocumentTypes,
    async (_, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await documentTypeProxy.getDocumentTypes();
            dispatch(setDocumentTypes(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const fetchFilteredCompanies = createAsyncThunk(apiTypes.fetchFilteredCompanies,
    async (_, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await documentProxy.getFilteredCompanies();
            dispatch(setFilteredCompanies(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const fetchFiles = createAsyncThunk(apiTypes.fetchFiles,
    async (filter, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await fileProxy.getFiles(filter);
            dispatch(setFiles(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });   

export const fetchDocuments = createAsyncThunk(apiTypes.fetchDocuments,
    async (filter, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await documentProxy.getDocuments(filter);
            // TODO: get rid from this temporary fix
            dispatch(setDocuments(_.isEmpty(response) ? [] : response)); 
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

    export const fetchDocumentPreview = createAsyncThunk(
        apiTypes.fetchDocumentPreview,
        async ({ documentFK, isFile }, { dispatch, getState }) => {
            dispatch(setLoading(true));
            try {
                const response = await documentProxy.getDocumentPreview(documentFK);
                const obj = { DocumentFK: documentFK, ScannedPages: response };
                isFile ? dispatch(setSelectedFile(obj)) : dispatch(setSelectedDocument(obj));
            } catch (exception) {
                dispatch(setError(exception.message));
            } finally {
                dispatch(setLoading(false));
            }
        }
    );

export const selectAllDataSamples = createAsyncThunk(apiTypes.selectAllDataSamples,
    async (docTypeFk, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.selectAllDataSamples(docTypeFk);
            dispatch(addSelectedDataSamples(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);
export const getDataSamplePreview = createAsyncThunk(apiTypes.getDataSamplePreview,
    async (sampleId, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.getDataSamplePreview(sampleId);
            dispatch(setCurrentSample(response));
            return response;
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const fetchDataSamplesView = createAsyncThunk(apiTypes.fetchDataSamplesView,
    async (filter, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.getDataSamplesView(filter);
            dispatch(setDataSamplesView(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const fetchDataSampleCreationStatistics = createAsyncThunk(apiTypes.fetchDataSampleCreationStatistics,
    async (_, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.getDataSampleStatistics();
            dispatch(setDataSamplesStatistics(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);


export const fetchDataSamplesWithFilter = createAsyncThunk(apiTypes.fetchDataSamplesWithFilter,
    async (filter, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.getDataSamplesWithFilter(filter);
            dispatch(setDataSamples(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);
export const fetchClassifiers = createAsyncThunk(apiTypes.fetchClassifiers,
    async (_, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await classifierProxy.getClassifiers();
            dispatch(setClassifiers(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);
export const fetchRecognizers = createAsyncThunk(apiTypes.fetchRecognizers,
    async (_, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.getRecognizers();
            dispatch(setRecognizers(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const fetchEntityTypes = createAsyncThunk(apiTypes.fetchEntityTypes,
    async (_, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.getEntityTypes();
            dispatch(setEntityTypes(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const createEntityType = createAsyncThunk(apiTypes.createEntityType,
    async (entityType, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.addEntityType(entityType);
            if (response.error){
                dispatch(setError(response.error));
                return;
            }
            dispatch(addEntityType(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const createLabel = createAsyncThunk(apiTypes.createLabel,
    async (label, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.addLabel(label);
            if (response.error){
                dispatch(setError(response.error));
                return;
            }
            dispatch(addLabel(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const fetchLabels = createAsyncThunk(apiTypes.fetchLabels,
    async (_, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.getLabels();
            dispatch(setLabels(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);
export const addLabeledArea = createAsyncThunk(
    apiTypes.createLabeledArea,
    async ({ labeledArea, sampleId, pageId }, { dispatch, getState }) => {
        const currentSample = getState().documents.currentSample;
        const labelOptions = getState().documents.labels;
        if (currentSample?.Id === 'new') {
            const labeledAreas = _.cloneDeep(currentSample.LabeledAreas);
            labeledAreas[pageId].push(getLabeledAreaDto(labeledArea, labelOptions));
            dispatch(setCurrentSample({ ...currentSample, LabeledAreas: labeledAreas }));
            return;
        }
       
        dispatch(setLoading(true));
        try {
            const pageLabeledAreas = currentSample.LabeledAreas[pageId];
            const subpath = pageLabeledAreas ? `${pageId}/-` : pageId;
            const data = {
                AreaPoints: labeledArea.points,
                LabelFk: labeledArea.labelKey
            };
            const response = await dataSampleProxy.updateDataSample(sampleId,
                [{
                    op: 'add',
                    path: `LabeledAreas/${subpath}`,
                    value: pageLabeledAreas ? data : [data]
                }]);
            dispatch(setCurrentSample(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
)
export const removeLabeledArea = createAsyncThunk(
    apiTypes.removeLabeledArea,
    async ({ labeledAreaId, sampleId, pageId }, { dispatch, getState }) => {
        const currentSample = getState().documents.currentSample;
        const labelOptions = getState().documents.labels;
        const labeledAreaIndex = currentSample.LabeledAreas[pageId]
            .findIndex((area) => getLabeledAreaId(getLabeledAreaView(area)) === labeledAreaId);
        if (currentSample?.Id === 'new') {
            const labeledAreas = _.cloneDeep(currentSample.LabeledAreas);
            labeledAreas[pageId].splice(labeledAreaIndex, 1);
            dispatch(setCurrentSample({ ...currentSample, LabeledAreas: labeledAreas }));
            return;
        }
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.updateDataSample(currentSample?.Id,
                [{
                    op: 'remove',
                    path: `LabeledAreas/${`${pageId}/${labeledAreaIndex}`}`
                }]);
                dispatch(setCurrentSample(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
)
export const updateLabeledArea = createAsyncThunk(
    apiTypes.updateLabeledArea,
    async ({ labeledAreaId, labeledArea, sampleId, pageId }, { dispatch, getState }) => {
        const currentSample = getState().documents.currentSample;
        const labelOptions = getState().documents.labels;
        const labeledAreaIndex = currentSample.LabeledAreas[pageId]
            .findIndex((area) => getLabeledAreaId(getLabeledAreaView(area)) === labeledAreaId);
        const labeledAreaDto =  getLabeledAreaDto(labeledArea, labelOptions);
        if (currentSample?.Id === 'new') {
            const pageAreas = [...currentSample.LabeledAreas[pageId]];
            pageAreas.splice(labeledAreaIndex, 1, labeledAreaDto);
            dispatch(setCurrentSample({
                ...currentSample,
                LabeledAreas: {
                    ...currentSample.LabeledAreas,
                    [pageId]: pageAreas
                }
            }));
            return;
        }
        dispatch(setLoading(true));
        try {
            const labeledAreaDto = getLabeledAreaDto(labeledArea, labelOptions);
            const response = await dataSampleProxy.updateDataSample(sampleId,
                [{
                    op: 'replace',
                    path: `LabeledAreas/${`${pageId}/${labeledAreaIndex}`}`,
                    value: labeledAreaDto
                }]);
            dispatch(setCurrentSample(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
)
export const updateLabel = createAsyncThunk(apiTypes.updateLabel,
    async ({labelId, patchArray, labels}, {dispatch, getState}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.updateLabel(labelId, patchArray);
            dispatch(setSelectedLabel({ name: response.Name, Id: response.Id, Color: response.Color }));
            const updatedLabels = labels.map(label => label.Id === response.Id 
                ? { ...label, ...response } 
                : label);
            dispatch(setLabels(updatedLabels)); 
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const deleteLabel = createAsyncThunk(apiTypes.deleteLabel,
    async (labelId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.deleteLabel(labelId);
            dispatch(removeLabel(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const saveDataSample = createAsyncThunk(apiTypes.saveDataSample,
    async (dataSample, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.saveDataSample(dataSample);
            dispatch(setDataSamples(response));
            dispatch(setCurrentSample(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const updateDataSample = createAsyncThunk(apiTypes.updateDataSample,
    async ({ sampleId, updates }, {dispatch, getState}) => {
        dispatch(setLoading(true));
        try {
            const patchArray = updates.map(x => {
                return {
                    op: 'replace',
                    path: `/${x.path}`,
                    value: x.value
                }
            });
            const response = await dataSampleProxy.updateDataSample(sampleId, patchArray);
            dispatch(setCurrentSample(response));
            dispatch(setEntityList(response.EntityList))
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const addNewEntity = createAsyncThunk(apiTypes.updateDataSample,
    async ({ sampleId, addedEntity}, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const patchArray = [{
                "op": "add",
                "path": "/EntityList/-",
                "value": addedEntity
            }];
            const response = await dataSampleProxy.updateDataSample(sampleId, patchArray);
            dispatch(setCurrentSample(response));
            dispatch(setEntityList(response.EntityList))
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const updateEntity = createAsyncThunk(apiTypes.updateDataSample,
    async ({ sampleId, updatedEntity, idx}, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const patchArray = [{
                "op": "replace",
                "path": `/EntityList/${idx}`,
                "value": updatedEntity
            }];
            const response = await dataSampleProxy.updateDataSample(sampleId, patchArray);
            dispatch(setCurrentSample(response));
            dispatch(setEntityList(response.EntityList))
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const deleteEntity = createAsyncThunk(apiTypes.updateDataSample,
    async ({ sampleId, idx}, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const patchArray = [{
                "op": "remove",
                "path": `/EntityList/${idx}`
            }];
            const response = await dataSampleProxy.updateDataSample(sampleId, patchArray);
            dispatch(setCurrentSample(response));
            dispatch(setEntityList(response.EntityList))
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const deleteDataSample = createAsyncThunk(apiTypes.deleteDataSample,
    async (sampleId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSampleProxy.deleteDataSample(sampleId);
            dispatch(removeDataSample(response));
            dispatch(setCurrentSample(null));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const deleteDataSet = createAsyncThunk(apiTypes.deleteDataSet,
    async (dataSetId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSetProxy.deleteDataSet(dataSetId);
            dispatch(removeDataSet(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const deleteEntityType = createAsyncThunk(apiTypes.deleteEntityType,
    async (entityTypeId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.deleteEntityType(entityTypeId);
            dispatch(removeEntityType(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const fetchDataSets = createAsyncThunk(apiTypes.fetchDataSets,
    async (_, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSetProxy.getDataSets();
            dispatch(setDataSets(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const resumeDataSetCreating = createAsyncThunk(apiTypes.resumeDataSetCreating,
    async (dataSetId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            await dataSetProxy.resumeCreating(dataSetId);
            dispatch(setDataSetProcessed(dataSetId));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const resumeClassifierTraining = createAsyncThunk(apiTypes.resumeClassifierTraining,
    async (classifierId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            await classifierProxy.resumeTraining(classifierId);
            dispatch(setClassifierProcessed(classifierId));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const saveDataSet = createAsyncThunk(apiTypes.saveDataSet,
    async (dataSet, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await dataSetProxy.saveDataSet(dataSet);
            dispatch(setDataSets(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const submitTrainClassifier = createAsyncThunk(apiTypes.submitTrainClassifier,
    async (dataSetId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            await classifierProxy.submitTrainClassifier(dataSetId);
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const classifyDocument = createAsyncThunk(apiTypes.classifyDocument,
    async ({data}, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await classifierProxy.classifyDocument(data);
            dispatch(setAiProcessingResult(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const recognizeDocumentEntities = createAsyncThunk(apiTypes.recognizeDocumentEntities,
    async ({data}, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.recognizeDocumentEntities(data);
            dispatch(setAiProcessingResult(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const updateClassifier = createAsyncThunk(apiTypes.updateClassifier,
    async ({patchArray, processorId}, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await classifierProxy.updateClassifier(patchArray, processorId);
            dispatch(updateDocumentClassifier(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const removeClassifier = createAsyncThunk(apiTypes.removeClassifier,
    async (processorId, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await classifierProxy.deleteClassifier(processorId);
            dispatch(removeDocumentClassifier(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const updateRecognizer = createAsyncThunk(apiTypes.updateRecognizer,
    async ({patchArray, processorId}, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await recognizerProxy.updateRecognizer(patchArray, processorId);
            dispatch(updateEntityRecognizer(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const identifyDocumentPage = createAsyncThunk(apiTypes.deleteImage,
    async (image, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            await identificationProxy.identifyDocumentPage(image);
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);
    export const identifyDocument = createAsyncThunk(apiTypes.deleteDocument,
        async (doc, {dispatch}) => {
            dispatch(setLoading(true));
            try {
                await identificationProxy.identifyDocument(doc);
            } catch (exception) {
                dispatch(setError(exception.message));
            } finally {
                dispatch(setLoading(false));
            }
        }
);
